import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useBillingOptionsGet } from '../../../../../../hooks/api/order';

function SelectBillingCodeType({
  fetchPath,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps & { fetchPath: 'billing-types' | 'billing-codes' }) {
  const dualUseGet = useBillingOptionsGet(undefined, fetchPath);

  return (
    <OrderSelect
      fetch={dualUseGet}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectBillingCodeType;
