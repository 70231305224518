import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import ParticipantForm from '../Form';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';
import { useParticipantCreate } from '../../../../hooks/api/addressBook';
import { useParticipantContext } from '../Form/context';
import { useAuth } from '../../../../store/auth';
import { getUserRequestPath } from '../../../../utils';

export function HeaderActions() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { form, isValid, setInitialState } = useParticipantContext();

  const participantCreate = useParticipantCreate(getUserRequestPath(user?.role));

  useMessageError([participantCreate]);
  useMessageSuccess([participantCreate], 'Changes saved successfully');

  useEffect(() => {
    if (!participantCreate.error && !participantCreate.loading && participantCreate.data) {
      navigate('/address-book');
    }
  }, [participantCreate.error, participantCreate.loading, participantCreate.data]);

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={participantCreate.loading}
        onClick={(e) => {
          e.preventDefault();

          if (form) {
            const data = form.getFieldsValue();
            const newData = {
              ...data,
              ...(data?.company?.id ? { company: { id: data.company.id } } : {}),
            };

            if (!data?.company?.id) {
              delete newData.company;
            }

            setInitialState(newData);
            participantCreate.fetch(newData);
          }
        }}
      >
        Save
      </Button>
    </Actions>
  );
}

export default function ParticipantCreate(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/address-book">Address Book</NavLink>,
          },
          {
            title: 'Add address',
          },
        ]}
      />
      <ParticipantForm />
    </>
  );
}
