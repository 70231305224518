import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { RequestData } from '@ant-design/pro-table';
import {
  Checkbox, Col, Input, List, ListProps, Typography,
} from 'antd';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { IServiceTable, useServicesTableGet } from '../../../../../../../../../hooks/api/services';
import { TableOnRowSelect } from '../../../../../../../../Common/Table';
import { queryFilterParams } from '../../../../../../../../../utils';
import Loading from '../../../../../../../../Common/Loading';
import { useMessageError } from '../../../../../../../../../hooks/common';

import styles from './index.module.scss';

interface ServicesListProps extends TableOnRowSelect {
  idk?: string;
  onRowSelection?: React.Dispatch<React.SetStateAction<string[]>>
}

function ServicesList({ params, selectedRows, onRowSelection }: ServicesListProps) {
  const servicesGet = useServicesTableGet();
  const [search, setSearch] = useState('');
  const [dataSource, setDataSource] = useState<IServiceTable[]>([]);

  // eslint-disable-next-line max-len
  const filteredData = useMemo(() => dataSource.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())), [dataSource, search]);

  const recentlyUsedList = useMemo(
    () => [...filteredData]
      .filter((item) => item.usedAt !== null && item.usedAt !== undefined) /** Exclude nulls for recently used */
      .sort((a, b) => dayjs(b.usedAt!).diff(dayjs(a.usedAt!)))
      .slice(0, 3),
    [filteredData],
  );

  const regularList = useMemo(() => {
    const recentlyUsedIds = new Set(recentlyUsedList.map((item) => item.id));

    return filteredData.filter((item) => !recentlyUsedIds.has(item.id));
  }, [filteredData, recentlyUsedList]);

  const handleCheckboxClick = (key: string, checked: boolean) => {
    if (!onRowSelection) return;

    if (checked) {
      onRowSelection((prevState) => [...prevState, key]);
    } else {
      onRowSelection((prevState) => (prevState.filter((item) => item === key)));
    }
  };

  const tableRequest = useCallback(async (): Promise<Partial<RequestData<IServiceTable>>> => {
    const newParams = queryFilterParams({
      page: '1',
      pageSize: '100',
      orderByColumn: 'name',
      // orderBy: 'ASC' | 'DESC',
    });

    const response = await servicesGet.fetch({ ...newParams, ...params, tab: undefined });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useEffect(() => {
    tableRequest();
    //   .then((res) => {
    //   if (res.data) {
    //     setDataSource(res.data);
    //   }
    // });
  }, []);

  useEffect(() => {
    if (servicesGet.data && !servicesGet.loading && !servicesGet.error) {
      setDataSource(servicesGet.data.data);
    }
  }, [servicesGet.data]);

  useMessageError([servicesGet]);

  const renderItem: ListProps<IServiceTable>['renderItem'] = (item) => (
    <List.Item>
      <Col span={3}>
        <Checkbox onChange={(e) => handleCheckboxClick(item.id, e.target.checked)} />
      </Col>
      <Col span={12}>
        {item.name}
      </Col>
      <Col span={7} style={{ textAlign: 'right' }}>
        {(Math.round((item?.value || 0) * 100) / 100)?.toFixed(2)}
        {' '}
        CHF
      </Col>
    </List.Item>
  );

  return (
    <div>
      {servicesGet.loading ? (
        <Loading absolute />
      ) : null}
      <div className={styles.header}>
        <p className={styles.title}>
          List of services
        </p>

        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.search}
          addonAfter={<SearchOutlined />}
        />
      </div>

      <div className={styles.listsWrapper}>
        <Typography.Text className={styles.subTitle}>Recently used</Typography.Text>
        <List
          itemLayout="horizontal"
          dataSource={recentlyUsedList}
          renderItem={renderItem}
        />

        <Typography.Text className={styles.subTitle}>All services</Typography.Text>
        <List
          itemLayout="horizontal"
          dataSource={regularList}
          className={styles.scrollList}
          renderItem={renderItem}
        />
      </div>
    </div>
  );
}

export default ServicesList;
