import { ConfigProvider, App } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import React from 'react';

interface AntdConfigProviderProps {
  children: React.ReactNode
}

export const COLOR_PRIMARY = '#339999';
export const COLOR_PRIMARY_HOVER = '#91cece';

export default function AntdConfigProvider({ children }: AntdConfigProviderProps): React.ReactNode {
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          colorPrimary: COLOR_PRIMARY,
          colorLink: COLOR_PRIMARY,
          colorLinkHover: COLOR_PRIMARY_HOVER,
          colorLinkActive: COLOR_PRIMARY,
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}
