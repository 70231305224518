import React, { useEffect, useState } from 'react';
import {
  Button, Form, FormInstance, InputNumber, Modal, Select, Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormFields } from '../index';
import { CurrencyType } from '../../../../../Adapter';
import { InvoiceCreateParams } from '../../../../../../../../hooks/api/invoices';

import styles from './index.module.scss';

interface ChangeCurrencyButton {
  invoiceForm: FormInstance<FormFields>;
}

function ChangeCurrencyButton({ invoiceForm }: ChangeCurrencyButton) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    currency: 'CHF', conversionRate: 1,
  });

  const invoiceValues = Form.useWatch([], invoiceForm) || {};
  const currencyWatch = Form.useWatch('currency', form);

  useEffect(() => {
    if (open) {
      setInitialValues({
        currency: 'CHF', // invoiceValues.currency || 'CHF'
        conversionRate: invoiceValues.conversionRate || 1,
      });
    }
  }, [open, invoiceValues]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const handleOk = () => {
    form.validateFields().then((values: { currency: CurrencyType, conversionRate?: number }) => {
      const { currency, conversionRate = 1 } = values;
      const currentConversionRate: number = invoiceForm.getFieldValue('conversionRate');

      const services: InvoiceCreateParams['services'] = invoiceForm.getFieldValue('services') || [];
      const regularServices: InvoiceCreateParams['services'] = invoiceForm.getFieldValue('regularServices') || [];

      const updateServiceValues = (serviceList: InvoiceCreateParams['services'])
        : InvoiceCreateParams['services'] => serviceList.map((service) => {
        const parsedValue = service.value;

        if (currency !== 'CHF') {
          /** If converting from CHF to another currency, set valueCHF to current value and apply conversion rate */
          const newValue = (service.valueCHF || parsedValue) * conversionRate;

          return {
            ...service,
            value: Number.parseFloat(newValue.toFixed(2)), /** Ensure value is a number rounded to 2 decimals */
            valueCHF: service?.valueCHF || parsedValue,
          };
        }

        /** Else we convert it back to CHF using conversion rate */
        const newValue = parsedValue / currentConversionRate;
        const newValueFormatted = Number.parseFloat(newValue.toFixed(2));

        return {
          ...service,
          value: newValueFormatted, // Revert to CHF value
          valueCHF: newValueFormatted,
        };
      });

      /** Set the updated services and new currency */
      const prevValues = invoiceForm.getFieldsValue();

      invoiceForm.setFieldsValue({
        ...prevValues,
        currency,
        conversionRate,
        regularServices: updateServiceValues(regularServices),
        services: updateServiceValues(services),
      });

      setOpen(false);
    });
  };

  const isLoading = false;

  return (
    <>
      <Button onClick={() => setOpen(true)}>Change currency</Button>
      <Modal
        centered
        open={open}
        title="Change currency"
        className="modal-with-table"
        okText={invoiceValues?.currency === 'CHF' ? 'Apply' : 'Change to CHF'}
        okButtonProps={{
          disabled: invoiceValues?.currency === currencyWatch,
        }}
        onOk={async () => {
          await handleOk();
        }}
        onCancel={() => {
          if (!isLoading) {
            setOpen(false);
          }
        }}
        width={450}
        maskClosable={false}
        destroyOnClose
        afterClose={() => { form.resetFields(); }}
      >
        <Form form={form} initialValues={initialValues} layout="vertical" className={styles.currencyForm}>
          <Form.Item name="currency" label="Currency" rules={[{ required: true }]}>
            <Select
              placeholder="Currency"
              options={['CHF', 'EUR', 'USD'].map((item) => ({ value: item, label: item }))}
              disabled={invoiceValues.currency !== 'CHF'}
            />
          </Form.Item>
          {/** We dont display rate only when convert to CHF */}
          {currencyWatch !== 'CHF' ? (
            <Form.Item
              label="Rate CHF / EUR / USD"
              name="conversionRate"
              normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Enter"
                step={0.1}
                min={0.1}
                max={99}
              />
            </Form.Item>
          ) : (
            <Form.Item label=" ">
              <Tooltip title="Currency conversion from EUR or USD is restricted to CHF">
                <InfoCircleOutlined />
              </Tooltip>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default ChangeCurrencyButton;
