import React, { createContext, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { Company, useCompanyGetById } from '../../../../hooks/api/company';
import { SimpleProviderProps } from '../../../../types';
import { DefaultFetchError, FetchGetId } from '../../../../hooks/fetch';

export interface CompanyContextProps {
  initialState: Company | null,
  setInitialState: (data: Company) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
  companyGetById: FetchGetId<Company, DefaultFetchError, unknown, Company>,
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: Company) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
  companyGetById: {} as FetchGetId<Company, DefaultFetchError, unknown, Company>,
};

export const CompanyContext = createContext<CompanyContextProps>(defaultValue);

function CompanyProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<Company | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  const companyGetById = useCompanyGetById(); // id . no id to not do autostart

  const memoizedValue: CompanyContextProps = useMemo(() => ({
    initialState,
    setInitialState,
    form,
    setForm,
    isValid,
    setValid,
    companyGetById,
  }), [initialState, setInitialState, form, setForm, isValid, setValid, companyGetById]);

  return (
    <CompanyContext.Provider value={memoizedValue}>
      {children}
    </CompanyContext.Provider>
  );
}

export default CompanyProvider;

export const useCompanyContext = ():
  CompanyContextProps => React.useContext(CompanyContext);
