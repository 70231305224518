import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import Menu from '../../components/Layout/Menu';
import ServicesContent from '../../components/Pages/Services';
import DefaultServicesContent from '../../components/Pages/Services/DefaultServices';

const { displayName } = config;

export default function Services(): React.ReactNode | null {
  document.title = `${displayName}: Services`;

  return (
    <Layout
      title="Services"
      leftMenu={<Menu />}
    >
      <DefaultServicesContent />
      <ServicesContent />
    </Layout>
  );
}
