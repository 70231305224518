import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import AdminAccountContent, { HeaderActions } from '../../components/Pages/Account/Admin';
import CustomerAccountContent from '../../components/Pages/Account/Customer';
import Menu from '../../components/Layout/Menu';
import { useAuth } from '../../store/auth';
import CustomerAccountProvider from '../../components/Pages/Account/Customer/customerContext';
import AdminAccountProvider from '../../components/Pages/Account/Admin/adminContext';

const { displayName } = config;

export default function Account(): React.ReactNode | null {
  document.title = `${displayName}: Account`;
  const { user } = useAuth();

  return (
    <Layout
      title="Account"
      leftMenu={<Menu />}
      headerContent={user?.role === 'user' ? undefined : <HeaderActions />}
    >
      {user?.role === 'user' ? (
        <CustomerAccountProvider>
          <CustomerAccountContent />
        </CustomerAccountProvider>
      ) : (
        <AdminAccountProvider>
          <AdminAccountContent />
        </AdminAccountProvider>
      )}
    </Layout>
  );
}
