import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Menu from '../../../components/Layout/Menu';
import ParticipantCreateContent, { HeaderActions } from '../../../components/Pages/AddressBook/Create';
import ParticipantProvider from '../../../components/Pages/AddressBook/Form/context';

const { displayName } = config;

export default function ParticipantCreate(): React.ReactNode | null {
  document.title = `${displayName}: Add address`;

  return (
    <ParticipantProvider>
      <Layout
        title="Add address"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ParticipantCreateContent />
      </Layout>
    </ParticipantProvider>
  );
}
