import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Input,
} from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useAuth } from '../../../../../store/auth';
import { useCompanyContext } from '../context';
import { useCompanyInvite } from '../../../../../hooks/api/company';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import { customizeRequiredMark } from '../../../../Common/Form/Input/common';
import { useAdministratorDelete, useAdministratorUpdate } from '../../../../../hooks/api/users';
import DeleteConfirmModal from '../../../../Common/Modal/DeleteConfirm';
import { useSimpleModal } from '../../../../Common/Modal/Simple';

interface UpdateUserNameProps {
  contactName: string;
  email: string;
}

type AdminAccountProps = AnyObject

export default function CompanyAdminForm(props: AdminAccountProps): React.ReactNode | null {
  const { user } = useAuth();
  const { id: companyId = '' } = useParams();
  const { open, contextHolder } = useSimpleModal();

  const { companyGetById } = useCompanyContext();
  const updateUser = useAdministratorUpdate();
  const deleteUser = useAdministratorDelete();
  const companyInvite = useCompanyInvite(companyId);
  const [formAdmin] = Form.useForm<UpdateUserNameProps>();

  const [modalOpen, setModalOpen] = useState(false);
  const [initialState, setInitialState] = useState<{ fullName?: string; email?: string }>({});

  const { loading, data, error } = companyGetById;

  useEffect(() => {
    if (!error && !loading && data) {
      const newData = {
        fullName: data.admin?.fullName || '',
        email: data.admin?.email || '',
      };

      setInitialState(newData);
      formAdmin?.setFieldsValue(newData);
    }
  }, [loading, data, error]);

  const reFetchCompany = () => companyGetById.fetch(undefined, companyId);

  /** Block/Unblock user */
  const handleUserStatus = (newStatus: 'blocked' | 'active') => {
    if (data?.admin?.id) {
      open({
        icon: <ExclamationCircleFilled />,
        title: `${newStatus === 'blocked' ? 'Block' : 'Unblock'} administrator?`,
        content: newStatus === 'blocked' ? (
          // eslint-disable-next-line max-len
          'Are you sure you want to block this administrator? Blocking will restrict the user’s access to the portal and prevent further interactions.'
        ) : (
          // eslint-disable-next-line max-len
          'Are you sure you want to unblock this administrator? Unblocking will restore the user’s access to the portal and allow them to interact again.'
        ),
        cancelText: 'Cancel',
        okText: `${newStatus === 'blocked' ? 'Block' : 'Unblock'} account`,
        okButtonProps: {
          danger: true,
        },
        onOk: () => updateUser.fetch({ status: newStatus }, data?.admin?.id)
          .then((res) => {
            if (res?.success) {
              reFetchCompany();
            }
          }),
      });
    }
  };

  useEffect(() => {
    if (!deleteUser.loading && !deleteUser.error && deleteUser.data) {
      reFetchCompany();
    }
  }, [deleteUser.data, deleteUser.loading, deleteUser.error]);

  useMessageError([updateUser, deleteUser, companyInvite]);
  useMessageSuccess([companyInvite], 'Customer invited successfully');
  useMessageSuccess([updateUser], 'Admin status changed successfully');
  useMessageSuccess([deleteUser], 'Admin deleted successfully');

  if (!data?.admin?.id) {
    return (
      <div>
        <p>
          Review our
          {' '}
          <a href="https://www.sephyre.com/data-privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>
          {' '}
          to understand how we collect, use, and protect your personal information.
          Your privacy is our top priority.
        </p>
        <Button
          type="default"
          onClick={() => companyInvite.fetch()}
          loading={companyInvite.loading}
        >
          Send invitation
        </Button>
      </div>
    );
  }

  return (
    <Form
      form={formAdmin}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      initialValues={initialState}
      {...props}
    >
      <Form.Item label="Name" name="fullName">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input disabled />
      </Form.Item>
      <Form.Item label=" " className="form-hidden-after">
        <div className="form-footer">
          <Button danger type="default" onClick={() => setModalOpen(true)}>
            Delete admin
          </Button>
          <DeleteConfirmModal
            open={modalOpen}
            handleOpen={setModalOpen}
            fetchHook={deleteUser}
            id={data?.admin?.id || null}
            title="Delete administrator?"
            description="Are you sure you want to delete this administrator?
                All data associated with this administrator will be permanently deleted. This action cannot be undone. "
          />

          <Button
            type="default"
            loading={updateUser.loading || loading}
            onClick={(e) => {
              e.preventDefault();
              handleUserStatus(data?.admin?.status === 'blocked' ? 'active' : 'blocked');
            }}
          >
            {data?.admin?.status === 'blocked' ? 'Unblock' : 'Block'}
          </Button>
        </div>
      </Form.Item>
      <Divider />
      {contextHolder}
    </Form>
  );
}
