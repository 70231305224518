import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import OrderEditContent, { HeaderActions } from '../../../../components/Pages/Orders/View/Edit';
import Menu from '../../../../components/Layout/Menu';
import OrderProviderForm from '../../../../components/Pages/Orders/Form/context';
import OrderProvider from '../../../../components/Pages/Orders/View/context';

const { displayName } = config;

export default function OrderEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit order`;

  return (
    <OrderProvider mode="edit">
      <OrderProviderForm>
        <Layout
          title="Edit order"
          leftMenu={<Menu />}
          headerContent={<HeaderActions />}
        >
          <OrderEditContent />
        </Layout>
      </OrderProviderForm>
    </OrderProvider>
  );
}
