import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography,
} from 'antd';
import { connect } from 'react-redux';
import {
  forgotPassword as forgotPasswordAction,
  forgotPasswordErrorClear as forgotPasswordErrorClearAction,
  ForgotPasswordResponse,
  ForgotPasswordAction,
} from '@triare/auth-redux/dist/saga/auth/forgotPassword';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { Action } from '@triare/auth-redux';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../utils/validations';
import SendSuccess from './SendSuccess';
import { getMessageInError } from '../../../../hooks/fetch';
import Wrapper from '../Wrapper';
import Submit from '../../../Common/Form/Button/Submit';
import { RootState } from '../../../../store';
import { moduleName } from '../../../../store/auth';

import styles from './index.module.scss';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';

const { Text, Paragraph } = Typography;
const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

interface ForgotPasswordProps {
  loading: boolean;
  response: ForgotPasswordResponse | null;
  error: Error;
  forgotPassword: (payload: ForgotPasswordAction) => Action;
  forgotPasswordErrorClear: () => Action;
}

function ForgotPassword({
  forgotPassword, forgotPasswordErrorClear, loading, response, error,
}: ForgotPasswordProps) {
  const [sendSuccess, setSendSuccess] = useState(false);

  const onFinish = ({ email }: { email: string }) => {
    forgotPassword({ email });
  };

  useEffect(() => {
    if (response) {
      setSendSuccess(true);
    }
  }, [response]);

  return (
    <Wrapper>
      <AntdForm className={styles.form} layout="vertical" onFinish={onFinish} requiredMark={customizeRequiredMark}>
        {sendSuccess ? (
          <SendSuccess />
        ) : (
          <>
            <Text className={styles.text} strong>
              Forgot your password?
            </Text>
            <Paragraph className={styles.secondary} type="secondary">
              No worries, well send you instructions on how to reset it.
            </Paragraph>

            {error && (
              <AntdForm.Item>
                <Alert
                  type="error"
                  onClose={() => forgotPasswordErrorClear()}
                  message={getMessageInError(error)}
                  closable
                  showIcon
                />
              </AntdForm.Item>
            )}

            <AntdForm.Item name="email" label="Email" className={styles.email} rules={[validationRules]} required>
              <Input size="large" placeholder="Email" />
            </AntdForm.Item>

            <Submit
              loading={loading}
              size="large"
              block
            >
              Send password reset email
            </Submit>

            <AntdForm.Item>
              <Link to="/sign-in">
                <Button type="text" size="large" style={{ color: '#8C8C8C' }} block icon={<LeftOutlined />}>
                  Back to log in
                </Button>
              </Link>
            </AntdForm.Item>
          </>
        )}
      </AntdForm>
    </Wrapper>
  );
}

export default connect((state: RootState) => ({
  loading: state[moduleName].forgotPassword.loading,
  response: state[moduleName].forgotPassword.response,
  error: state[moduleName].forgotPassword.error,
}), {
  forgotPassword: forgotPasswordAction,
  forgotPasswordErrorClear: forgotPasswordErrorClearAction,
})(ForgotPassword);
