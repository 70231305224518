import React from 'react';
import { useSegregationGroupGetAll } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectSegregationGroup({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useSegregationGroupGetAll();

  return (
    <OrderSelect
      {...props}
      selectProps={{
        mode: 'multiple',
        ...selectProps,
      }}
      rest={rest}
      fetch={dualUseGet}
    />
  );
}

export default SelectSegregationGroup;
