import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useNameStatusDeclarantGetAll } from '../../../../../../hooks/api/order';

function SelectNameStatusDeclarant({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useNameStatusDeclarantGetAll();

  return (
    <OrderSelect
      fetch={dualUseGet}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectNameStatusDeclarant;
