import React from 'react';

import styles from './index.module.scss';

export default function Separator(): React.ReactNode {
  return (
    <div className={styles.separator}>
      or
    </div>
  );
}
