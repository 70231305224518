import React, { useEffect } from 'react';
import { Button } from 'antd';
import CompanyForm from '../../../../Companies/Form';
import { useCompanyContext } from '../../../../Companies/Form/context';
import { useCompanyUpdate } from '../../../../../../hooks/api/company';
import { useAuth } from '../../../../../../store/auth';
import { useMessageError } from '../../../../../../hooks/common';
import { useCustomerAccountContext } from '../../customerContext';

function CustomerProfileForm() {
  const { user } = useAuth();
  const {
    form, isValid, setInitialState, initialState,
  } = useCompanyContext();
  const { companyClient } = useCustomerAccountContext();
  const companyUpdate = useCompanyUpdate(undefined, user?.role === 'user');

  const handleSubmit = () => {
    if (form) {
      form.validateFields().then((values) => {
        companyUpdate.fetch(values);
      });
    }
  };

  useEffect(() => {
    if (companyClient.data) {
      setInitialState(companyClient.data);
    }
  }, [companyClient.data]);

  useEffect(() => {
    if (form) {
      form?.setFieldsValue(companyClient.data);
    }
  }, [initialState]);

  useMessageError([companyUpdate]);

  return (
    <div>
      <CompanyForm
        footer={(
          <>
            <Button type="default" onClick={() => form?.resetFields()} loading={companyUpdate.loading}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={!isValid} loading={companyUpdate.loading}>
              Save
            </Button>
          </>
        )}
      />
    </div>
  );
}

export default CustomerProfileForm;
