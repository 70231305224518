import { AxiosProgressEvent } from 'axios';
import {
  DefaultFetchError, FetchCreate, useFetchCreate, FetchGetId, useFetchGetId,
} from './fetch';

interface UploadFileSuccess {
  id: string;
  encoding: string;
  fileSize: number;
  location: string;
  mimeType: string;
  originalName: string;
}

export const useUploadFile = (uploadUrl = 'files/upload', authorization = true): FetchCreate<
  UploadFileSuccess,
  DefaultFetchError,
  FormData
> => useFetchCreate(
  uploadUrl,
  {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    authorization,
  },
);

export const useDownloadFile = (
  abortController: AbortController,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'files',
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController.signal,
      },
    },
    'arraybuffer',
    onDownloadProgress,
  )
);

export default {
  useUploadFile,
};
