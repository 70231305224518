import React from 'react';
import OrderSelect, { OrderSelectProps } from '../Select';
import { SelectYesNoList } from '../../../../../../contstant';

function SelectEmptyUncleaned({
  options,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  return (
    <OrderSelect
      initialValue={false}
      options={options || SelectYesNoList}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectEmptyUncleaned;
