import React, { useEffect } from 'react';
import { Button } from 'antd';
import {
  DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined, MailOutlined, RollbackOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { DividerStyled, ListItemEnum, SelectedItemPaperwork } from '../index';
import {
  Invoice,
  useInvoiceResend,
  useInvoiceReturnToDraft,
  useInvoicesDelete,
  useInvoiceSendAsOffer,
} from '../../../../../../hooks/api/invoices';
import { useOrderContext } from '../../../View/context';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { useOffersDelete } from '../../../../../../hooks/api/offers';
import { useDeliveryNoteDelete } from '../../../../../../hooks/api/deliveryNotes';
import { isInvoiceDeleteVisible } from '../../../View/InvoicesList';
import { useMessageError } from '../../../../../../hooks/common';

import styles from '../index.module.scss';

interface ActionItemProps {
  visible?: boolean,
  onClick?: () => void,
  loading?: boolean;
}

interface PaperworkActionsProps {
  selectedState: SelectedItemPaperwork;
  handleSelectedState: (props: SelectedItemPaperwork) => void;
  clearSelectedState: () => void;
  onAction?: {
    preview?: ActionItemProps,
    edit?: ActionItemProps,
    download?: ActionItemProps,
  };
}

function PaperworkActions({
  selectedState,
  handleSelectedState,
  clearSelectedState,
  onAction = {
    preview: {},
    edit: {},
    download: {},
  },
}: PaperworkActionsProps) {
  const { id: orderId = '' } = useParams();
  const { order, clientOrderData } = useOrderContext();
  const { open, contextHolder } = useSimpleModal();
  const { id, type, item } = selectedState;
  const { edit, download, preview } = onAction;

  const isInvoice = type === 'invoice';
  const isDeliveryNote = type === 'deliveryNote';

  const isInvoiceDeleteShow = isInvoice && isInvoiceDeleteVisible(item as Invoice);

  /** >>> Request */
  const invoiceSendAsOffer = useInvoiceSendAsOffer();
  const invoiceReturnToDraft = useInvoiceReturnToDraft();
  const invoiceSend = useInvoiceResend('admin');
  const invoiceDelete = useInvoicesDelete('admin');

  const offerDelete = useOffersDelete('admin');
  const deliveryNoteDelete = useDeliveryNoteDelete(orderId);

  useEffect(() => {
    /** When we update order and update some item - re-select new version of it from response */
    if (clientOrderData && type === 'invoice' && item) {
      const foundItem = (clientOrderData.invoices || []).find((invoice) => invoice.id === id);

      handleSelectedState({ ...selectedState, id: foundItem?.id || item.id, item: foundItem || item });
    }
    // if (clientOrderData && type === 'deliveryNote' && item) {
    //   handleSelectedState({ ...selectedState, item: clientOrderData?.deliveryNotes || item });
    // }
  }, [clientOrderData]);

  useEffect(() => {
    if ((invoiceSendAsOffer.data && !invoiceSendAsOffer.error && !invoiceSendAsOffer.loading)
    || (invoiceSend.data && !invoiceSend.error && !invoiceSend.loading)
    || (invoiceReturnToDraft.data && !invoiceReturnToDraft.error && !invoiceReturnToDraft.loading)
    ) {
      order.fetch();
      invoiceSendAsOffer.clearResponse();
      invoiceSend.clearResponse();
      invoiceReturnToDraft.clearResponse();
    }
  }, [invoiceDelete.response, invoiceSendAsOffer.response, invoiceSend.response, invoiceReturnToDraft.response]);

  useEffect(() => {
    if ((invoiceDelete.data && !invoiceDelete.error && !invoiceDelete.loading)
      || (deliveryNoteDelete.data && !deliveryNoteDelete.error && !deliveryNoteDelete.loading)
    ) {
      order.fetch();
      invoiceDelete.clearResponse();
      deliveryNoteDelete.clearResponse();

      clearSelectedState();
    }
  }, [invoiceDelete.response, deliveryNoteDelete.response]);

  useMessageError([invoiceDelete, invoiceSendAsOffer, invoiceSend, deliveryNoteDelete, invoiceReturnToDraft]);

  const handleDelete = () => {
    open({
      icon: <ExclamationCircleFilled />,
      title: 'Delete item?',
      centered: true,
      content: (
        <span>
          Are you sure you want to delete
          {' '}
          {type ? ListItemEnum[type] : 'this'}
          {' '}
          <b>{item?.number || 'item'}</b>
          ?
        </span>
      ),
      cancelText: 'Cancel',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        if (isInvoice) {
          return invoiceDelete.fetch(id);
        }

        // type === 'deliveryNote'
        return deliveryNoteDelete.fetch(id);
      },
    });
  };

  const handleReturnToDraft = () => {
    open({
      icon: <ExclamationCircleFilled />,
      title: 'Return to draft?',
      centered: true,
      content: (
        <span>
          Are you sure you want to return invoice
          {' '}
          <b>{item?.number || 'item'}</b>
          {' '}
          to draft status?
        </span>
      ),
      cancelText: 'Cancel',
      okText: 'Return to draft',
      okButtonProps: {
        danger: true,
      },
      onOk: () => invoiceReturnToDraft.fetch(undefined, `${id}/return-to-draft`),
    });
  };

  if (!type || !id) {
    return null;
  }

  return (
    <div className={styles.section}>
      {contextHolder}
      <DividerStyled>Actions</DividerStyled>

      <div className={styles.actions}>
        {preview?.visible ? (
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              preview?.onClick?.();
            }}
            loading={preview.loading}
          >
            Preview
          </Button>
        ) : null}
        {edit?.visible ? (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              edit?.onClick?.();
            }}
            loading={edit.loading}
          >
            Edit
          </Button>
        ) : null}
        {download?.visible ? (
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              download?.onClick?.();
            }}
            loading={download.loading}
          >
            Download
          </Button>
        ) : null}

        {isInvoice ? (
          <>
            <Button
              icon={<MailOutlined />}
              onClick={() => invoiceSendAsOffer.fetch(undefined, `${id}/send-as-offer`)}
              loading={invoiceSendAsOffer.loading}
            >
              Send as offer
            </Button>
            <Button
              icon={<MailOutlined />}
              onClick={() => invoiceSend.fetch(undefined, `${id}/send-by-mail`)}
              loading={invoiceSend.loading}
            >
              Send invoice
            </Button>
            {(item as Invoice)?.status === 'pending' ? (
              <Button
                icon={<RollbackOutlined />}
                danger
                onClick={handleReturnToDraft}
                loading={invoiceSend.loading}
              >
                Return to draft
              </Button>
            ) : null}
          </>
        ) : null}
        {isInvoiceDeleteShow || isDeliveryNote ? (
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={handleDelete}
            loading={invoiceDelete.loading || deliveryNoteDelete.loading}
          />
        ) : null}
      </div>
    </div>
  );
}

export default PaperworkActions;
