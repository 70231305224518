import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signIn as signInAction, SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';
import { Error, AnyObject } from '@triare/auth-redux/dist/saga/common';
import Form from '../Form';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../../utils/validations';
import { RootState } from '../../../../../store';
import { moduleName } from '../../../../../store/auth';
import { ContentProps } from '../index';
import { customizeRequiredMark } from '../../../../Common/Form/Input/common';
import Footer from '../Footer';

const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

interface EmailAndPasswordProps extends ContentProps {
  response: AnyObject | null;
  error: Error | null;
  signIn: (payload: SignInAction) => Action
}

function EmailAndPassword({
  response, error, signIn, onSubmit,
}: EmailAndPasswordProps): React.ReactNode {
  const [signInData, setSignInData] = useState<SignInAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit && signInData) {
      onSubmit(signInData);
    }
  }, [response, error, onSubmit, signInData]);

  return (
    <>
      <Form<SignInAction>
        validationRules={validationRules}
        validateSubmit={({ email, password }) => !email || !password}
        signIn={(props) => {
          const data: SignInAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignInData(data);
          signIn(data);
        }}
        name="signInEmail"
        requiredMark={customizeRequiredMark}
      >
        <AntdForm.Item name="email" label={`${t('email')}`} rules={[validationRules]} required>
          <Input
            placeholder="Email"
            size="large"
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')}`}
          rules={[validationRules]}
          required
        >
          <Input.Password placeholder="Password" size="large" />
        </AntdForm.Item>
      </Form>

      <Footer />
    </>
  );
}

export default connect((state: RootState) => ({
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signIn: signInAction,
})(EmailAndPassword);
