import React, { useEffect } from 'react';
import {
  Breadcrumb, Button, Dropdown, Spin,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useAuth } from '../../../../store/auth';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import {
  AddressBookCreateParams, useParticipantCreate, useParticipantDelete, useParticipantsGetById,
} from '../../../../hooks/api/addressBook';
import { getUserRequestPath } from '../../../../utils';
import Actions from '../../../Common/Header/Actions';
import { isRoleEnough } from '../../../../enums/user';

import styles from './index.module.scss';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const participantCopy = useParticipantCreate(getUserRequestPath(user?.role));
  const participantDelete = useParticipantDelete(getUserRequestPath(user?.role), id || 'undefined');
  const participantGetById = useParticipantsGetById(
    id ? `${getUserRequestPath(user?.role || 'user')}/participants/${id}` : '',
  );

  useMessageError([participantDelete, participantCopy, participantGetById]);
  useMessageSuccess([participantCopy], 'Duplicate created successfully');
  useMessageSuccess([participantDelete], 'Address deleted successfully');

  useEffect(() => {
    if (!participantCopy.error && !participantCopy.loading && participantCopy.data) {
      navigate(`/address-book/${participantCopy.data.id}/edit`);
    }
  }, [participantCopy.data]);

  useEffect(() => {
    if (!participantDelete.error && !participantDelete.loading && participantDelete.data) {
      navigate('../address-book');
    }
  }, [
    participantDelete.error, participantDelete.loading, participantDelete.data,
  ]);

  return (
    <Actions>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();

          navigate('edit');
        }}
      >
        Edit
      </Button>
      <Dropdown
        menu={{
          items: [
            {
              key: 'Make a copy',
              label: 'Make a copy',
              onClick: ({ domEvent }) => {
                domEvent.preventDefault();
                if (participantGetById?.data) {
                  const { data } = participantGetById;

                  const dataCopy = {
                    ...data, id: undefined, createdAt: undefined, company: undefined,
                  } as AddressBookCreateParams;

                  if (user?.role !== 'user') {
                    dataCopy.company = { id: data.company?.id || '' };
                  }

                  participantCopy.fetch(dataCopy);
                }
              },
            },
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => {
                open({
                  icon: <ExclamationCircleFilled />,
                  title: 'Delete contact?',
                  content: (
                    <span>
                      Are you sure you want to delete address?
                    </span>
                  ),
                  cancelText: 'Cancel',
                  okText: 'Delete',
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => participantDelete.fetch(),
                });
              },
              danger: true,
            },
          ],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          icon={<EllipsisOutlined />}
          style={{ transform: 'translate(0px, 1.5px)' }}
        />
      </Dropdown>

      {contextHolder}
    </Actions>
  );
}

export default function ParticipantView(): React.ReactNode | null {
  const { id } = useParams();
  const { user } = useAuth();
  const participantGetById = useParticipantsGetById(
    id ? `${getUserRequestPath(user?.role || 'user')}/participants/${id}` : '',
  );
  const {
    company,
    displayName,
    companyName,
    contactName,
    phone,
    email,
    country,
    address1,
    address2,
    postalCode,
    city,
    state,
    tinNumber,
    registerNumber,
    createdAt,
  } = participantGetById.data || {};

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/address-book">Address book</NavLink>,
          },
          {
            title: 'View address',
          },
        ]}
      />

      <div className={styles.participant}>
        <div className={styles.info}>
          <div>
            <h4>
              Display Name
            </h4>
            {displayName ? (
              <div>{displayName}</div>
            ) : null}
            <h4>
              Company
            </h4>
            {companyName ? (
              <div>{companyName}</div>
            ) : null}
            {isRoleEnough(user?.role, 'admin') && (
              <>
                <h4>Customer</h4>
                {company ? (
                  <div>
                    {<NavLink to={`/companies/${company?.id}`}>{company?.companyName}</NavLink> || ''}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div>
            <h4>
              Contact
            </h4>
            {contactName ? (
              <div>{contactName}</div>
            ) : null}
            {phone ? (
              <a href={`tel:${phone}`}>{phone}</a>
            ) : null}
            {email ? (
              <a href={`mailto:${email}`}>{email}</a>
            ) : null}
            <h4>
              Address
            </h4>
            {address1 || address2 ? (
              <div>{[address1, address2].join(', ')}</div>
            ) : null}
            {city ? (
              <div>{city}</div>
            ) : null}
            {postalCode || state ? (
              <div>
                {state}
                {' '}
                {postalCode}
              </div>
            ) : null}
            {country ? (
              <div>{country}</div>
            ) : null}
          </div>
          <div>
            <h4>
              TIN number
            </h4>
            {tinNumber ? (
              <div>{tinNumber}</div>
            ) : null}
            <h4>
              Register number
            </h4>
            {registerNumber ? (
              <div>{registerNumber}</div>
            ) : null}
          </div>
          <div>
            <h4>
              Date added
            </h4>
            {createdAt ? (
              <div>
                {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
              </div>
            ) : null}
            <br />
          </div>

          {participantGetById.loading ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
