import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../../Orders/Form/Common/Select';
import { useCompaniesGetAll } from '../../../../../hooks/api/company';

function SelectCompany({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useCompaniesGetAll();

  return (
    <OrderSelect fetch={dualUseGet} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectCompany;
