import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { ButtonProps, ContentProps } from './index';
import PackageForm from './Form';
import { ClientDataPackages } from '../../../Adapter';

export function CreateButton({ remove }: ButtonProps) {
  return (
    <Button
      icon={<DeleteOutlined />}
      type="text"
      onClick={(e) => {
        e.stopPropagation();
        remove();
      }}
    />
  );
}

interface CreateContentProps extends ContentProps {
  item: ClientDataPackages;
}

export function CreateContent(props: CreateContentProps) {
  return (
    <PackageForm {...props} />
  );
}

export default {
  CreateButton,
  CreateContent,
};
