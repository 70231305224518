import {
  DefaultFetchError,
  FetchGetId,
  useFetchGetId,
} from '../fetch';

export type DeliveryServiceType = 'dhl' | 'fedex' | 'schenker' | 'other';

export enum TrackingMethod {
  DHL = 'DHL',
  Fedex = 'Fedex',
  DBSchenker = 'DBSchenker',
}

export interface ShipmentInfo {
  address: {
    name: string;
    countryCode: string;
  };
  description?: string;
  status: string;
  timestamp: string;
}

export interface TrackingInfo<TShipment = ShipmentInfo> {
  type: TrackingMethod;
  shipments: TShipment[];
}

export interface DHLShipmentInfo {
  date: string; // "2024-09-03"
  time: string; // "22:42:58"
  typeCode: string; // "DF"
  description: string; // "Shipment has departed from a DHL facility BASEL-SWITZERLAND",
  serviceArea: [
    {
      'code': string; // "BSL",
      'description': string; // "Basel-CH"
    }
  ]
}

export const useOrderTrackingDHLGet = <DD = TrackingInfo>(
  id?: string,
  decorateData: ((data: TrackingInfo<DHLShipmentInfo>) => DD) = (data) => ({
    type: TrackingMethod.DHL,
    shipments: (data.shipments || []).map((shipment) => {
      const serviceArea = shipment.serviceArea[0];

      return {
        address: {
          name: serviceArea?.description,
          countryCode: serviceArea?.code,
        },
        description: shipment.description,
        // status: shipment.typeCode === 'DF' ? 'departed' : 'transit',
        status: shipment.typeCode,
        timestamp: `${shipment.date}T${shipment.time}`, // Combine date and time into timestamp
      };
    }),
  }) as DD,
): FetchGetId<
  TrackingInfo<DHLShipmentInfo>,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/dhl',
    id,
    {
      autoStart: false, // !!id,
      decorateData,
    },
  );

export const useOrderTrackingFedexGet = <DD = TrackingInfo>(
  id?: string,
): FetchGetId<
  TrackingInfo,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/fedex',
    id,
    {
      autoStart: false, // !!id,
    },
  );

export const useOrderTrackingDBSchenkerGet = <DD = TrackingInfo>(
  id?: string,
): FetchGetId<
  TrackingInfo,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'orders/tracking/dbschenker',
    id,
    {
      autoStart: false, // !!id,
    },
  );
