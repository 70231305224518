import React, { useEffect } from 'react';
import {
  Breadcrumb, Button, Dropdown, Spin,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';
import {
  ServiceUnitEnum, useServicesCreate, useServicesDelete, useServicesGetById,
} from '../../../../hooks/api/services';

import styles from '../../AddressBook/View/index.module.scss';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const serviceCopy = useServicesCreate();
  const serviceDelete = useServicesDelete(id || 'undefined');

  useMessageError([serviceDelete, serviceCopy]);
  useMessageSuccess([serviceDelete], 'Service deleted successfully');
  useEffect(() => {
    if (!serviceCopy.error && !serviceCopy.loading && serviceCopy.data) {
      navigate('edit');
    }
  }, [serviceCopy]);

  useEffect(() => {
    if (!serviceDelete.error && !serviceDelete.loading && serviceDelete.data) {
      navigate('../services');
    }
  }, [
    serviceDelete.error, serviceDelete.loading, serviceDelete.data,
  ]);

  return (
    <Actions>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();

          navigate('edit');
        }}
      >
        Edit
      </Button>
      <Dropdown
        menu={{
          items: [
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => {
                open({
                  icon: <ExclamationCircleFilled />,
                  title: 'Delete service?',
                  content: (
                    <span>
                      Are you sure you want to delete service?
                    </span>
                  ),
                  cancelText: 'Cancel',
                  okText: 'Delete',
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => serviceDelete.fetch(),
                });
              },
              danger: true,
            },
          ],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          icon={<EllipsisOutlined />}
          style={{ transform: 'translate(0px, 1.5px)' }}
        />
      </Dropdown>

      {contextHolder}
    </Actions>
  );
}

const unitsView = (unit: ServiceUnitEnum) => ServiceUnitEnum[unit as unknown as keyof typeof ServiceUnitEnum];

export default function ServiceView(): React.ReactNode | null {
  const { id } = useParams();
  const serviceGetById = useServicesGetById(id);
  const {
    name,
    value,
    units,
    updatedAt,
  } = serviceGetById.data || {};

  useMessageError([serviceGetById]);

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/services">Services</NavLink>,
          },
          {
            title: 'View service',
          },
        ]}
      />

      <div className={styles.participant}>
        <div>
          <div className={styles.info}>
            <div>
              <h4>
                Name
              </h4>
              {name ? (
                <div>{name}</div>
              ) : null}
            </div>
            <div>
              <h4>
                Units
              </h4>
              {units ? (
                <div>{unitsView(units)}</div>
              ) : null}
            </div>
            <div>
              <h4>
                Price
              </h4>
              {value ? (
                <div>{`${value} CHF`}</div>
              ) : null}
            </div>
            <div>
              <h4>
                Last updated
              </h4>
              {updatedAt ? (
                <div>
                  {dayjs(updatedAt).format('DD/MM/YYYY HH:mm')}
                </div>
              ) : null}
            </div>

            {serviceGetById.loading ? (
              <div className={styles.spin}>
                <Spin />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
