import React from 'react';
import { Form, InputNumber, InputNumberProps } from 'antd';
import { OrderInputProps } from '../Input';

export interface InputDHLDeliveryProps extends Omit<OrderInputProps, 'value'> {
  disabled?: boolean;
  suffix?: string;
  width?: string;
  placeholder?: string;
  onChange?: (value: number | null) => void;

  value?: number | null;
  defaultValue?: number;
  precision?: number;
}

function OrderDeliveryDHLInput({
  children,
  inputNumberProps,
  rightText,
  status,
  value,
  disabled = true,
  width = '110px',
  placeholder = '0',
  onChange,
  name,
  label,
  defaultValue,
  precision,
  ...props
}: InputDHLDeliveryProps) {
  return (
    <Form.Item name={name} label={label}>
      <InputNumber
        disabled={disabled}
        style={{ width }}
        value={value}
        placeholder={placeholder}
        min={1}
        onChange={onChange}
        defaultValue={defaultValue}
        precision={precision}
        {...props}
      />
    </Form.Item>
  );
}

export default OrderDeliveryDHLInput;
