import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import ParticipantViewContent, { HeaderActions } from '../../../components/Pages/AddressBook/View';
import Menu from '../../../components/Layout/Menu';
import ParticipantProvider from '../../../components/Pages/AddressBook/Form/context';

const { displayName } = config;

export default function ParticipantView(): React.ReactNode | null {
  document.title = `${displayName}: View address`;

  return (
    <ParticipantProvider>
      <Layout
        title="View address"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ParticipantViewContent />
      </Layout>
    </ParticipantProvider>
  );
}
