import React, { useEffect, useRef } from 'react';
import {
  Form, Input, FormInstance,
} from 'antd';

import styles from './index.module.scss';

interface InputCodeProps {
  id: number
  form: FormInstance
}

export default function InputCode({ id, form }: InputCodeProps) {
  const parentRef = useRef(null);
  const inputRef = useRef(null);
  const name = `number-${id}`;
  const value = Form.useWatch(name, form);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputRef.current) {
      const { input } = inputRef.current as { input: HTMLInputElement };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handler = (event: any) => {
        if (event?.currentTarget) {
          if (event.currentTarget.value === '') {
            if (parentRef.current) {
              const prevInput = (parentRef.current as HTMLInputElement).previousElementSibling?.querySelector('input');

              setTimeout(() => {
                prevInput?.focus();
              }, 15);
            }
          } else if (parentRef.current) {
            const nextInput = (parentRef.current as HTMLInputElement).nextElementSibling?.querySelector('input');

            setTimeout(() => {
              nextInput?.focus();
            }, 15);
          }
        }
      };

      input.addEventListener('input', handler, false);

      return () => {
        input.removeEventListener('input', handler);
      };
    }
  }, []);

  useEffect(() => {
    const result = Number.parseInt((value !== 0 ? value || '' : 0).toString().replace(/\D*/gim, '').slice(0, 1), 10);

    form.setFieldValue(name, Number.isNaN(result) ? '' : result);
  }, [value]);

  return (
    <div ref={parentRef}>
      <Form.Item
        className={styles.input}
        name={name}
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input size="large" type="number" ref={inputRef} />
      </Form.Item>
    </div>
  );
}
