import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAdministratorContext } from '../Form/context';
import AdministratorForm from '../Form';
import Actions from '../../../Common/Header/Actions';
import { useAdministratorCreate } from '../../../../hooks/api/users';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';

export function HeaderActions() {
  const navigate = useNavigate();
  const { form, isValid, setInitialState } = useAdministratorContext();
  const administratorCreate = useAdministratorCreate();

  useMessageError([administratorCreate]);
  useMessageSuccess([administratorCreate], 'Invitation sent successfully');

  useEffect(() => {
    if (!administratorCreate.error && !administratorCreate.loading && administratorCreate.data) {
      navigate('/administrators');
    }
  }, [administratorCreate.error, administratorCreate.loading, administratorCreate.data]);

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={administratorCreate.loading}
        onClick={(e) => {
          e.preventDefault();

          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            administratorCreate.fetch(data);
          }
        }}
      >
        Send invitation
      </Button>
    </Actions>
  );
}

export default function AdministratorCreate(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/administrators">Administrators</NavLink>,
          },
          {
            title: 'Add administrator',
          },
        ]}
      />

      <AdministratorForm />
    </>
  );
}
