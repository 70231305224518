import React from 'react';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';

function Footer(): React.ReactNode {
  return (
    <Typography.Text
      strong
      style={{
        marginTop: '24px', textAlign: 'center', display: 'block', width: '100%',
      }}
    >
      Don’t have an account? &thinsp;
      <NavLink to="/sign-up">
        Sign Up
      </NavLink>
    </Typography.Text>
  );
}

export default Footer;
