import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Alert, Form, Input, Typography, App,
} from 'antd';
import { Error as CommonError } from '@triare/auth-redux/dist/saga/common';
import { Action } from '@triare/auth-redux';
import { connect } from 'react-redux';
import {
  resetPassword as resetPasswordAction,
  resetPasswordErrorClear as resetPasswordErrorClearAction,
  ResetPasswordAction, ResetPasswordResponse,
} from '@triare/auth-redux/dist/saga/auth/resetPassword';
import { validationNewPassword, createRulesForAntd } from '../../../../utils/validations';
import { getMessageInError } from '../../../../hooks/fetch';
import Wrapper from '../Wrapper';
import Submit from '../../../Common/Form/Button/Submit';
import { RootState } from '../../../../store';
import { moduleName } from '../../../../store/auth';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

interface ResetPasswordProps {
  loading: boolean;
  response: ResetPasswordResponse | null;
  error: CommonError;
  resetPassword: (payload: ResetPasswordAction) => Action;
  resetPasswordErrorClear: () => Action;
}

function ResetPassword({
  resetPassword, resetPasswordErrorClear, loading, response, error,
}: ResetPasswordProps) {
  const { secretKey } = useParams();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const onFinish = ({ password }: { password: string }) => {
    if (secretKey) {
      resetPassword({ password, secretKey });
    }
  };

  useEffect(() => {
    if (response) {
      message.info('Your password has been successfully changed');
      navigate('/sign-in');
    }
  }, [response]);

  return (
    <Wrapper>
      <Form className={styles.form} layout="vertical" onFinish={onFinish}>
        <Typography.Title level={5} className={styles.title}>
          Set and confirm new password
        </Typography.Title>

        {error && (
          <Form.Item>
            <Alert
              type="error"
              onClose={() => resetPasswordErrorClear()}
              message={getMessageInError(error)}
              closable
              showIcon
            />
          </Form.Item>
        )}

        <Form.Item name="password" label="New password *" className={styles.input} rules={[validationRules]}>
          <Input.Password placeholder="New password" size="large" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm new password *"
          className={styles.input}
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm new password" size="large" />
        </Form.Item>

        <Submit
          loading={loading}
          className={styles.btn}
          size="large"
        >
          Log in
        </Submit>
      </Form>

      {/* <Typography.Text */}
      {/*  strong */}
      {/*  style={{ */}
      {/*    marginTop: '24px', textAlign: 'center', display: 'block', width: '100%', */}
      {/*  }} */}
      {/* > */}
      {/*  Don’t have an account? &thinsp; */}
      {/*  <NavLink to="/sign-up">Sign Up</NavLink> */}
      {/* </Typography.Text> */}
    </Wrapper>
  );
}

export default connect((state: RootState) => ({
  loading: state[moduleName].resetPassword.loading,
  response: state[moduleName].resetPassword.response,
  error: state[moduleName].resetPassword.error,
}), {
  resetPassword: resetPasswordAction,
  resetPasswordErrorClear: resetPasswordErrorClearAction,
})(ResetPassword);
