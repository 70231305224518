import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import AdministratorCreateContent, { HeaderActions } from '../../../components/Pages/Administrators/Create';
import Menu from '../../../components/Layout/Menu';
import AdministratorProvider from '../../../components/Pages/Administrators/Form/context';

const { displayName } = config;

export default function AdministratorCreate(): React.ReactNode | null {
  document.title = `${displayName}: Add administrator`;

  return (
    <AdministratorProvider>
      <Layout
        title="Add administrator"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <AdministratorCreateContent />
      </Layout>
    </AdministratorProvider>
  );
}
