import React from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useTransportGetAll } from '../../../../../../hooks/api/order';
import { ModeOfTransport } from '../../../Adapter/enums';
import { Option } from '../../../../../../types';

interface SelectModeTransportProps extends OrderSelectProps {
  disableAirFreight?: boolean;
}

function SelectModeTransport({
  fetch,
  selectProps = {},
  rest = {},
  disableAirFreight = false,
  ...props
}: SelectModeTransportProps) {
  const dualUseGet = useTransportGetAll();
  const form = useFormInstance();

  const handleBeforeSetOptions = (options: Option[]) => {
    /** Result with disabled option */
    const result = options.map((item) => (
      disableAirFreight ? { ...item, disabled: item.value === ModeOfTransport.IATA } : item
    ));

    /** If value is IATA and there's some item disabled - clear field value. (only IATA itself can be disabled) */
    if (form.getFieldValue(props.name) === ModeOfTransport.IATA && result.some((item) => item.disabled)) {
      form.setFieldValue(props.name, null);
    }

    return result;
  };

  return (
    <OrderSelect
      fetch={dualUseGet}
      selectProps={selectProps}
      rest={rest}
      beforeOptionsSet={{
        callback: handleBeforeSetOptions,
        trigger: disableAirFreight,
      }}
      {...props}
    />
  );
}

export default SelectModeTransport;
