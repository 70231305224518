import React, { useState } from 'react';
import {
  Alert, Input, Modal, Typography,
} from 'antd';
import { DefaultFetchError, FetchDelete, FetchSuccess } from '../../../../hooks/fetch';

interface DeleteConfirmProps {
  id: string | null;
  fetchHook: FetchDelete<FetchSuccess, DefaultFetchError, any>;
  open: boolean;
  handleOpen: (open: boolean) => void;
  title?: React.ReactNode;
  description?: React.ReactNode;
  okText?: React.ReactNode;
}

function DeleteConfirmModal({
  fetchHook,
  id,
  open,
  handleOpen,
  okText = 'Delete',
  title = 'Delete?',
  description = 'All data associated with this entity will be permanently deleted. This action cannot be undone.',
}: DeleteConfirmProps) {
  const [confirmText, setConfirmText] = useState('');

  return (
    <div tabIndex={-1} role="button" aria-label="delete modal" onClick={(event) => event.stopPropagation()}>
      <Modal
        open={open}
        onCancel={(e) => {
          e.stopPropagation();
          handleOpen(false);
        }}
        onOk={(e) => {
          e.stopPropagation();

          fetchHook.fetch(id).then((res) => {
            if (res?.success) { handleOpen(false); }
          });
        }}
        okText={okText}
        okButtonProps={{
          danger: true,
          disabled: confirmText !== 'DELETE',
          loading: fetchHook.loading,
        }}
        width={400}
        destroyOnClose
      >
        <Alert
          type="warning"
          showIcon
          style={{ background: 'none', border: 'none', padding: '0px' }}
          message={(<b>{title}</b>)}
          description={(
            <div>
              <p>
                {description}
              </p>

              <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                Type
                {' '}
                <b style={{ color: 'black' }}>&quot;DELETE&quot;</b>
                {' '}
                to confirm
              </Typography.Text>

              <Input
                placeholder="DELETE"
                value={confirmText}
                onChange={(event) => setConfirmText(event.target.value)}
              />
            </div>
        )}
        />
      </Modal>
    </div>
  );
}

export default DeleteConfirmModal;
