import React, { useEffect } from 'react';
import { Form, FormInstance } from 'antd';
import { useOrderServiceTypes } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';

interface SelectServiceTypeProps extends OrderSelectProps {
  form: FormInstance
}

function SelectServiceType({
  form,
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: SelectServiceTypeProps) {
  const deliveryService = Form.useWatch('deliveryService', form);
  const orderServiceTypes = useOrderServiceTypes();

  useEffect(() => {
    if (deliveryService) {
      orderServiceTypes.fetch({
        deliveryService,
      });
    }
  }, [deliveryService]);

  useEffect(() => {
    if (orderServiceTypes.data?.length === 1 && orderServiceTypes.data?.[0]?.value) {
      form.setFieldValue(props.name, orderServiceTypes.data?.[0]?.value);
    }
  }, [orderServiceTypes.data]);

  return (
    <OrderSelect fetch={orderServiceTypes} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectServiceType;
