import React from 'react';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectShipmentCreation({
  options,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  return (
    <OrderSelect
      options={options || [
        {
          label: 'Automatically',
          value: 'Automatically',
        },
        {
          label: 'Manually',
          value: 'Manually',
        },
      ]}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectShipmentCreation;
