import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import AdministratorEditContent, { HeaderActions } from '../../../../components/Pages/Administrators/View/Edit';
import Menu from '../../../../components/Layout/Menu';
import AdministratorProvider from '../../../../components/Pages/Administrators/Form/context';

const { displayName } = config;

export default function AdministratorEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit administrator`;

  return (
    <AdministratorProvider>
      <Layout
        title="Edit administrator"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <AdministratorEditContent />
      </Layout>
    </AdministratorProvider>
  );
}
