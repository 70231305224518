import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Menu from '../../../components/Layout/Menu';
import ServicesProvider from '../../../components/Pages/Services/Form/context';
import ServiceViewContent, { HeaderActions } from '../../../components/Pages/Services/View';

const { displayName } = config;

export default function ServiceView(): React.ReactNode | null {
  document.title = `${displayName}: View services`;

  return (
    <ServicesProvider>
      <Layout
        title="View services"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ServiceViewContent />
      </Layout>
    </ServicesProvider>
  );
}
