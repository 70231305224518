import React, { useEffect } from 'react';
import {
  Breadcrumb, Button,
} from 'antd';
import {
  NavLink, useNavigate, useParams,
} from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import AdministratorForm from '../../Form';
import Actions from '../../../../Common/Header/Actions';
import { useAdministratorContext } from '../../Form/context';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import {
  useAdministratorDelete,
  useAdministratorSendInvitation,
  useAdministratorUpdate,
} from '../../../../../hooks/api/users';
import { useAuth } from '../../../../../store/auth';
import { useSimpleModal } from '../../../../Common/Modal/Simple';

export function HeaderActions() {
  const simpleModal = useSimpleModal();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const {
    form, isValid, setInitialState, administratorGetById,
  } = useAdministratorContext();
  const administratorUpdate = useAdministratorUpdate(id);
  const administratorSendInvitation = useAdministratorSendInvitation(id || 'undefined');
  const administratorDelete = useAdministratorDelete(id || 'undefined');

  useMessageError([administratorUpdate, administratorSendInvitation, administratorDelete]);
  useMessageSuccess([administratorUpdate], 'Changes saved successfully');
  useMessageSuccess([administratorSendInvitation], 'Invitation sent successfully');
  useMessageSuccess([administratorDelete], 'Administrator deleted successfully');

  useEffect(() => {
    if (
      (!administratorUpdate.error && !administratorUpdate.loading && administratorUpdate.data)
      || (
        !administratorSendInvitation.error && !administratorSendInvitation.loading && administratorSendInvitation.data
      )
      || (!administratorDelete.error && !administratorDelete.loading && administratorDelete.data)
    ) {
      navigate('/administrators');
    }
  }, [
    administratorUpdate.error, administratorUpdate.loading, administratorUpdate.data,
    administratorSendInvitation.error, administratorSendInvitation.loading, administratorSendInvitation.data,
    administratorDelete.error, administratorDelete.loading, administratorDelete.data,
  ]);

  /** Block/Unblock user */
  const handleUserStatus = (newStatus: 'blocked' | 'active') => {
    if (administratorGetById.data?.id) {
      simpleModal.open({
        icon: <ExclamationCircleFilled />,
        title: `${newStatus === 'blocked' ? 'Block' : 'Unblock'} administrator?`,
        content: newStatus === 'blocked' ? (
          // eslint-disable-next-line max-len
          'Are you sure you want to block this administrator? Blocking will restrict the user’s access to the portal and prevent further interactions.'
        ) : (
          // eslint-disable-next-line max-len
          'Are you sure you want to unblock this administrator? Unblocking will restore the user’s access to the portal and allow them to interact again.'
        ),
        cancelText: 'Cancel',
        okText: `${newStatus === 'blocked' ? 'Block' : 'Unblock'} account`,
        okButtonProps: {
          danger: true,
        },
        onOk: () => administratorUpdate.fetch({ status: newStatus }, administratorGetById.data?.id),
      });
    }
  };

  return (
    <Actions>
      {administratorGetById?.data?.role === 'user' ? (
        <Button
          type="default"
          loading={administratorUpdate.loading || administratorGetById.loading}
          onClick={(e) => {
            e.preventDefault();
            handleUserStatus(administratorGetById.data?.status === 'blocked' ? 'active' : 'blocked');
          }}
        >
          {administratorGetById.data?.status === 'blocked' ? 'Unblock' : 'Block'}
        </Button>
      ) : null}

      {user?.id !== id ? (
        <Button
          danger
          loading={administratorDelete.loading}
          onClick={(e) => {
            e.preventDefault();

            simpleModal.open({
              icon: <ExclamationCircleFilled />,
              title: 'Delete administrator?',
              content: (
                <span>
                  Are you sure you want to delete administrator
                  {' '}
                  <b>{form?.getFieldValue('fullName')}</b>
                  ?
                </span>
              ),
              cancelText: 'Cancel',
              okText: 'Delete',
              okButtonProps: {
                danger: true,
              },
              onOk: () => administratorDelete.fetch(),
            });
          }}
        >
          Delete
        </Button>
      ) : null}
      {form?.getFieldValue('status') && form?.getFieldValue('status') !== 'active' ? (
        <Button
          loading={administratorSendInvitation.loading}
          onClick={(e) => {
            e.preventDefault();
            if (form) {
              administratorSendInvitation.fetch();
            }
          }}
        >
          Send invitation
        </Button>
      ) : null}
      <Button
        type="primary"
        disabled={!isValid}
        loading={administratorUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            administratorUpdate.fetch(data);
          }
        }}
      >
        Save
      </Button>

      {simpleModal.contextHolder}
    </Actions>
  );
}

export default function AdministratorEdit(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/administrators">Administrators</NavLink>,
          },
          {
            title: 'Edit administrator',
          },
        ]}
      />

      <AdministratorForm />
    </>
  );
}
