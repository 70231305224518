import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { AlertProps } from 'antd/es/alert';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useAuth } from '../../../../../store/auth';

import styles from './index.module.scss';

export type PaymentStatus = 'success' | 'failed' | 'canceled';
const statusIntoAlertType = (paymentStatus: PaymentStatus): AlertProps['type'] => {
  switch (paymentStatus) {
    case 'success': return 'success';
    case 'failed': return 'error';
    case 'canceled': return 'warning';
    default: return undefined;
  }
};

const footerContainer = (
  <p style={{ marginTop: '8px' }}>
    For any questions, please
    {' '}
    <a href="mailto:ramon@sephyre.com" target="_blank" rel="noreferrer">contact us</a>
  </p>
);

const getAlertPropsFromStatus = (paymentStatus: PaymentStatus, isPrepayment: boolean): {
  type: AlertProps['type'],
  title: string,
  description: React.ReactNode,
} => {
  switch (paymentStatus) {
    case 'success':
      return {
        // type: 'success',
        type: statusIntoAlertType(paymentStatus),
        title: 'Order successfully paid',
        description: (
          <>
            {isPrepayment ? (
              <>
                <p>
                  Please click the &quot;Place Order&quot; button to initiate the transfer to the delivery service.
                </p>
                <p>Take care of preparing products for shipment.</p>
              </>
            ) : null}
            {footerContainer}
          </>
        ),
      };
    case 'failed':
      return {
        // type: 'error',
        type: statusIntoAlertType(paymentStatus),
        title: 'Order Placement Rejected',
        description: (
          <>
            <p>Your payment could not be processed, and as a result, your order was not placed.</p>
            <p>Please check your payment details and try again.</p>
            {footerContainer}
          </>
        ),
      };
    case 'canceled':
      return {
        // type: 'warning',
        type: statusIntoAlertType(paymentStatus),
        title: 'Order Canceled',
        description: (
          <>
            <p>Your payment could not be processed, and as a result, your order was not placed.</p>
            <p>Please check your payment details and try again.</p>
            {footerContainer}
          </>
        ),
      };
    default: return { type: undefined, title: 'Alert', description: undefined };
  }
};

const availableStatuses = new Set(['success', 'failed', 'canceled']);

function PaymentAlert() {
  const [_, __, params] = useSearchParams();
  const { user } = useAuth();
  const [status, setStatus] = useState<PaymentStatus | null>(null);

  useEffect(() => {
    if (params?.payment && availableStatuses.has(params?.payment as string)) {
      setStatus(params.payment as PaymentStatus);
    }
  }, [params]);

  if (!status) {
    return null;
  }

  const currentItem = getAlertPropsFromStatus(status, user?.company?.paymentDays === 0);

  return (
    <Alert
      type={currentItem.type}
      showIcon
      message={(<b>{currentItem.title}</b>)}
      className={styles.alert}
      description={(
        <div className={styles.description}>
          {currentItem.description}
        </div>
      )}
    />
  );
}

export default PaymentAlert;
