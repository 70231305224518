import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  signUpByPhone as signUpByPhoneAction,
  SignUpByPhoneAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import {
  createRulesForAntd, validationSignUpPhoneAndPasswordOnly,
} from '../../../../../utils/validations';
import Form from '../Form';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import { ContentProps } from '../index';
import { useAuth } from '../../../../../store/auth';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

const validationRules = createRulesForAntd(validationSignUpPhoneAndPasswordOnly);

interface PhoneAndPasswordProps extends ContentProps {
  signUp: (payload: SignUpByPhoneAction) => Action;
}

function PhoneAndPassword({ signUp, onSubmit }: PhoneAndPasswordProps): React.ReactNode {
  const [signUpData, setSignUpData] = useState<SignUpByPhoneAction | null>(null);
  const { signUp: { response, error } } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit) {
      onSubmit(signUpData);
    }
  }, [response, error, onSubmit]);

  return (
    <>
      <Form<SignUpByPhoneAction>
        validationRules={validationRules}
        signUp={({ confirmPassword, ...props }) => {
          const data: SignUpByPhoneAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignUpData(data);
          signUp(data);
        }}
        name="signUpPhone"
      >
        <AntdForm.Item
          name="phone"
          label={`${t('phone')} *`}
          rules={[validationRules]}
        >
          <PhoneNumberInput
            placeholder="321567926"
            // placeholder={t('phone') || 'Phone'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          rules={[validationRules]}
        >
          <Input.Password
            placeholder={t('password') || 'Password'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="confirmPassword"
          label={`${t('confirmPassword')} *`}
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('confirmPassword') || 'Confirm password'}
            size="large"
          />
        </AntdForm.Item>
      </Form>

      <Separator />

      <AntdForm.Item>
        <ButtonGoogle />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonApple />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonFacebook />
      </AntdForm.Item>

      <Footer />
    </>
  );
}

export default connect(null, {
  signUp: signUpByPhoneAction,
})(PhoneAndPassword);
