import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Col, Divider, List, Modal, Row,
} from 'antd';

interface TermsOfServiceProps{
  handleOpen?: (modalOpen: boolean) => void,
  showFooter?: boolean,
}
const LIST_MAX_HEIGHT = 'min(calc(80vh - 106px), 600px)';
// TODO update sections
/* eslint-disable max-len */
const sections = [
  { title: 'Lorem ipsum dolor sit amet...', content: 'Curabitur vulputate lectus gravida arcu consequat, non tempus lorem varius. Etiam eget porttitor tellus. Cras et placerat nisl, non venenatis turpis. Fusce finibus tempor tellus viverra congue. Vestibulum vel sollicitudin arcu, non gravida ante. Mauris et diam lacus. Phasellus gravida iaculis nulla non tristique. Nulla ullamcorper vulputate eros. Vivamus pretium vulputate nibh sed porttitor. Vestibulum gravida est nec augue iaculis, id dapibus lacus pharetra.' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet long title idk some more text cuz its long...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
  { title: 'Lorem ipsum dolor sit amet...', content: 'Content Lorem ipsum dolor sit amet ipsum dolor sit amet ...' },
];

function TermsOfServiceModal({ handleOpen, showFooter = true }: TermsOfServiceProps) {
  const [open, setOpen] = useState(true);
  const [agreeEnabled, setAgreeEnabled] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const content = contentRef.current;

    if (content) {
      const scrollPosition = content.scrollHeight - content.scrollTop;
      const { clientHeight } = content;

      // Check if the user is within a gap of ±5 pixels from the bottom
      if (Math.abs(scrollPosition - clientHeight) <= 5) {
        setAgreeEnabled(true);
      }
    }
  };

  useEffect(() => {
    const content = contentRef.current;

    if (content) {
      content.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (content) {
        content.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Modal
      title="Terms of Service"
      open={open}
      centered
      closable={!showFooter}
      maskClosable={false}
      width={952}
      footer={
        showFooter ? [
          <Divider key="divider" />,
          <span key="footer-text" style={{ marginRight: '24px' }}>
            By clicking “Agree and Continue”, you agree to our Terms of Service
          </span>,
          <Button
            key="agree"
            type="primary"
            disabled={!agreeEnabled}
            onClick={() => {
              setOpen(false);
            }}
          >
            Agree and Continue
          </Button>,
        ] : []
}
      onCancel={() => {
        setOpen(false);
        handleOpen?.(false);
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <div style={{ maxHeight: LIST_MAX_HEIGHT, overflowY: 'auto', padding: '0 4px 0 0' }}>
            <List
              dataSource={sections}
              renderItem={({ title }, index) => (
                <List.Item>
                  <a href={`#section-${index}`} className="truncate3">
                    {`${index + 1}. ${title}`}
                  </a>
                </List.Item>
              )}
            />
          </div>
        </Col>

        <Col span={16}>
          <div
            ref={contentRef}
            style={{
              minHeight: '200px', maxHeight: LIST_MAX_HEIGHT, overflowY: 'auto', padding: '0 16px',
            }}
          >
            {sections.map(({ title, content }, index) => (
              <div key={crypto.randomUUID()} id={`section-${index}`}>
                <h3>
                  {`${index + 1}. ${title}`}
                </h3>
                <p>
                  {index + 1}
                  {'. '}
                  {content}
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default TermsOfServiceModal;

export function TermsOfServiceButton({
  variant = 'button',
  text = 'Terms of Service',
  modalProps,
}: { text?: string, variant?: 'button' | 'link', modalProps?: TermsOfServiceProps }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
  };

  return (
    <>
      <Button onClick={handleClick} {...(variant === 'link' ? { type: 'link', size: 'small' } : {})}>
        {text}
      </Button>
      {isModalOpen && <TermsOfServiceModal showFooter={false} handleOpen={handleCloseModal} {...modalProps} />}
    </>
  );
}
