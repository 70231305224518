import React, { useCallback, useEffect, useState } from 'react';

import {
  Button, Collapse, CollapseProps, Form, Input,
} from 'antd';
import clsx from 'clsx';
import { ViewButton, ViewContent } from './view';
import { CreateButton, CreateContent } from './create';
import { EditButton, EditContent } from './edit';
import { FormName, useOrderContextForm } from '../../context';
import { Mode, useOrderContext } from '../../../View/context';
import { ClientDataGood } from '../../../Adapter';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { SelectOption } from '../../../../../../types/select';
import { reCalcNetValue } from './Form';
import { IconDangerousOutlined } from '../../../../../Common/Icon';

import styles from './index.module.scss';

const calcGoodsItem = (item: ClientDataGood) => {
  if (item) {
    /** if measure unit is 'L' */
    if (item.massUnit === 'L' && item.density) {
      if (item.innerPackaging) {
        return (
          (((item.innerPackagingQuantity || 0) * (item.innerPackagingNetUnitaryQuantity || 0)) * item.density)
          * (item.quantity || 1)
        );
      }

      /** if not inner packaging and mass unit 'L' */
      return ((item.net || 0) * item.density) * (item.quantity || 1);
    }

    /** If item measure unit is KG */
    return item.innerPackaging
      ? (
        ((item.innerPackagingQuantity || 0)
          * (item.innerPackagingNetUnitaryQuantity || 0))
        * (item.quantity || 1)
      )
      : (item.net || 0) * (item.quantity || 1);
  }

  return 0;
};

export interface ButtonProps extends FormName {
  item: ClientDataGood;
  index: number;
  remove: () => void;
}

export type ContentProps = ButtonProps

function CollapseQuantity({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();

  return Form.useWatch([formName, index, 'quantity'], form);
}

function CollapsePackaging({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();

  return Form.useWatch([formName, index, 'packaging'], form)?.label;
}

function CollapseName({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();

  return Form.useWatch([formName, index, 'name'], form);
}

function CollapseIsDangerousGoods({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const goodsIsDangerous = Form.useWatch([formName, index, 'dangerousGoods'], form);

  return goodsIsDangerous ? <IconDangerousOutlined /> : null;
}

function CollapseNetWeight({ formName, index }: { index: number } & FormName) {
  const { forms: { [formName]: form } } = useOrderContextForm();
  const weight = Form.useWatch([formName, index, 'net'], form);
  const massUnit = Form.useWatch([formName, index, 'massUnit'], form);

  if (weight) {
    return `${weight} ${massUnit}`;
  }

  return '';
}

interface ProductProps extends FormName {
  list?: ClientDataGood[];
  update?: () => void;
  modeRewrite?: Mode;
}

function Product({
  list, update,
  formName = 'goods',
  modeRewrite,
}: ProductProps) {
  const { product } = useSearchParams()[2];
  const { mode: modeContext } = useOrderContext();
  const { forms: { goods: form } } = useOrderContextForm();
  const [currentList, setCurrentList] = useState(list || []);
  const [items, setItems] = useState<CollapseProps['items']>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>('0');
  const formList = Form.useWatch(['goods'], form);

  const mode = modeRewrite || modeContext;

  const createItem = useCallback((item: ClientDataGood, index: number, remove: () => void) => {
    const label = (
      <div className={styles.label}>
        <div className={styles.x}>
          {mode === 'view'
            ? item?.quantity
            : <CollapseQuantity formName={formName} index={index} />}
        </div>
        <div>
          {mode === 'view'
            ? (item?.packaging as SelectOption)?.label
            : <CollapsePackaging formName={formName} index={index} />}
        </div>
        <div>
          {mode === 'view'
            ? item?.name
            : <CollapseName formName={formName} index={index} />}
        </div>

        <div className={styles.centered}>
          {mode === 'view'
            ? item?.dangerousGoods
            : <CollapseIsDangerousGoods formName={formName} index={index} />}
        </div>

        <div className={styles.end}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {mode === 'view'
            // ? (item.net ? `${item.net} ${item.massUnit}` : '')
            ? (item?.net ? `${reCalcNetValue({
              net: item?.net,
              innerPackagingNetUnitaryQuantity: item?.innerPackagingNetUnitaryQuantity?.toString(),
              innerPackagingQuantity: item?.innerPackagingQuantity?.toString(),
            })} ${item.massUnit}` : '')
            : <CollapseNetWeight formName={formName} index={index} />}
        </div>
      </div>
    );

    switch (mode) {
      case 'create':
        return {
          forceRender: true,
          key: index,
          label,
          children: <CreateContent formName={formName} item={item} index={index} remove={remove} />,
          extra: <CreateButton formName={formName} item={item} index={index} remove={remove} />,
        };
      case 'edit': return {
        forceRender: true,
        key: index,
        label,
        children: <EditContent formName={formName} item={item} index={index} remove={remove} />,
        extra: <EditButton formName={formName} item={item} index={index} remove={remove} />,
      };
      case 'view': return {
        forceRender: true,
        key: index,
        label,
        children: <ViewContent formName={formName} item={item} index={index} remove={remove} />,
        extra: !modeRewrite ? (
          <ViewButton
            formName={formName}
            item={item}
            index={index}
            remove={() => {
              if (update) {
                update();
              }
            }}
          />
        ) : undefined,
      };
      default: return {};
    }
  }, []);

  useEffect(() => {
    if (list) {
      setCurrentList(list);
    }
  }, [list]);

  useEffect(() => {
    if (formList) {
      setCurrentList(formList);
    }
  }, [formList]);

  useEffect(() => {
    setItems(currentList?.map((item, index) => createItem(
      item,
      index,
      () => currentList.filter(((_, id) => id !== index)),
    )));
  }, [currentList]);

  // const massUnit = [...(new Set((currentList || []).map((value) => value.massUnit)))];

  const calcNetTotal = (): React.ReactNode => (
    Number.parseFloat((currentList || []).reduce((
      accumulator,
      value,
    ) => accumulator + calcGoodsItem(value), 0).toFixed(3))
  );

  return (
    <>
      {mode === 'view' && items && items.length > 0 ? (
        <Collapse
          className={styles.collapse}
          size="small"
          items={items}
        />
      ) : null}

      {mode === 'edit' || mode === 'create' ? (
        <Form.List
          name={formName}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }) => (
                <React.Fragment key={key}>
                  <Collapse
                    defaultActiveKey={[product as string]}
                    className={styles.collapse}
                    size="small"
                    accordion
                    items={[createItem(rest as ClientDataGood, name, () => remove(name))]}
                    onChange={(keyCollapse) => setActiveKey(keyCollapse)}
                    activeKey={activeKey}
                  />
                  <Form.Item hidden name={[name, 'id']}>
                    <Input />
                  </Form.Item>
                </React.Fragment>
              ))}
              <div className={styles.create}>
                <Button onClick={(e) => {
                  e.preventDefault();
                  add();
                  setActiveKey([fields?.length.toString()]);
                }}
                >
                  Add
                </Button>
              </div>
            </>
          )}
        </Form.List>
      ) : null}

      <div className={clsx(styles.calc, { [styles.empty]: items && items.length === 0 && mode === 'view' })}>
        <div className={styles.units}>
          <b>{(currentList || []).reduce((accumulator, value) => accumulator + (value?.quantity || 0), 0)}</b>
          unit(s)
        </div>
        <div className={styles.total}>
          <div>
            Net (total):
            {' '}
            <b>
              {calcNetTotal()}
              {' kg'}
              {/* {massUnit.length > 1 ? 'Kg/L' : massUnit[0]} */}
            </b>
          </div>
          <div style={{ marginTop: '3px' }}>
            Gross (total):
            {' '}
            <b>
              {Number.parseFloat((currentList || []).reduce((accumulator, value) => accumulator
                + (value?.innerPackaging
                  ? ((value?.gross || 0) * (value?.quantity || 1))
                  : (value?.gross || value?.net || 0) * (value?.quantity || 1)
                ), 0).toFixed(3))}
              {' '}
              kg
            </b>
          </div>
          <div>
            Volume (total):
            {' '}
            <b>
              {Number.parseFloat((currentList || []).reduce((accumulator, value) => accumulator
                + ((value?.volume || 0) * (value?.quantity || 1)), 0).toFixed(3))}
              {' '}
              m
              <sup>3</sup>
            </b>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
