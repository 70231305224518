import React from 'react';
import { config } from '@triare/auth-redux';
import { Form } from 'antd';
import Layout from '../../../components/Layout';
import OrderCreateContent, { HeaderActions } from '../../../components/Pages/Orders/Create';
import Menu from '../../../components/Layout/Menu';
import OrderProviderForm from '../../../components/Pages/Orders/Form/context';
import OrderProvider from '../../../components/Pages/Orders/View/context';

const { displayName } = config;

export default function Administrators(): React.ReactNode | null {
  document.title = `${displayName}: Add order`;

  return (
    <OrderProvider mode="create">
      <OrderProviderForm>
        <Layout
          title="Add order"
          leftMenu={<Menu />}
          headerContent={<HeaderActions />}
        >
          <OrderCreateContent />
        </Layout>
      </OrderProviderForm>
    </OrderProvider>
  );
}
