import React, {
  Key, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { RequestData } from '@ant-design/pro-table';
import { Button, Empty } from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import { getSorterParams, queryFilterParams } from '../../../utils';
import Table, { listAction, TableOnRowSelect } from '../../Common/Table';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useAuth } from '../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';

import { IServiceTable, useServicesDelete, useServicesTableGet } from '../../../hooks/api/services';

function DeleteButton({ record, setTableKey }: { record: IServiceTable } & AnyObject) {
  const { user } = useAuth();
  const serviceDelete = useServicesDelete();
  const { open, contextHolder } = useSimpleModal();

  useMessageError([serviceDelete]);
  useMessageSuccess([serviceDelete], 'Service deleted successfully');

  useEffect(() => {
    if (!serviceDelete.error && !serviceDelete.loading && serviceDelete.data) {
      setTableKey(Date.now());
    }
  }, [serviceDelete.error, serviceDelete.loading, serviceDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={serviceDelete.loading}
        title="Delete service"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete service?',
            content: (
              <span>
                Are you sure you want to delete service
                {' '}
                <b>{record.name}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => serviceDelete.fetch(record.id),
          });
        }}
      />
      {contextHolder}
    </>
  );
}

function ToolBar() {
  const navigate = useNavigate();

  return [
    <Button
      key="add"
      icon={<PlusCircleOutlined />}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        navigate('create');
      }}
    >
      Add
    </Button>,
  ];
}

export default function ServicesContent({
  params, selectedRows, onRowSelection,
}: TableOnRowSelect): React.ReactNode | null {
  const navigate = useNavigate();
  const servicesGet = useServicesTableGet();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());

  const columns: ProColumns<IServiceTable>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      hideInSearch: true,
      onCell: () => ({
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        },
      }),
    },
    {
      title: 'Price',
      dataIndex: 'value',
      key: 'value',
      hideInSearch: true,
      render: (value) => (
        `${value} CHF`
      ),
    },
    {
      key: 'updatedAt',
      title: 'Last updated',
      dataIndex: 'updatedAt',
      hideInSearch: true,
      render: (text, record) => (
        dayjs(record.updatedAt).format('DD/MM/YYYY')
      ),
    },
  ];

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<IServiceTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...params, ...getSorterParams(sorter) }), { replace: true });

    const response = await servicesGet.fetch({
      ...newParams,
      ...params,
      tab: undefined,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([servicesGet]);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as string[]); // as number[]
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<IServiceTable>
      locale={{ emptyText: <Empty description="No services added yet" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
      key={tableKey}
      className="transparent"
      rowClassName={onRowSelection ? undefined : 'cursor-pointer'}
      headerTitle="List of services"
      toolBarRender={onRowSelection ? undefined : ToolBar}
      request={tableRequest}
      columns={columns}
      onRow={onRowSelection ? undefined : (record) => ({
        onClick: (event) => {
          event.preventDefault();
          navigate(`/services/${record.id}`);
        },
      })}
      actions={onRowSelection ? undefined : [
        ['edit', ({ record }) => ({
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            navigate(`${record.id}/edit`);
          },
        })],
        [DeleteButton, {
          setTableKey,
        }],
      ]}
      search={{
        searchText: 'Filter',
        resetText: 'Clear',
        style: {
          display: 'none',
        },
      }}
      options={{
        search: {
          name: 'search',
        },
      }}
      columnsState={{ persistenceKey: 'pro-table-services', persistenceType: 'localStorage' }}
      rowSelection={!!onRowSelection && rowSelection}
    />
  );
}
