import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  useFetchCreate,
  useFetchDelete,
  useFetchGetId, useFetchUpdate,
} from '../fetch';

export interface DeliveryNote extends DeliveryNoteCreateParams {
  id: string;
  createdAt: string;
  updatedAt: string;
  number?: string;
}

export interface DeliveryNoteCreateParams {
  description: string;
  units: string; // ServiceUnitEnum;
}

export const useDeliveryNoteGetByOrderId = <DD = DeliveryNote>(
  orderId: string,
  id?: string,
  decorateData?: (data: DeliveryNote) => DD,
): FetchGetId<
  DeliveryNote,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `orders/${orderId}/delivery-notes`,
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export const useDeliveryNoteResend = (
  orderId: string,
  id?: string,
): FetchCreate<FetchSuccess, DefaultFetchError> => (
  useFetchCreate(`orders/${orderId}/delivery-notes${id ? `/${id}/send-by-mail` : ''}`)
);

export const useDeliveryNoteDelete = (orderId: string, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`orders/${orderId}/delivery-notes${id ? `/${id}` : ''}`)
);

export const useDeliveryNoteDownload = (
  orderId: string,
  id?: string,
  abortController?: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    `orders/${orderId}/delivery-notes${id ? `/${id}/download-as-pdf` : ''}`,
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal,
      },
    },
    'arraybuffer',
  )
);

export const useOrderDeliveryNoteCreate = (
  orderId?: string,
): FetchCreate<DeliveryNote, DefaultFetchError, DeliveryNoteCreateParams> => (
  useFetchCreate(`orders/${orderId}/delivery-notes`)
);

export const useDeliveryNoteUpdate = (
  orderId?: string,
  id?: string,
): FetchUpdate<DeliveryNote, DefaultFetchError, DeliveryNoteCreateParams> => (
  useFetchUpdate(`orders/${orderId}/delivery-notes${id ? `/${id}` : ''}`)
);
