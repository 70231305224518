import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ServiceDetails } from '../../../../../../../../hooks/api/invoices';
import { useServiceUsedNow } from '../../../../../../../../hooks/api/services';
import { useOrderContext } from '../../../../../View/context';
import ServicesList from './ServicesList';
import { useMessageError } from '../../../../../../../../hooks/common';

interface ChooseServiceButtonProps {
  add: (defaultValue?: Partial<ServiceDetails>) => void,
}

function ChooseServiceButton({ add }: ChooseServiceButtonProps) {
  const { clientOrderData } = useOrderContext();
  const [open, setOpen] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const serviceUsedNow = useServiceUsedNow();

  const isLoading = false;

  const handleAddServices = async () => {
    if (!selectedRowIds?.length) return;
    let selectedItems: Partial<ServiceDetails>[] = [];

    await Promise.all(selectedRowIds.map((id) => serviceUsedNow.fetch(undefined, `${id}/used-now`)))
      .then((res) => {
        if (res.every((el) => el?.id)) {
          const preparedValues: Partial<ServiceDetails>[] = res.map((item) => ({
            name: item?.name,
            quantity: 1,
            units: item?.units,
            value: item?.value,
            valueCHF: item?.valueCHF || item?.value,
            tax: clientOrderData?.company?.country === 'Switzerland' ? 8.1 : 0,
          }));

          selectedItems = [...selectedItems, ...preparedValues];
        }
      });

    selectedItems.forEach((service) => {
      add(service);
    });
    setSelectedRowIds([]);
  };

  useMessageError([serviceUsedNow]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Choose service</Button>
      <Modal
        centered
        open={open}
        title="Services"
        className="modal-with-table"
        okText="Add"
        okButtonProps={{
          disabled: !selectedRowIds?.length,
        }}
        onOk={async () => {
          await handleAddServices();
          setOpen(false);
        }}
        onCancel={() => {
          if (!isLoading) {
            setOpen(false);
          }
        }}
        width={800}
        maskClosable={false}
        destroyOnClose
      >
        <ServicesList
          selectedRows={selectedRowIds}
          onRowSelection={setSelectedRowIds}
        />
      </Modal>
    </>
  );
}

export default ChooseServiceButton;
