import React, { useEffect, useState } from 'react';

import {
  Divider,
  Form, FormInstance, Input, Select, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  createRulesForAntd, createValidatorTextField, phone, email,
  ValidatorProps,
} from '../../../../utils/validations';
import { useCompanyContext } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { Company, CompanyPaymentTypeEnum, CompanyRoleEnum } from '../../../../hooks/api/company';
import { useMessageError } from '../../../../hooks/common';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { enumToOptionsArray, formatPhoneNumber, getOnlyValueRecursive } from '../../../../utils';
import SelectPlace from '../../Orders/Form/Common/SelectPlace';
import { useAuth, UserRole } from '../../../../store/auth';
import { isRoleEnough } from '../../../../enums/user';

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);
export const createValidatorEmailForInvoices = (text: ValidatorProps) => yup.string()
  .email(text[0] || 'Invalid email');
export const emailForInvoices: yup.StringSchema = createValidatorEmailForInvoices([]);

// export const validationCreateCompany = yup.object().shape({
export const validationCreateCompany = (userRole?: UserRole) => yup.object().shape({
  companyName: textFieldRequired,
  contactName: textFieldRequired,
  phone,
  email,
  emailForInvoices,
  country: textFieldRequired,
  address1: textFieldRequired,
  address2: textField,
  postalCode: textFieldRequired,
  city: textFieldRequired,
  state: textFieldRequired,
  tinNumber: textField,
  registerNumber: textField,
  /** Required for admin and not for customer */
  paymentDays: yup.number().when([], {
    is: () => isRoleEnough(userRole, 'admin'),
    then: yup.number().required('Required'),
    otherwise: yup.number().notRequired(),
  }),
  role: yup.string().when([], {
    is: () => isRoleEnough(userRole, 'admin'),
    then: textFieldRequired,
    otherwise: yup.string().notRequired(),
  }),
});

export interface CompanyFormProps extends AnyObject {
  createProfileView?: boolean;
  footer?: React.ReactNode;
  dataSource?: Company;
  roleEnabled?: boolean;
}

function FullAddress({ form }: { form: FormInstance; }) {
  const { user } = useAuth();
  const validationRules = createRulesForAntd(validationCreateCompany(user?.role));

  return (
    <>
      <SelectPlace
        addressName="address1"
        type={['country']}
        form={form}
        label="Country"
        name="country"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
        onSelect={() => {
          ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
            form.setFieldValue(key, undefined);
          });
        }}
      />
      <SelectPlace
        addressName="address1"
        type={['address']}
        form={form}
        label="Address"
        name="address1"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <Form.Item<Company>
        label="Address 2"
        name="address2"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>
      <SelectPlace
        addressName="address1"
        type={['postal_code']}
        form={form}
        label="Postal code"
        name="postalCode"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['locality']}
        form={form}
        label="City / Town"
        name="city"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['administrative_area_level_1']}
        form={form}
        label="Province / Region / State"
        name="state"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
    </>
  );
}

export default function CompanyForm({
  createProfileView, dataSource, footer, roleEnabled, ...props
}: CompanyFormProps): React.ReactNode | null {
  const { id } = useParams();
  const { user } = useAuth();
  const [form] = Form.useForm<Company>();
  const {
    setForm, setValid, initialState, setInitialState,
    companyGetById,
  } = useCompanyContext();

  // Replaced autoStart company-by-id with context company-by-id
  // const companyGetById = useCompanyGetById(createProfileView ? undefined : id);
  useEffect(() => {
    if (!createProfileView && id) {
      companyGetById.fetch(undefined, id);
    }
  }, [id, createProfileView]);

  const validationSchema = validationCreateCompany(user?.role);
  const validationRules = createRulesForAntd(validationSchema);

  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );
  const { loading, data, error } = companyGetById;

  useMessageError([companyGetById]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data && !createProfileView && !dataSource) {
      setInitialState(data);
      form?.setFieldsValue(data);
    }
  }, [loading, data, error]);

  useEffect(() => {
    setInitialState(dataSource as Company);
    form?.setFieldsValue(dataSource as Company);
  }, [dataSource]);

  return (
    <Form
      className={clsx('form-customized-cols', 'miniTopPadding')}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={async () => {
        setTriggerChange(Date.now());

        const formValues = form.getFieldsValue();

        try {
          await validationSchema.validate(formValues, { abortEarly: false });
          setValid(true);
        } catch {
          setValid(false);
        }
        // setValid(!(Object.keys(form.getFieldsValue())
        //   .filter((key) => !(['address2', 'registerNumber', 'tinNumber'].includes(key)))
        //   .map((key) => form.getFieldValue(key))
        //   .some((value) => value === '' || value === undefined)
        //   || form.getFieldsError().some(({ errors }) => errors.length)));
      }}
      initialValues={{ ...initialState }}
      {...props}
    >
      <Divider orientation="left">{user?.role === 'user' ? 'Company' : 'Customer'}</Divider>
      <Form.Item<Company>
        label="Company"
        name="companyName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      {isRoleEnough(user?.role, 'admin') ? (
        <>
          <Form.Item<Company>
            label="Payment type"
            name="paymentDays"
            rules={[validationRules, { required: true, message: <div /> }]}
          >
            <Select
              options={enumToOptionsArray(CompanyPaymentTypeEnum)
                .map((item) => (
                  { ...item, value: Number(item.value.replace('p', '')) }
                ))}
              placeholder="Select"
              allowClear
            />
          </Form.Item>
          <Form.Item<Company>
            label="Role"
            name="role"
            rules={[validationRules, { required: true, message: <div /> }]}
          >
            <Select
              options={enumToOptionsArray(CompanyRoleEnum)}
              placeholder="Select"
              disabled={roleEnabled ? !roleEnabled : true}
            />
          </Form.Item>
        </>
      ) : null}

      <Divider orientation="left">Contact</Divider>
      <Form.Item<Company>
        label="Contact name"
        name="contactName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Mobile number"
        name="phone"
        rules={[validationRules, { required: true, message: <div /> }]}
        getValueProps={formatPhoneNumber}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Email"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<Company>
        label="Email for invoices"
        name="emailForInvoices"
        rules={[validationRules, { required: false }]}
      >
        <Input />
      </Form.Item>

      <Divider orientation="left">Address</Divider>
      <FullAddress form={form} />

      <Divider orientation="left">Further Information</Divider>
      <Form.Item<Company>
        label="TIN number"
        name="tinNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      <Form.Item<Company>
        label="Register number"
        name="registerNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      {loading ? (
        <div className="spin-loading">
          <Spin />
        </div>
      ) : null}

      <Form.Item label=" " className="form-hidden-after">
        <div className="form-footer">
          {footer}
        </div>
      </Form.Item>
      {routeBlocker.contextHolder}
    </Form>
  );
}
