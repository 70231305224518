import React, { createContext, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { User } from '../../../../store/auth';
import { SimpleProviderProps } from '../../../../types';
import { useAdministratorGetById } from '../../../../hooks/api/users';
import { DefaultFetchError, FetchGetId } from '../../../../hooks/fetch';

export type UserForm = Pick<User, 'fullName' | 'email' | 'status'>

export interface AdministratorContextProps {
  initialState: UserForm | null,
  setInitialState: (data: UserForm) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
  administratorGetById: FetchGetId<User, DefaultFetchError, unknown, User>;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: UserForm) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
  administratorGetById: {} as FetchGetId<User, DefaultFetchError, unknown, User>,
};

export const AdministratorContext = createContext<AdministratorContextProps>(defaultValue);

function AdministratorProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<UserForm | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);
  const administratorGetById = useAdministratorGetById(); // id

  const memoizedValue = useMemo(() => ({
    initialState,
    setInitialState,
    form,
    setForm,
    isValid,
    setValid,
    administratorGetById,
  }), [initialState, setInitialState, form, setForm, isValid, setValid, administratorGetById]);

  return (
    <AdministratorContext.Provider value={memoizedValue}>
      {children}
    </AdministratorContext.Provider>
  );
}

export default AdministratorProvider;

export const useAdministratorContext = ():
  AdministratorContextProps => React.useContext(AdministratorContext);
