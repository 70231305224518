import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { useOrderContext } from '../context';
import { Company, useCompanyCreate } from '../../../../../hooks/api/company';
import { useOrderUpdate } from '../../../../../hooks/api/order';
import { useAuth } from '../../../../../store/auth';
import CompanyForm from '../../../Companies/Form';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import CompanyProvider, { useCompanyContext } from '../../../Companies/Form/context';

interface ChooseCustomerProps {
  handleOpen?: (modalOpen: boolean) => void;
  isModalOpen: boolean;
  showFooter?: boolean;
  handleOpenAddCustomer?: (modalOpen: boolean) => void;
}

const LIST_MAX_HEIGHT = 'min(calc(80vh - 106px), 600px)';

function AddNewCustomerModal({
  handleOpen, isModalOpen, handleOpenAddCustomer,
}: ChooseCustomerProps) {
  const { id } = useParams();
  const { user } = useAuth();
  const { form, isValid, setInitialState } = useCompanyContext();
  const [newCustomer, setNewCustomer] = useState<Company>();
  const orderUpdate = useOrderUpdate(id || '', user?.role === 'user');
  const { order, clientOrderData } = useOrderContext();
  const companyCreate = useCompanyCreate();

  useMessageError([companyCreate]);
  useMessageSuccess([companyCreate], 'Customer added successfully');
  useEffect(() => {
    if (!companyCreate.error && !companyCreate.loading && companyCreate.data) {
      if (companyCreate.data) {
        orderUpdate.fetch({
          company: companyCreate.data.id,
        });
        order.fetch();
      }
      order.fetch();
    }
  }, [companyCreate.error, companyCreate.loading, companyCreate.data]);

  useEffect(() => {
    const newData = {
      ...clientOrderData?.shipper,
      companyName: clientOrderData?.shipper.company,
      tinNumber: clientOrderData?.shipper.tin,
    };

    delete newData.company;
    delete newData.details;

    setNewCustomer(newData as Company);
  }, [clientOrderData?.shipper]);

  return (
    <Modal
      title="Add customer"
      open={isModalOpen}
      centered
      closable
      maskClosable={false}
      width={952}
      okButtonProps={{
        disabled: !isValid,
      }}
      onOk={() => {
        if (form) {
          const data = form.getFieldsValue();

          setInitialState(data);
          companyCreate.fetch(data);
        }

        handleOpen?.(false);
        handleOpenAddCustomer?.(false);
      }}
      okText="Save"
      onCancel={() => {
        handleOpen?.(false);
      }}
    >
      <div style={{ height: LIST_MAX_HEIGHT, overflowY: 'auto' }}>
        <CompanyForm createProfileView roleEnabled dataSource={newCustomer} />
      </div>
    </Modal>
  );
}

export default AddNewCustomerModal;

export function AddNewCustomerModalButton({
  variant = 'button',
  text = 'Add customer',
  modalProps,
  handleOpenAddCustomer,
}: {
  text?: string;
  variant?: 'button' | 'link';
  modalProps?: ChooseCustomerProps;
  handleOpenAddCustomer?: (modalOpen: boolean) => void;
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
  };

  return (
    <>
      <Button onClick={handleClick} {...(variant === 'link' ? { type: 'link', size: 'small' } : {})}>
        {text}
      </Button>
      <CompanyProvider>
        <AddNewCustomerModal
          handleOpen={handleCloseModal}
          handleOpenAddCustomer={handleOpenAddCustomer}
          isModalOpen={isModalOpen}
          {...modalProps}
        />
      </CompanyProvider>
    </>
  );
}
