import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import CompanyCreateContent, { HeaderActions } from '../../../components/Pages/Companies/Create';
import Menu from '../../../components/Layout/Menu';
import CompanyProvider from '../../../components/Pages/Companies/Form/context';

const { displayName } = config;

export default function CompanyCreate(): React.ReactNode | null {
  document.title = `${displayName}: Add customer`;

  return (
    <CompanyProvider>
      <Layout
        title="Add customer"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <CompanyCreateContent />
      </Layout>
    </CompanyProvider>
  );
}
