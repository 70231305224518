import React, { CSSProperties } from 'react';
import {
  Form, Input, InputNumber, InputNumberProps, InputProps,
} from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { TextAreaProps } from 'antd/es/input';
import { AnyObject } from '@triare/auth-redux';

import styles from '../../index.module.scss';

export interface OrderInputProps extends FormItemProps {
  prefix?: string;
  rules?: Rule[];
  label?: string | React.ReactNode;
  name: NamePath<string>;
  textarea?: true;
  inputNumber?: boolean;
  style?: CSSProperties;
  children?: React.ReactNode;
  textAreaProps?: TextAreaProps;
  inputProps?: InputProps;
  inputNumberProps?: InputNumberProps;
  rightText?: string | false;
  rest?: AnyObject;
}

function OrderInput({
  inputNumber = false,
  inputProps = {},
  textAreaProps = {
    rows: 4,
  },
  rest = {},
  textarea,
  children,
  inputNumberProps,
  rightText,
  ...props
}: OrderInputProps) {
  return (
    <Form.Item
      {...rest}
      {...props}
      extra={(
        <>
          {children}
          {rightText === false ? null : (
            <div className={styles.text}>
              {rightText}
            </div>
          )}
        </>
      )}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {textarea ? (
        <Input.TextArea
          {...textAreaProps}
        />
      ) : (inputNumber ? (
        <InputNumber
          min={0}
          {...inputNumberProps}
        />
      ) : (
        <Input
          {...inputProps}
        />
      ))}
    </Form.Item>
  );
}

export default OrderInput;
