import React from 'react';
import CustomerProfileForm from '../../Form/Profile';

function CustomerEdit() {
  return (
    <CustomerProfileForm />
  );
}

export default CustomerEdit;
