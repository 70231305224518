import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Form, FormInstance, Select, SelectProps,
} from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { AnyObject } from '@triare/auth-redux';
import OrderInput from '../Input';

import styles from '../../index.module.scss';

interface SelectPureMixSolutionProps extends FormItemProps {
  prefix?: string;
  rules?: Rule[];
  label: string;
  name: [NamePath<string>, NamePath<string>];
  style?: CSSProperties;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectProps?: SelectProps<any, any>;
  rightText?: string;
  rest?: AnyObject;
  form: FormInstance;
}

function SelectPureMixSolution({
  form,
  prefix,
  selectProps = {},
  rest = {},
  name,
  children,
  rightText,
  ...props
}: SelectPureMixSolutionProps) {
  const pureMixSolution = Form.useWatch([prefix, ...(name[0] as string[])], form);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    setDisable(!pureMixSolution || pureMixSolution === 'Pure');
  }, [pureMixSolution]);

  return (
    <Form.Item
      {...props}
      {...rest}
      name={name[0]}
      className={disable ? undefined : props.className}
      initialValue="Pure"
      extra={(
        <>
          <OrderInput
            inputNumber
            name={name[1]}
            inputNumberProps={{
              suffix: '%',
              controls: false,
            }}
            rightText={false}
            style={disable ? { display: 'none' } : undefined}
          />

          {children}
          <div className={styles.text}>
            {rightText}
          </div>
        </>
      )}
    >
      <Select
        defaultValue="Pure"
        options={[
          {
            label: 'Pure',
            value: 'Pure',
          },
          {
            label: 'Mix',
            value: 'Mix',
          },
          {
            label: 'Solution',
            value: 'Solution',
          },
        ]}
        {...selectProps}
      />
    </Form.Item>
  );
}

export default SelectPureMixSolution;
