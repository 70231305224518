import React, { CSSProperties, useEffect, useState } from 'react';
import { Form, Select, SelectProps } from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { AnyObject } from '@triare/auth-redux';
import { useMessageError } from '../../../../../../hooks/common';
import { Option } from '../../../../../../types';
import { ResponseForSelect } from '../../../../../../hooks/api/order';
import { FetchGet } from '../../../../../../hooks/fetch';

import styles from '../../index.module.scss';

export interface OrderSelectProps extends FormItemProps {
  prefix?: string;
  rules?: Rule[];
  label?: string;
  name: NamePath<string>;
  style?: CSSProperties;
  children?: React.ReactNode;
  rightText?: string | false;
  rest?: AnyObject;
  options?: Option[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch?: FetchGet<any, any, any, ResponseForSelect[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectProps?: SelectProps<any, any>;
  /** Function which called before set options. Triggers after fetch response and when 'trigger' value changes */
  beforeOptionsSet?: {
    callback: (options: Option[]) => Option[];
    trigger?: number | boolean | string;
  },
}

function OrderSelect({
  selectProps = {},
  rest = {},
  options, fetch, children, rightText, beforeOptionsSet, ...props
}: OrderSelectProps) {
  const [fetchOptions, setFetchOptions] = useState<Option[] | undefined>(options);

  useMessageError([fetch]);

  useEffect(() => {
    if (fetch && fetch?.data) {
      let newOptions = fetch.data;

      /** Preparing options with new value */
      if (beforeOptionsSet?.callback) {
        newOptions = beforeOptionsSet.callback(newOptions);
      }
      setFetchOptions(newOptions);
    }
  }, [fetch?.data, beforeOptionsSet?.trigger]);

  useEffect(() => {
    if (options?.length) {
      setFetchOptions(options);
    }
  }, [options]);

  return (
    <Form.Item
      {...rest}
      {...props}
      extra={(
        <>
          {children}
          {rightText === false ? null : (
            <div className={styles.text}>
              {rightText}
            </div>
          )}
        </>
      )}
    >
      <Select
        allowClear
        showSearch
        loading={fetch?.loading}
        options={fetchOptions}
        filterOption={(input, option) => (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase())}
        {...selectProps}
      />
    </Form.Item>
  );
}

export default OrderSelect;
