import React, { useEffect, useState } from 'react';

import {
  Form, InputNumber, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import clsx from 'clsx';
import { createValidatorTextField } from '../../../../../utils/validations';
import { customizeRequiredMark } from '../../../../Common/Form/Input/common';
import { useMessageError } from '../../../../../hooks/common';
import useRouteBlocker from '../../../../Common/RouteBlocker';
import { getOnlyValueRecursive } from '../../../../../utils';
import { DefaultServices, useDefaultServicesGet } from '../../../../../hooks/api/services';
import { useServicesContext } from './context';
import { useBlockerContext } from '../../../../../context/blockerDisabler';

import styles from '../../../../Common/Form/index.module.scss';

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);
export const valueField: yup.StringSchema = textFieldRequired.min(1, 'Must be at least 1 characters');

export const validationCreateService = yup.object().shape({
  deliveryMultiplayer: valueField,
  platformUsageFee: valueField,
  insuranceFee: valueField,
});

type ServicesFormProps = AnyObject

export default function DefaultServicesForm({ ...props }: ServicesFormProps): React.ReactNode | null {
  const [form] = Form.useForm<DefaultServices>();
  const defaultServiceData = useDefaultServicesGet();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useServicesContext();
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );
  const { handleIsBlockerActive } = useBlockerContext();
  const { loading, data, error } = defaultServiceData;

  useMessageError([defaultServiceData]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      setInitialState(data);
      form?.setFieldsValue(data);
    }
  }, [loading, data, error]);

  useEffect(() => {
    handleIsBlockerActive(false);
  }, [initialState]);

  return (
    <Form
      className={clsx(styles.form, styles.miniTopPadding)}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={async () => {
        setTriggerChange(Date.now());
        handleIsBlockerActive(true);
        const formValues = form.getFieldsValue();

        try {
          await validationCreateService.validate(formValues, { abortEarly: false });
          setValid(true);
        } catch {
          setValid(false);
        }
      }}
      initialValues={{ ...initialState }}
      {...props}
    >
      <Form.Item<DefaultServices>
        label="Shipping door-to-door"
        name="deliveryMultiplayer"
        rules={[{ required: true, message: 'Must be at least 1 characters' }]}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          min={0}
          max={9999}
          suffix="Coefficient"
          controls={false}
          style={{ width: '100%' }}

        />
      </Form.Item>
      <Form.Item<DefaultServices>
        label="Platform usage fee"
        name="platformUsageFee"
        rules={[{ required: true, message: 'Must be at least 1 characters' }]}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          min={0}
          max={9999}
          suffix="CHF"
          controls={false}
          style={{ width: '100%' }}

        />
      </Form.Item>
      <Form.Item<DefaultServices>
        label="Insurance fee"
        name="insuranceFee"
        rules={[{ required: true, message: 'Must be at least 1 characters' }]}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          min={0}
          max={9999}
          suffix="CHF"
          controls={false}
          style={{ width: '100%' }}

        />
      </Form.Item>

      {loading ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : null}

      {routeBlocker.contextHolder}
    </Form>
  );
}
