import { Button } from 'antd';

const handleClick = () => {
  window.open('https://www.sephyre.com/data-privacy/', '_blank');
};

export default function PrivacyPolicy() {
  return (
    <div>
      <span>
        Review our Privacy Policy to understand how we collect, use, and protect your personal information. Your
        <br />
        privacy is our top priority.
      </span>
      <div style={{ marginTop: '21px' }}>
        <Button onClick={handleClick}>
          View Privacy Policy
        </Button>
      </div>
    </div>
  );
}
