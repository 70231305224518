import React, { useEffect, useState } from 'react';
import {
  SignInAction,
  SignInByPhoneAction, SignInByServiceAction,
  SignInByUsernameAction,
  SignInResponse,
} from '@triare/auth-redux/dist/saga/auth/signIn';
import { useNavigate } from 'react-router-dom';
import OTPCode from './OTPCode';
import { useAuth } from '../../../../store/auth';
import Wrapper from '../Wrapper';

type Values = SignInAction | SignInByUsernameAction | SignInByPhoneAction | SignInByServiceAction | null;

export interface ContentProps {
  onSubmit?: (values: Values) => void
}

export interface SignInProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any
}

export default function SignIn({ content: Content }: SignInProps) {
  const { signIn: { response, error }, OTP, authorized } = useAuth();
  const [state, setState] = useState<SignInResponse>();
  const [requestData, setRequestData] = useState<Values>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized && (
      (state && state.secretKey && !OTP.error && !OTP.response) || (!error && !response && requestData))
    ) {
      navigate('/');
    }
  }, [authorized, error, response, OTP.error, OTP.response, state, requestData]);

  useEffect(() => {
    if (response && response.secretKey) {
      setState(response);
    }
  }, [response]);

  return (
    <Wrapper>
      {state && state.secretKey && requestData ? (
        <OTPCode
          onBack={() => setState(undefined)}
          email={requestData?.email}
        />
      ) : (
        <Content onSubmit={setRequestData} />
      )}
    </Wrapper>
  );
}
