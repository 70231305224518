import React, { useEffect, useMemo, useState } from 'react';
import { Badge, BadgeProps, Col } from 'antd';
import dayjs from 'dayjs';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EuroCircleOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import InvoicesModal from '../../../Invoices/View';
import { useOrderContext } from '../context';
import { capitalize } from '../../../../../utils';
import { getInvoiceStatusColor } from '../../../Invoices';
import IconWithBadge from '../../../../Common/IconWithBadge';
import { Invoice, InvoiceStatusType, prioritizedInvoiceServices } from '../../../../../hooks/api/invoices';
import { isRoleEnough } from '../../../../../enums/user';
import { useAuth, UserRole } from '../../../../../store/auth';

import styles from './index.module.scss';

type InvoiceStatusReturnType = { color: BadgeProps['color'], icon: React.ElementType };

export const getInvoiceStatusIcon = (status?: InvoiceStatusType): InvoiceStatusReturnType => {
  switch (status) {
    case 'invisible': /** Inivisible should not be displayed in list */
      return {
        icon: MinusCircleFilled,
        color: '',
      };

    case 'draft':
      return {
        icon: MinusCircleFilled,
        color: '',
      };

    case 'pending':
      return {
        icon: InfoCircleFilled,
        color: 'gold',
      };

    case 'paid':
      return {
        icon: CheckCircleFilled,
        color: 'green',
      };

    case 'expired':
      return {
        icon: CloseCircleFilled,
        color: 'red',
      };

    case 'overdue':
      return {
        icon: ExclamationCircleFilled,
        color: 'red',
      };

    default: // Draft
      return {
        icon: MinusCircleFilled,
        color: '',
      };
  }
};

export const getVisibleInvoices = (invoices: Invoice[], userRole?: UserRole) => (
  (invoices || [])
    .filter((item) => !(['invisible'] as InvoiceStatusType[]).includes(item.status))
    .filter((item) => isRoleEnough(userRole, 'admin')
      || !(['draft', 'expired'] as InvoiceStatusType[]).includes(item.status))
);

export function getPrioritizedInvoice(visibleInvoices: Invoice[]): Invoice | undefined {
  if (visibleInvoices?.length === 0) return undefined;

  const statusPriority: Record<InvoiceStatusType, number> = {
    overdue: 1,
    pending: 2,
    paid: 3,
    draft: 4,
    expired: 5,
    invisible: 6, // This shouldn't appear since invisible gets filtered it out
  };

  return visibleInvoices.reduce((prev, current) => (
    statusPriority[current.status] < statusPriority[prev.status] ? current : prev), visibleInvoices[0]);
}

export const isInvoiceDeleteVisible = (invoice?: Invoice) => {
  const isInvoiceHasShipping = !!invoice?.services
    ?.some(({ kind }) => prioritizedInvoiceServices.includes(kind));
  const isDeleteVisible = (!isInvoiceHasShipping && invoice?.status
    && ['draft', 'overdue', 'expired'].includes(invoice?.status));

  return !!isDeleteVisible;
};

export interface ListModalState<D = Invoice> {
  open: boolean,
  itemId: string,
  item: D | undefined,
}

function InvoicesList() {
  const { user } = useAuth();
  const { order, clientOrderData } = useOrderContext();
  const [modalState, setModalState] = useState<ListModalState>({
    open: false, itemId: '', item: undefined,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = ({ item, ...props }: ListModalState) => {
    setModalState({ ...props, item: item ? { ...item, order: order?.data } : undefined });
  };

  const visibleInvoices = useMemo(() => (
    getVisibleInvoices(clientOrderData?.invoices || [], user?.role)
  ), [clientOrderData, user?.role]);

  useEffect(() => {
    const itemParamId = searchParams.get('invoice');
    const foundItem = visibleInvoices.find((i) => itemParamId === i.id);

    if (itemParamId && foundItem) {
      handleOpen({ open: true, itemId: itemParamId, item: foundItem });
    }
  }, [visibleInvoices, searchParams]);

  return (
    <div>
      {visibleInvoices?.length ? (
        visibleInvoices
          .map((invoice) => {
            const iconConfig = getInvoiceStatusIcon(invoice.status);

            return (
              <div
                key={invoice.id}
                className={styles.item}
                tabIndex={-1}
                role="button"
                onClick={() => handleOpen({ open: true, itemId: invoice.id, item: invoice })}
                style={{ cursor: 'pointer' }}
              >
                <div className={styles.icon}>
                  <IconWithBadge
                    BadgeIcon={iconConfig.icon}
                    badgeColor={iconConfig.color}
                    Icon={EuroCircleOutlined}
                  />
                </div>

                <Col>
                  <div className={styles.invoiceNumber}>
                    {`Invoice ${invoice.number || '-'}`}
                  </div>
                  <div className={styles.date}>
                    {`Created: ${dayjs(invoice.createdAt).format('DD/MM/YYYY')}`}
                  </div>
                </Col>

                <div style={{ textAlign: 'right' }}>
                  <Badge
                    color={getInvoiceStatusColor(invoice.status)}
                    text={capitalize(invoice?.status)}
                  />
                </div>
              </div>
            );
          })
      ) : (
        <div className="text-secondary">
          No documents yet
        </div>
      )}

      <InvoicesModal
        showFooter={false}
        id={modalState.itemId}
        isModalOpen={modalState.open}
        dataSource={modalState.item}
        handleClose={() => {
          handleOpen({ open: false, itemId: '', item: undefined });
          setSearchParams({});
        }}
        afterAction={() => order.fetch()}
      />
    </div>
  );
}

export default InvoicesList;
