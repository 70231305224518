import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { Button, Tag } from 'antd';
import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import dayjs from 'dayjs';
import {
  downloadFromAnchor, getSorterParams, getUserRequestPath, queryFilterParams,
} from '../../../utils';
import Table, { listAction } from '../../Common/Table';

import { useSimpleModal } from '../../Common/Modal/Simple';
import { useAuth } from '../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import {
  OffersTable, OfferStatus, useOfferDownload, useOffersDelete, useOffersTableGet,
} from '../../../hooks/api/offers';
import OffersModal from './View';

export enum OffersStatusColor {
  'pending' = 'gold',
  'declined' = 'red',
  'accepted' = 'green',
}

export function getOffersStatusColor(status: string): string {
  // @ts-ignore take a color from the list
  return OffersStatusColor[status?.toLowerCase()] || 'rgba(0,0,0,.15)';
}

function DeleteButton({ record, setTableKey }: { record: OffersTable } & AnyObject) {
  const { user } = useAuth();
  const offerDelete = useOffersDelete(getUserRequestPath(user?.role));
  const { open, contextHolder } = useSimpleModal();

  useMessageError([offerDelete]);
  useMessageSuccess([offerDelete], 'Offer deleted successfully');

  useEffect(() => {
    if (!offerDelete.error && !offerDelete.loading && offerDelete.data) {
      setTableKey(Date.now());
    }
  }, [offerDelete.error, offerDelete.loading, offerDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={offerDelete.loading}
        title="Delete offer"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete offer?',
            content: (
              <span>
                Are you sure you want to delete offer
                {' '}
                <b>{record.number}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => offerDelete.fetch(record.id),
          });
        }}
      />
      {contextHolder}
    </>
  );
}
function DownloadButton({ record }: { record: OffersTable } & AnyObject) {
  const { user } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const offerDownload = useOfferDownload(getUserRequestPath(user?.role), record.id);

  useMessageError([offerDownload]);
  useMessageSuccess([offerDownload], 'Download successfully');

  const ActionButton = listAction.download;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={offerDownload.loading}
        title="Download offer"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          offerDownload.fetch()
            .then((response) => {
              if (!response) return;

              downloadFromAnchor(response, `offer_${record.number}`, 'application/pdf');
            });
        }}
      />
      {contextHolder}
    </>
  );
}

export default function OffersContent({ companyId }: { companyId?: string }): React.ReactNode | null {
  const { user } = useAuth();
  const offersTableGet = useOffersTableGet();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());
  const [isModalOpen, setModalOpen] = useState(false);
  const [offerId, setOfferId] = useState('');
  const handleClick = (id: string) => {
    setOfferId(id);
    setModalOpen(true);
  };

  const columns: ProColumns<OffersTable>[] = [
    {
      title: 'Offer No.',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      hideInSearch: true,
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {text}
          {record.offerStatus === 'declined' ? (
            <WarningOutlined className="warning-icon" />
          ) : null}
        </div>
      ),
    },
    {
      title: 'Order No.',
      dataIndex: 'order',
      key: 'order',
      hideInSearch: true,
      render: (text, record) => (<NavLink to={`/orders/${record.order?.id}`}>{record.order?.orderNumber}</NavLink>),
    },
    {
      title: 'Declination reason',
      dataIndex: 'reason',
      key: 'reason',
      hideInSearch: true,
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      hideInSearch: true,
      sorter: true,
      render: (text, record) => (
        dayjs(record.createdAt).format('DD/MM/YYYY')
      ),
    },
    {
      title: 'Status',
      dataIndex: 'offerStatus',
      key: 'offerStatus',
      valueType: 'select',
      valueEnum: OfferStatus,
      render: (_, record) => (
        <Tag color={getOffersStatusColor(record.offerStatus)}>{record.offerStatus}</Tag>
      ),
      fieldProps: {
        showSearch: true,
      },
    },
    {
      title: 'Date added',
      key: 'createdAt',
      dataIndex: 'createdAt',
      valueType: 'dateRange',
      hideInTable: true,
      search: {
        transform: (value) => (value ? ({
          dateAddedFrom: value[0],
          dateAddedTo: value[1],
        }) : undefined),
      },
    },
  ];

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<OffersTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    if (companyId) {
      Object.assign(newParams, {
        companyId,
      });
    }

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    const response = await offersTableGet.fetch({
      ...newParams,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([offersTableGet]);

  return (
    <>
      <Table<OffersTable>
        key={tableKey}
        className="transparent"
        rowClassName="cursor-pointer"
        headerTitle="List of offers"
        request={tableRequest}
        columns={columns}
        onRow={(record) => ({
          onClick: (event) => {
            event.preventDefault();
            handleClick(record.id);
          },
        })}
        actions={[
          [DownloadButton, {
            setTableKey,
          }],
          [DeleteButton, {
            setTableKey,
          }],
        ]}
        search={{
          searchText: 'Filter',
          resetText: 'Clear',
          style: {
            display: (companyId || user?.role === 'user') ? 'none' : 'block',
          },
        }}
        options={{
          search: {
            name: 'search',
          },
        }}
        columnsState={{ persistenceKey: 'pro-table-offers', persistenceType: 'localStorage' }}
      />
      <OffersModal
        showFooter={false}
        id={offerId}
        isModalOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        setTableKey={setTableKey}
      />
    </>
  );
}
