import React, { CSSProperties } from 'react';
import { Form, Switch, SwitchProps } from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { AnyObject } from '@triare/auth-redux';

import styles from '../../index.module.scss';

interface OrderSwitchProps extends FormItemProps {
  prefix?: string;
  rules?: Rule[];
  label?: string | React.ReactNode;
  name: NamePath<string>;
  style?: CSSProperties;
  children?: React.ReactNode;
  switchProps?: SwitchProps;
  rightText?: string | false;
  rest?: AnyObject;
}

function OrderSwitch({
  switchProps = {},
  rest = {},
  children,
  rightText,
  ...props
}: OrderSwitchProps) {
  return (
    <Form.Item
      extra={(
        <>
          {children}
          {rightText === false ? null : (
            <div className={styles.text}>
              {rightText}
            </div>
          )}
        </>
      )}
      initialValue={false}
      {...props}
      {...rest}
    >
      <Switch
        {...switchProps}
      />
    </Form.Item>
  );
}

export default OrderSwitch;
