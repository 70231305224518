import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import Menu from '../../../components/Layout/Menu';
import ServicesCreateContent, { HeaderActions } from '../../../components/Pages/Services/Create';
import ServicesProvider from '../../../components/Pages/Services/Form/context';

const { displayName } = config;

export default function ServicesCreate(): React.ReactNode | null {
  document.title = `${displayName}: Add service`;

  return (
    <ServicesProvider>
      <Layout
        title="Add service"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ServicesCreateContent />
      </Layout>
    </ServicesProvider>
  );
}
