import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useDefaultServicesGet } from '../../../../hooks/api/services';

import styles from './index.module.scss';

export default function DefaultServicesContent(): React.ReactNode | null {
  const navigate = useNavigate();
  const defaultServiceData = useDefaultServicesGet();
  const {
    deliveryMultiplayer,
    platformUsageFee,
    insuranceFee,
  } = defaultServiceData.data || {};

  return (
    <div className={styles.defaultServices}>
      <div className={styles.defaultServicesHeader}>
        <h3>Default services</h3>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate('default-services');
          }}
        >
          Edit
        </Button>
      </div>
      <div className={styles.info}>
        <div className={styles.items}>
          <div className={styles.item}>
            <h4>Name:</h4>
            <span>Shipping door-to-door</span>
          </div>
          <div className={styles.item}>
            <h4>Coefficient:</h4>
            {deliveryMultiplayer ? (
              <span>{deliveryMultiplayer}</span>
            ) : null}
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <h4>Name :</h4>
            <span>Platform usage fee</span>
          </div>
          <div className={styles.item}>
            <h4>Value:</h4>
            {platformUsageFee ? (
              <span>{`${platformUsageFee} CHF`}</span>
            ) : null}
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <h4>Name:</h4>
            <span>Insurance fee</span>
          </div>
          <div className={styles.item}>
            <h4>Value:</h4>
            {insuranceFee ? (
              <span>{`${insuranceFee} CHF`}</span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
