import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Action } from '@triare/auth-redux';
import {
  signUpByService as signUpByServiceAction,
  signUpErrorClear as signUpErrorClearAction,
  SignUpByServiceAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { useTranslation } from 'react-i18next';
import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login';
import { Button } from 'antd';
import { SignInByServiceAction } from '@triare/auth-redux/dist/saga/auth/signIn';

interface ButtonFacebookProps {
  signUpByService: (payload: SignUpByServiceAction) => Action;
  signUpErrorClear: () => Action;
}

function ButtonFacebook({
  signUpByService,
  signUpErrorClear,
}: ButtonFacebookProps): React.ReactNode {
  // const [providerName, setProviderName] = useState('');

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  const onLogoutSuccess = useCallback(() => {
    // setProviderName('');
    // alert('logout success')
  }, []);

  const { t } = useTranslation();

  return (
    <LoginSocialFacebook
      isOnlyGetToken
      appId={process.env.REACT_APP_FB_APP_ID || ''}
      onLoginStart={onLoginStart}
      onResolve={({ provider, data }: IResolveParams) => {
        // setProviderName(provider);

        if (data && data.accessToken) {
          const payload: SignInByServiceAction = {
            // _query: {
            //   lang: 'en',
            // },
            method: 'facebook',
            accessToken: data.accessToken,
          };

          signUpByService(payload);
        }
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <Button type="primary" style={{ width: '100%' }}>
        {t('signUp.facebook')}
      </Button>
    </LoginSocialFacebook>
  );
}

export default connect(null, {
  signUpByService: signUpByServiceAction,
  signUpErrorClear: signUpErrorClearAction,
})(ButtonFacebook);
