import {
  NavigateOptions,
  SetURLSearchParams,
  URLSearchParamsInit,
  useSearchParams as useRouterSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnyObject } from '@triare/auth-redux';
import { getValidSearchParamsWithout, ValidSearchParams } from '../utils';

export function getSearchParamsWithoutTableProps(searchParams: URLSearchParams): ValidSearchParams {
  return getValidSearchParamsWithout(['page', 'pageSize', 'orderBy', 'orderByColumn'], searchParams);
}

export function useWatchSearchParam(name: string): string[] {
  const [searchParams] = useRouterSearchParams();
  const [state, setState] = useState<string[]>(searchParams.getAll(name));

  useEffect(() => {
    const newState = searchParams.getAll(name);

    if (newState.toString() !== state.toString()) {
      setState(newState);
    }
  }, [searchParams]);

  return state;
}

export function useSearchParams(
  defaultInit?: URLSearchParamsInit,
): [URLSearchParams, SetURLSearchParams, ValidSearchParams] {
  const [searchParams, setSearchParams] = useRouterSearchParams(defaultInit);
  const [paramsWithoutTableProps, setParamsWithoutTableProps] = useState(
    getSearchParamsWithoutTableProps(searchParams),
  );

  useEffect(() => {
    const newParams = getSearchParamsWithoutTableProps(searchParams);

    if (JSON.stringify(paramsWithoutTableProps) !== JSON.stringify(newParams)) {
      setParamsWithoutTableProps(newParams);
    }
  }, [searchParams]);

  return [searchParams, (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => {
    if (nextInit) {
      if (typeof nextInit === 'object') {
        const keys = Object.keys(nextInit);

        if (keys.length > 0 && keys.some((key) => {
          const next = (nextInit as AnyObject)[key] as string[];
          const params = searchParams.getAll(key);

          if (Array.isArray(next)) {
            if (next.length !== (Array.isArray(params) ? params.length : params)) {
              return true;
            }

            return next.some((value) => !(Array.isArray(params) && params.includes(value)));
          } if (typeof next === 'object' && next !== null) {
            const nextKeys = Object.keys(next);

            return nextKeys.some((nextKey) => next[nextKey] !== params[nextKey as never]);
          }

          return next !== params;
        })) {
          setSearchParams(nextInit, navigateOpts);
        }
      } else {
        setSearchParams(nextInit, navigateOpts);
      }
    }
  }, paramsWithoutTableProps];
}

export default {
  useSearchParams,
};
