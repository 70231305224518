import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { Button } from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import { getSorterParams, getUserRequestPath, queryFilterParams } from '../../../utils';
import Table, { listAction } from '../../Common/Table';
import {
  AddressBookTable, useAddressBookTableGet, useParticipantDelete,
} from '../../../hooks/api/addressBook';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useAuth } from '../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import { useCompaniesGetAll } from '../../../hooks/api/company';

function DeleteButton({ record, setTableKey }: { record: AddressBookTable } & AnyObject) {
  const { user } = useAuth();
  const addressDelete = useParticipantDelete(getUserRequestPath(user?.role));
  const { open, contextHolder } = useSimpleModal();

  useMessageError([addressDelete]);
  useMessageSuccess([addressDelete], 'Address deleted successfully');

  useEffect(() => {
    if (!addressDelete.error && !addressDelete.loading && addressDelete.data) {
      setTableKey(Date.now());
    }
  }, [addressDelete.error, addressDelete.loading, addressDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={addressDelete.loading}
        title="Delete address"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete contact?',
            content: (
              <span>
                Are you sure you want to delete address
                {' '}
                <b>{record.displayName}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => addressDelete.fetch(record.id),
          });
        }}
      />
      {contextHolder}
    </>
  );
}

function ToolBar() {
  const navigate = useNavigate();

  return [
    <Button
      key="add"
      icon={<PlusCircleOutlined />}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        navigate('/address-book/create');
      }}
    >
      Add
    </Button>,
  ];
}

export default function AddressBookContent({ companyId }: { companyId?: string }): React.ReactNode | null {
  const navigate = useNavigate();
  const { user } = useAuth();
  const addressBookGet = useAddressBookTableGet(getUserRequestPath(user?.role));
  const companiesOptionsGet = useCompaniesGetAll();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());

  const columns: ProColumns<AddressBookTable>[] = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: true,
      hideInSearch: true,
      ellipsis: true,
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      hideInSearch: true,
    },
    {
      title: 'Contact',
      dataIndex: 'contactName',
      key: 'contactName',
      hideInSearch: true,
    },
    {
      title: 'Mobile number',
      dataIndex: 'phone',
      key: 'phone',
      hideInSearch: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      hideInSearch: true,
    },
    {
      title: 'Customer',
      dataIndex: 'companyId',
      key: 'companyId',
      valueType: 'select',
      hideInTable: user?.role === 'user',
      render: (text, record) => (
        <NavLink
          onClick={(e) => e.stopPropagation()}
          to={`/companies/${record.company?.id}`}
        >
          {record.company?.companyName}
        </NavLink>
      ),
      request: () => new Promise((resolve) => {
        companiesOptionsGet.fetch().then((data) => resolve(data || [])).catch(() => resolve([]));
      }),
      fieldProps: {
        showSearch: true,
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // renderFormItem: (_, { defaultRender, ...config }) => <SelectCompany name="companyId" {...config} />,
    },
  ];

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<AddressBookTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    if (companyId) {
      Object.assign(newParams, {
        companyId,
      });
    }

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    const response = await addressBookGet.fetch({
      ...newParams,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: (data || []).map(((item) => ({
          ...item,
          key: item.id,
        }))),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([addressBookGet]);

  return (
    <Table<AddressBookTable>
      key={tableKey}
      className="transparent"
      headerTitle="List of addresses"
      rowClassName="cursor-pointer"
      toolBarRender={ToolBar}
      request={tableRequest}
      columns={columns}
      onRow={(record) => ({
        onClick: (event) => {
          event.preventDefault();
          navigate(`/address-book/${record.id}`);
        },
      })}
      actions={[
        ['edit', ({ record }) => ({
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            navigate(`/address-book/${record.id}/edit`);
          },
        })],
        [DeleteButton, {
          setTableKey,
        }],
      ]}
      search={{
        searchText: 'Filter',
        resetText: 'Clear',
        style: {
          display: (companyId || user?.role === 'user') ? 'none' : 'block',
        },
      }}
      options={{
        search: {
          name: 'search',
        },
      }}
      columnsState={{ persistenceKey: 'pro-table-address-book', persistenceType: 'localStorage' }}
    />
  );
}
