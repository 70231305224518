import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
  useFetchCreate,
  useFetchUpdate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId, FetchUpdate,
} from '../fetch';
import { Option } from '../../types';
import { RolePathType } from '../../utils';
import { Order } from './order';

export enum InvoiceStatusOptions {
  // invisible = 'Invisible',
  // draft = 'Draft',
  'pending' = 'Pending',
  'overdue' = 'Overdue',
  'paid' = 'Paid',
  expired = 'Expired',
}

export interface Invoice extends InvoiceAll {
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface InvoicesGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useInvoicesGet = <DD = InvoicesTableData>(
  rolePath: RolePathType,
  decorateData?: (data: PagingDataResponse<Invoice>) => DD,
): FetchGet<
  PagingDataResponse<Invoice>,
  InvoicesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    `${rolePath}/invoices`,
    { autoStart: false, startStateLoading: false, decorateData },
  );

interface InvoiceBase {
  description: string | null;
  currency: string;
  conversionRate: number;
  roundDifference: number;
}

export interface InvoiceCreateParams extends InvoiceBase {
  services: ServiceCreateParams[];
}

export interface InvoiceAll extends InvoiceBase {
  number: string;
  amount: number;
  dueDate: string;
  status: InvoiceStatusType;
  order?: Order;
  services: ServiceDetails[];
}

interface ServiceCreateParams {
  name: string;
  value: number;
  valueCHF: number;
  tax: number;
  quantity: number;
  units: string;
  kind?: ServiceKindType;
}

export interface ServiceDetails extends ServiceCreateParams {
  id: string;
  kind: ServiceKindType;
}

export type InvoiceStatusType = 'invisible' | 'draft' | 'pending' | 'paid' | 'overdue' | 'expired';
export type ServiceKindType = 'shippingCost' | 'platformUsageFee' | 'insuranceFee' | 'custom' | string;

export interface InvoicesTable extends Invoice {
  key: string;
}

export interface InvoicesTableData {
  data: InvoicesTable[];
  total: number;
}

export const useInvoicesTableGet = (rolePath: RolePathType) => useInvoicesGet<InvoicesTableData>(
  rolePath,
  ({ data, meta }: PagingDataResponse<Invoice>): InvoicesTableData => ({
    data: data.map((item: Invoice): InvoicesTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useInvoicesOptionsGet = (rolePath: RolePathType) => useInvoicesGet<Option[]>(
  rolePath,
  ({ data }: PagingDataResponse<Invoice>): Option[] => (
    data.map((item) => (
      { value: item?.status || '', label: item?.status || '' }
    ))
  ),
);

export const useInvoicesGetById = <DD = Invoice>(
  rolePath: RolePathType,
  id?: string,
  decorateData?: (data: Invoice) => DD,
): FetchGetId<
  Invoice,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `${rolePath}/invoices`,
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface InvoiceRequestParams {
  id: string;
}

export const useInvoiceResend = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<FetchSuccess, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/invoices${id ? `/${id}/send-by-mail` : ''}`)
);

export const useInvoiceMarkAsPaid = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<FetchSuccess, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/invoices/${id}/mark-as-paid`)
);

export const useInvoicesDelete = (rolePath: RolePathType, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${rolePath}/invoices`, id)
);

export interface InvoicesData {
  id: string;
  location: string;
  fileSize: number;
  originalName: string;
  mimeType: string;
  encoding: string;
}

export const useInvoiceDownload = (
  rolePath: RolePathType,
  id?: string,
  abortController?: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    `${rolePath}/invoices${id ? `/${id}/download-as-pdf` : ''}`,
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal,
      },
    },
    'arraybuffer',
  )
);

/** Paperwork: */
export const useOrderInvoiceCreate = (
  orderId?: string,
): FetchCreate<Invoice, DefaultFetchError, InvoiceCreateParams> => ( // InvoiceCreateParams
  useFetchCreate(`admin/invoices/for-order/${orderId}`)
);

export const useInvoiceUpdate = (
  rolePath: RolePathType,
  id?: string,
): FetchUpdate<Invoice, DefaultFetchError, InvoiceCreateParams> => (
  useFetchUpdate(`${rolePath}/invoices${id ? `/${id}` : ''}`)
);

export const useInvoiceSendAsOffer = (
  id?: string,
): FetchCreate<FetchSuccess, DefaultFetchError> => (
  useFetchCreate(`admin/invoices${id ? `/${id}/send-as-offer` : ''}`)
);

export const useOrderInvoiceSetVisible = (
  orderId?: string,
): FetchCreate<Order, DefaultFetchError> => (
  useFetchCreate(`orders${orderId ? `/${orderId}/set-invoice-visible` : ''}`) // set-invoice-visible
);

export const useInvoiceReturnToDraft = (
  id?: string,
): FetchCreate<FetchSuccess, DefaultFetchError> => (
  useFetchCreate(`admin/invoices${id ? `/${id}/return-to-draft` : ''}`)
);

export const prioritizedInvoiceServices: ServiceKindType[] = ['shippingCost', 'platformUsageFee', 'insuranceFee'];

/** Payments */
export const useGetInvoicePaymentLink = (rolePath: RolePathType, id?: string)
  : FetchGet<string, undefined, DefaultFetchError> => useFetchGet(
  `${rolePath}/invoices${id ? `/${id}/payment-link` : ''}`,
  { autoStart: false, startStateLoading: false },
);

interface InvoicePayRes {
  link: string;
}

/** Returns link for payment */
export const useOrderInvoicePay = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<InvoicePayRes, DefaultFetchError, undefined> => ( // InvoiceCreateParams
  useFetchCreate(`${rolePath}/invoices${id ? `/${id}/pay` : ''}`)
);
