import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import {
  NavLink, useNavigate, useParams,
} from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  useParticipantCreate,
  useParticipantDelete,
  useParticipantUpdate,
} from '../../../../../hooks/api/addressBook';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useBlockerContext } from '../../../../../context/blockerDisabler';
import ParticipantForm from '../../Form';
import { getUserRequestPath } from '../../../../../utils';
import { useAuth } from '../../../../../store/auth';
import { useParticipantContext } from '../../Form/context';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const {
    form, isValid, setInitialState,
  } = useParticipantContext();
  const participantCopy = useParticipantCreate(getUserRequestPath(user?.role));
  const participantUpdate = useParticipantUpdate(getUserRequestPath(user?.role), id);
  const participantDelete = useParticipantDelete(getUserRequestPath(user?.role), id || 'undefined');

  const { handleIsBlockerActive } = useBlockerContext();

  useMessageError([participantUpdate, participantDelete, participantCopy]);
  useMessageSuccess([participantUpdate], 'Changes saved successfully');
  useMessageSuccess([participantCopy], 'Copy saved successfully');
  useMessageSuccess([participantDelete], 'Participant deleted successfully');

  useEffect(() => {
    if (!participantCopy.error && !participantCopy.loading && participantCopy.data) {
      navigate(`/address-book/${participantCopy.data.id}/edit`);
      window.location.reload();
    }
  }, [participantCopy.data]);

  useEffect(() => {
    if (!participantUpdate.error && !participantUpdate.loading && participantUpdate.data) {
      navigate(`/address-book/${id}`);
    }
  }, [participantUpdate.error, participantUpdate.loading, participantUpdate.data]);

  useEffect(() => {
    if (!participantDelete.error && !participantDelete.loading && participantDelete.data) {
      /** After delete disable blocker, and after navigation - reset to default state */
      handleIsBlockerActive(false)
        .then(() => {
          navigate('/address-book');
        }).then(() => handleIsBlockerActive(null));
    }
  }, [participantDelete.error, participantDelete.loading, participantDelete.data]);

  return (
    <Actions>
      <Button
        danger
        loading={participantDelete.loading}
        onClick={(e) => {
          e.preventDefault();

          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete contact?',
            content: (
              <span>
                Are you sure you want to delete address
                {' '}
                <b>{form?.getFieldValue('displayName')}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => participantDelete.fetch(),
          });
        }}
      >
        Delete
      </Button>
      <Button
        loading={participantCopy.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            participantCopy.fetch(data);
          }
        }}
      >
        Make a copy
      </Button>
      <Button
        type="primary"
        disabled={!isValid}
        loading={participantUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();
            const newData = {
              ...data,
              ...(data?.company?.id ? { company: { id: data.company.id } } : {}),
            };

            if (!data?.company?.id) {
              delete newData.company;
            }

            setInitialState(newData);
            participantUpdate.fetch(newData);
          }
        }}
      >
        Save
      </Button>

      {contextHolder}
    </Actions>
  );
}

export default function ParticipantEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { initialState } = useParticipantContext();

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/address-book">Address book</NavLink>,
          },
          {
            title: <NavLink to={`/address-book/${id}`}>{initialState?.displayName || 'Current address'}</NavLink>,
          },
          {
            title: 'Edit address',
          },
        ]}
      />

      <ParticipantForm />
    </>
  );
}
