import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { App, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useResendSignUpEmail } from '../../../../../hooks/api/auth';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Wrapper from '../../Wrapper';

import styles from './index.module.scss';

function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
}

function Information(): React.ReactNode {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const resendSignUpEmail = useResendSignUpEmail();
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const handleResend = () => {
    if (email && isValidEmail(email)) {
      setLoading(true);
      resendSignUpEmail.fetch({ email: email || '' });
    } else {
      message.error('Invalid email');
    }
  };

  useMessageSuccess([resendSignUpEmail], 'Email sent successfully!');
  useMessageError([resendSignUpEmail]);

  return (
    <Wrapper>
      <div className={styles.content}>
        <h3>
          Verify your email address
        </h3>

        <p>
          To continue, please click the verification link in the email we sent to
          {' '}
          <b>{email || 'you'}</b>
        </p>

        <p>
          if you don&apos;t see this email in your inbox, you may need to check your spam folder.
        </p>

        <span>
          Still can&apos;t find the email?
        </span>

        <div className={styles.footer}>
          <Button
            type="default"
            style={{ width: '100%' }}
            size="large"
            onClick={handleResend}
            disabled={loading}
          >
            Resend Email
          </Button>

          <p style={{ textAlign: 'center' }}>
            Need help?
            {' '}
            <a href="mailto:ramon@sephyre.com" target="_blank" rel="noreferrer">Contact us</a>
          </p>

          <Button
            type="text"
            style={{ width: '100%' }}
            size="large"
            onClick={() => navigate('/sign-up')}
            icon={<LeftOutlined />}
          >
            Back to Sign up
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

export default Information;
