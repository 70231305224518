import React from 'react';
import OrderSelect, { OrderSelectProps } from '../Select';
import { useHSCodeGetAll } from '../../../../../../hooks/api/order';
import { FormNames, useOrderContextForm } from '../../context';

interface SelectHSCodeProps extends OrderSelectProps {
  prefix: FormNames
}

function SelectHSCode({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: SelectHSCodeProps) {
  const hsCodeGetAll = useHSCodeGetAll();
  const { forms: { [props.prefix]: form } } = useOrderContextForm();

  return (
    <OrderSelect
      fetch={hsCodeGetAll}
      selectProps={{
        allowClear: false,
        onChange: (value: string[]) => {
          form.setFieldValue([props.prefix, ...props.name as string[]], value[value.length - 1]);
        },
        mode: 'tags',
        ...selectProps,
      }}
      rest={rest}
      {...props}
    />
  );
}

export default SelectHSCode;
