import React, { useEffect, useState } from 'react';
import { App, Button, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useOrderContext } from '../../../View/context';
import { OrderStatus } from '../../../Adapter/enums';
import { useWatchSearchParam } from '../../../../../../hooks/useSearchParams';
import { useOrderContextForm } from '../../context';
import { useIntegrationsShipmentCreate, useOrderSetToBePaid } from '../../../../../../hooks/api/order';
import RequestAssistanceButton from '../../../RequestAssistance';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import { DeliveryServiceType } from '../../../../../../hooks/api/tracking';
import { useAuth } from '../../../../../../store/auth';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import InvoicesModal from '../../../../Invoices/View';
import isValidForShipmentForm from './validation';

function ButtonContainer({
  children, modalState, handleOpen,
}: { children: React.ReactNode, modalState: ModalState, handleOpen: (params: ModalState) => void }) {
  const { order } = useOrderContext();

  return (
    <>
      {children}
      <InvoicesModal
        showFooter={false}
        id={modalState.invoiceId}
        isModalOpen={modalState.open}
        handleClose={() => handleOpen({ open: false, invoiceId: '' })}
        afterAction={() => order.fetch()}
      />
    </>
  );
}

interface ModalState {
  open: boolean,
  invoiceId: string,
}

function ClientPostOrderButton() {
  const { id } = useParams();
  const { user } = useAuth();
  const { message } = App.useApp();
  const { open: openSimpleModal, contextHolder } = useSimpleModal();
  const {
    loadingFile,
    orderUpdate,
    orderTypeForm,
    goodsForm,
    packagesForm,
    deliveryForm,
    overviewForm,
  } = useOrderContextForm();
  const { order, clientOrderData, handleClientOrderData } = useOrderContext();
  const integrationsShipmentCreate = useIntegrationsShipmentCreate(id);
  const orderSetToBePaid = useOrderSetToBePaid(`${id}/set-to-be-paid`);

  const [modalState, setModalState] = useState({ open: false, invoiceId: '' });

  const handleOpen = (props: ModalState) => {
    setModalState(props);
  };

  const checkIsFormValid = async () => (
    await isValidForShipmentForm({
      goodsValues: goodsForm.getFieldsValue()?.goods || [],
      packagesValues: packagesForm.getFieldsValue()?.packages || [],
      message,
    })
  );

  const tabWatch = useWatchSearchParam('tab');

  const isAssistanceNeeded = Form.useWatch('isAssistanceNeeded', orderTypeForm);
  const assistanceNote = Form.useWatch('assistanceNote', orderTypeForm) || '';
  const deliveryService: DeliveryServiceType | '' = Form.useWatch('deliveryService', deliveryForm);

  const beforeSend = async () => {
    try {
      await checkIsFormValid();
      await overviewForm.validateFields();

      return true;
    } catch {
      return false;
    }
  };

  const shipmentCreate = async () => {
    try {
      await checkIsFormValid();
      await overviewForm.validateFields();

      return integrationsShipmentCreate.fetch({
        // TODO ask backend, maybe i can here just send nothing, and it will work? all data already in order...
        //  and order saving before send this info...
        deliveryService,
        shipmentCreation: deliveryForm.getFieldValue('shipmentCreation'),
        accountNumber:
          deliveryForm.getFieldValue('accountNumber'), // || orderAccountNumbersWithLabel.data?.[0]?.account,
        serviceType: deliveryForm.getFieldValue('serviceType'),
        serviceName: deliveryForm.getFieldValue('serviceName') || clientOrderData?.delivery?.serviceName || '',
        trackingLink: deliveryForm.getFieldValue('trackingLink') || clientOrderData?.delivery?.trackingLink || '',
        trackingNumber: deliveryForm.getFieldValue('trackingNumber') || clientOrderData?.delivery?.trackingNumber || '',
      });
    } catch {
      return false;
    }
  };

  const placeOrderPrepayment = async () => {
    try {
      await checkIsFormValid();
      await overviewForm.validateFields();

      return openSimpleModal({
        icon: <ExclamationCircleFilled />,
        title: 'Place order',
        content: (
          <div>
            <span>Are you sure you would like to place the order?</span>
            <p>
              Please note, the invoice will need to be paid, and afterward,
              you will be able to proceed with the shipment.
            </p>
          </div>
        ),
        cancelText: 'Cancel',
        okText: 'Confirm',
        onOk: async () => orderSetToBePaid.fetch().then((res) => {
          if (res?.success) {
            order.fetch()
              .then((orderRes) => {
                const pendingInvoice = orderRes?.invoices
                  ?.find((invoice) => invoice.status === 'pending');

                if (pendingInvoice) {
                  handleOpen({ open: true, invoiceId: pendingInvoice.id });
                  /** After payment in invoice redirect to View page and show Alert with info about success */
                }
              });
          }
        }),
        centered: true,
      });
    } catch {
      return false;
    }
  };

  /** Requests which have updated entity in response instantly updates order-data state if there's no error */
  useEffect(() => {
    if (integrationsShipmentCreate.data && !integrationsShipmentCreate.error && !integrationsShipmentCreate.loading) {
      handleClientOrderData(integrationsShipmentCreate.data);
      integrationsShipmentCreate.clearResponse(); // Clear response state after setting it in form.
    }
  }, [integrationsShipmentCreate.response]);

  useMessageError([integrationsShipmentCreate], 60);
  useMessageSuccess([integrationsShipmentCreate], 'Shipment created successfully');

  const isUserPrepayment = user?.company?.paymentDays === 0;

  const buttonPlacePrepayment = (
    <Button
      type="primary"
      loading={orderSetToBePaid.loading || integrationsShipmentCreate.loading || loadingFile || orderUpdate.loading}
      onClick={placeOrderPrepayment}
    >
      Place order
    </Button>
  );
  const buttonCreateShipment = (
    <Button
      type="primary"
      loading={integrationsShipmentCreate.loading || loadingFile || orderUpdate.loading}
      onClick={shipmentCreate}
    >
      {/* Place order */}
      Create shipment
    </Button>
  );
  const buttonRequestAssist = (
    <RequestAssistanceButton
      loading={orderUpdate.loading}
      buttonProps={{ type: 'primary' }}
      initialNote={assistanceNote || clientOrderData?.orderType?.assistanceNote}
      beforeModalOpen={beforeSend}
    />

  );

  /** If user on tab Overview render specific send buttons */
  let buttonContent = null;

  if (tabWatch?.[0] === 'Overview' && user?.role === 'user') {
    if (clientOrderData?.status === OrderStatus.DRAFT) {
      if (!isAssistanceNeeded) {
        buttonContent = isUserPrepayment ? buttonPlacePrepayment : buttonCreateShipment;
      } else {
        buttonContent = buttonRequestAssist;
      }
    } else if (isUserPrepayment
      && clientOrderData?.status && [
      OrderStatus.IN_PROGRESS,
      OrderStatus.READY_FOR_DELIVERY, // 'Prepayment-user' order status after payment
    ].includes(clientOrderData?.status)) {
      /** If user prepayment and he already processed with payment show create shipment button */
      buttonContent = buttonCreateShipment;
    }
  }

  return (
    <ButtonContainer modalState={modalState} handleOpen={handleOpen}>
      {contextHolder}
      {buttonContent}
    </ButtonContainer>
  );
}

export default ClientPostOrderButton;
