import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Form, Modal,
} from 'antd';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

import { useAuth } from '../../../../../store/auth';
import { OfferActionEnum, useOfferClientAction } from '../../../../../hooks/api/offers';
import { getUserRequestPath } from '../../../../../utils';
import { useOrderContext } from '../../../Orders/View/context';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import { HeaderTitle } from '..';

interface DeclinationReasonProps {
  handleOpen?: (modalOpen: boolean) => void;
  isModalOpen: boolean;
  showFooter?: boolean;
  offerId?: string;
  closeOfferModal: () =>void;
}

const LIST_MAX_HEIGHT = 'min(calc(80vh - 106px), 600px)';

const checkboxOptions = [
  'Incorrect or incomplete information',
  'Not satisfied with the price',
  'Not satisfied with the terms of the order',
  'Other',
];

function DeclinationReasonModal({
  handleOpen, offerId, showFooter = true, isModalOpen, closeOfferModal,
}: DeclinationReasonProps) {
  const { id } = useParams();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const { order } = useOrderContext();
  const offerClientAction = useOfferClientAction(getUserRequestPath(user?.role), id, offerId, OfferActionEnum.DECLINE);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (!offerClientAction.error && !offerClientAction.loading && offerClientAction.data) {
      order.fetch();
    }
  }, [offerClientAction.data]);
  useMessageError([offerClientAction]);
  useMessageSuccess([offerClientAction], 'Offer declined successfully');

  const handleSave = () => {
    const values = form.getFieldsValue();
    const filteredReasons = values.reason?.filter((reason: string) => reason !== 'Other') || [];

    const reasonText = [
      ...filteredReasons,
      values.otherReason?.trim() && `${values.otherReason.trim()}`,
    ]
      .filter(Boolean)
      .map((reason, index, array) => (index < array.length - 1 ? `${reason}.` : reason))
      .join(' ');

    const finalReason = { reason: reasonText.trim() };

    offerClientAction.fetch(finalReason);
    handleOpen?.(false);
    closeOfferModal();
  };

  const onCheckboxChange = (checkedValues: string[]) => {
    const isOtherSelected = checkedValues.includes('Other');

    setShowOtherInput(isOtherSelected);
    const isCheckboxSelected = checkedValues.length > 0;
    const otherReasonText = form.getFieldValue('otherReason') || '';

    setIsSaveEnabled(isCheckboxSelected && (!isOtherSelected || otherReasonText.length >= 3));
    form.setFieldsValue({ reason: checkedValues });
  };

  const onOtherReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const otherReasonText = e.target.value;
    const isCheckboxSelected = form.getFieldValue('reason')?.length > 0;

    setIsSaveEnabled(isCheckboxSelected && (!showOtherInput || otherReasonText.length >= 3));
  };

  return (
    <Modal
      title={<HeaderTitle status="pending" />}
      open={isModalOpen}
      centered
      closable
      maskClosable={false}
      width={952}
      footer={
        showFooter
          ? [
            <Button
              key="cancel"
              onClick={() => {
                handleOpen?.(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              disabled={!isSaveEnabled}
              key="agree"
              type="primary"
              onClick={handleSave}
            >
              Save
            </Button>,
          ]
          : []
      }
      onCancel={() => {
        handleOpen?.(false);
      }}
    >
      <div
        style={{
          height: LIST_MAX_HEIGHT,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Form form={form}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
            <strong>Please provide the declination reason</strong>
            <span style={{ color: '#00000073' }}>
              This helps us understand your needs and improve our offer.
              <br />
              {' '}
              For a quick response or any questions,
              <a href="mailto:welcome@sephyre.com"> contact us</a>
            </span>
          </div>
          <Form.Item name="reason">
            <Checkbox.Group
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              options={checkboxOptions.map((option) => ({ label: option, value: option }))}
              onChange={onCheckboxChange}
            />
          </Form.Item>
          {showOtherInput && (
            <Form.Item name="otherReason">
              <TextArea onChange={onOtherReasonChange} placeholder="Please specify" />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default DeclinationReasonModal;

export function DeclinationReasonModalButton({
  text = '',
  modalProps,
  handleClick,
  handleCloseModal,
  isModalOpen,
  offerId,
  closeOfferModal,
}: {
  offerId?: string;
  text?: string;
  variant?: 'button' | 'link';
  isModalOpen: boolean,
  modalProps?: DeclinationReasonProps,
  handleClick: () => void,
  closeOfferModal:() => void,
  handleCloseModal: (isOpen: boolean) => void,
}) {
  return (
    <>
      <Button onClick={handleClick} type="primary" danger>
        {text}
      </Button>
      <DeclinationReasonModal
        closeOfferModal={closeOfferModal}
        handleOpen={handleCloseModal}
        isModalOpen={isModalOpen}
        {...modalProps}
        offerId={offerId}
      />
    </>
  );
}
