import React, { useEffect, useState } from 'react';
import { Button, Tag } from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import clsx from 'clsx';
import Table, { listAction } from '../../Common/Table';
import {
  useAdministratorDelete, useAdministratorResetPassword,
  useAdministratorSendInvitation,
  useAdministratorsTableGet,
  UserTable,
} from '../../../hooks/api/users';
import { getSorterParams, queryFilterParams } from '../../../utils';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../hooks/common';
import { useAuth } from '../../../store/auth';

import styles from './index.module.scss';

export enum AdministratorStatusColor {
  'new' = 'purple',
  'shipped out' = 'cyan',

  'declined' = 'default',

  'in progress' = 'orange',

  'processing' = 'blue',

  'pending' = 'blue',

  'rejected' = 'red',

  'on the way' = 'cyan',

  'delivered' = 'green',

  'active' = 'green',
}

function DeleteButton({ record, setTableKey }: { record: UserTable } & AnyObject) {
  const administratorDelete = useAdministratorDelete();
  const { open, contextHolder } = useSimpleModal();
  const { user } = useAuth();

  useMessageError([administratorDelete]);
  useMessageSuccess([administratorDelete], 'Administrator deleted successfully');

  useEffect(() => {
    if (!administratorDelete.error && !administratorDelete.loading && administratorDelete.data) {
      setTableKey(Date.now());
    }
  }, [administratorDelete.error, administratorDelete.loading, administratorDelete.data]);

  const ActionButton = listAction.delete;

  if (user?.id === record.id) {
    return null;
  }

  return (
    <>
      <ActionButton
        loading={administratorDelete.loading}
        title="Delete administrator"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete administrator?',
            content: (
              <span>
                Are you sure you want to delete administrator
                {' '}
                <b>{record.fullName}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => administratorDelete.fetch(record.id),
          });
        }}
      />
      {contextHolder}
    </>
  );
}

function ReloadButton({ record }: { record: UserTable }) {
  const administratorSendInvitation = useAdministratorSendInvitation();
  const administratorResetPassword = useAdministratorResetPassword();

  useMessageError([administratorSendInvitation, administratorResetPassword]);
  useMessageSuccess([administratorSendInvitation], 'Invitation sent successfully');
  useMessageSuccess([administratorResetPassword], 'Reset-password link sent successfully');

  const ActionButton = listAction.reload;

  return (
    <ActionButton
      loading={administratorSendInvitation.loading}
      title="Reload administrator"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();

        return record.status === 'active' ? (
          administratorResetPassword.fetch(undefined, `${record.id}/reset-password`)
        ) : (
          administratorSendInvitation.fetch(undefined, `${record.id}/invitation`)
        );
      }}
    />
  );
}

export function getAdministratorStatusColor(status: string): string {
  // @ts-ignore take a color from the list
  return AdministratorStatusColor[status?.toLowerCase()] || 'rgba(0,0,0,.15)';
}

enum UserRoleDisplay {
  user = 'Company-Admin',
  admin = 'Admin',
  root = 'Root',
}

const columns: ProColumns<UserTable>[] = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    valueEnum: UserRoleDisplay,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_, record) => (
      <Tag color={getAdministratorStatusColor(record.status)}>{record.status}</Tag>
    ),
  },
];

function ToolBar() {
  const navigate = useNavigate();

  return [
    <Button
      key="add"
      icon={<PlusCircleOutlined />}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        navigate('create');
      }}
    >
      Add
    </Button>,
  ];
}

export default function Administrators(): React.ReactNode | null {
  const navigate = useNavigate();
  const administratorsGet = useAdministratorsTableGet();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<UserTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return administratorsGet.fetch({
      ...newParams,
    }).then((response) => {
      if (response) {
        const { data, total } = response;

        return ({
          data: (data || []).map(((item) => ({
            ...item,
            key: item.id,
          }))),
          success: true,
          total,
        });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  useMessageError([administratorsGet]);

  return (
    <Table<UserTable>
      key={tableKey}
      className={clsx('transparent', styles.table)}
      headerTitle="List of administrators"
      toolBarRender={ToolBar}
      request={tableRequest}
      columns={columns}
      actions={[
        ['edit', ({ record }) => ({
          title: 'Edit administrator',
          onClick: (e: MouseEvent) => {
            e.preventDefault();
            navigate(`${record.id}/edit`);
          },
        })],
        [DeleteButton, {
          setTableKey,
        }],
        [ReloadButton],
      ]}
      search={{
        searchText: 'Filter',
        resetText: 'Clear',
        style: {
          display: 'none',
        },

      }}
      options={{
        search: {
          name: 'search',
        },
      }}
      columnsState={{ persistenceKey: 'pro-table-users', persistenceType: 'localStorage' }}
    />
  );
}
