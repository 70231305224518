import React from 'react';

import styles from './index.module.scss';

interface ActionsProps {
  children: React.ReactNode;
}

export default function Actions({ children }: ActionsProps): React.ReactNode | null {
  return (
    <div className={styles.actions}>
      {children}
    </div>
  );
}
