import React, { useEffect } from 'react';
import {
  Button,
  Form,
  Input,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { useAdministratorChangePassword } from '../../../../hooks/api/users';
import {
  confirmPassword,
  createRulesForAntd,
  password,
} from '../../../../utils/validations';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';
import { useAdminAccountContext } from './adminContext';

import styles from '../../../Common/Form/index.module.scss';

export function HeaderActions() {
  const { form, isValid } = useAdminAccountContext();
  const administratorChangePassword = useAdministratorChangePassword();

  useMessageError([administratorChangePassword]);
  useMessageSuccess([administratorChangePassword], 'Password updated successfully');

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={administratorChangePassword.loading}
        onClick={(e) => {
          e.preventDefault();

          if (form) {
            const { currentPassword, newPassword } = form.getFieldsValue();

            administratorChangePassword.fetch({
              currentPassword,
              newPassword,
            });
          }
        }}
      >
        Save
      </Button>
    </Actions>
  );
}

export const validationCreateAdministrator = yup.object().shape({
  currentPassword: password,
  newPassword: password,
  confirmPassword,
});

const validationRules = createRulesForAntd(validationCreateAdministrator);

interface FieldType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type AdminAccountProps = AnyObject

export default function AdminAccount(props: AdminAccountProps): React.ReactNode | null {
  const [form] = Form.useForm<FieldType>();
  const { setForm, setValid } = useAdminAccountContext();

  useEffect(() => {
    setForm(form);
  }, [form]);

  return (
    <Form
      // className={styles.form}
      className="form-customized-cols"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={() => {
        setValid(!(Object.values(form.getFieldsValue()).some((value) => value === '' || value === undefined)
          || form.getFieldsError().some(({ errors }) => errors.length)));
      }}
      {...props}
    >
      <h3>Set and confirm new password</h3>
      <div
        // className={styles.description}
        className="description"
      >
        The password must be 12 characters long and consist of letters, numbers, and symbols
      </div>

      <Form.Item<FieldType>
        label="Old password"
        name="currentPassword"
        rules={[validationRules]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<FieldType>
        label="New password"
        name="newPassword"
        rules={[validationRules]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<FieldType>
        label="Confirm new password"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[validationRules, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Passwords that you entered do not match!');
          },
        })]}
      >
        <Input.Password />
      </Form.Item>
    </Form>
  );
}
