import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import Menu from '../../components/Layout/Menu';

import OffersContent from '../../components/Pages/Offers';

const { displayName } = config;

export default function Offers(): React.ReactNode | null {
  document.title = `${displayName}: Invoices`;

  return (
    <Layout
      title="Offers"
      leftMenu={<Menu />}
    >
      <OffersContent />
    </Layout>
  );
}
