/* eslint-disable no-param-reassign */

import { AnyObject, Action } from '@triare/auth-redux';
import { capitalize } from '../utils';

export interface BaseState extends AnyObject {
  response: null | AnyObject;
  loading: boolean;
  error: null | AnyObject;
}

export function createBaseState(props: AnyObject = {}): BaseState {
  return {
    response: null,
    loading: false,
    error: null,
    ...props,
  };
}

export interface CreateCRUDStates {
  [key: string]: BaseState;
}

export function createCRUDStates<S = CreateCRUDStates>(list: string[], props?: CreateCRUDStates): S {
  const result: CreateCRUDStates = {};

  for (const key of list) {
    result[key] = createBaseState();
  }

  if (props) {
    for (const key of Object.keys(props)) {
      if (result[key]) {
        if (props[key]) {
          for (const key2 of Object.keys(props[key])) {
            result[key][key2] = props[key][key2];
          }
        }
      } else {
        result[key] = props[key];
      }
    }
  }

  return result as S;
}

export function createModel(modalName: string, name: string) {
  return {
    [`${modalName + capitalize(name)}Trigger`]: () => {
      // trigger action users
    },
    [`${modalName + capitalize(name)}Start`]: (state: AnyObject) => {
      state[name].error = null;
      state[name].loading = true;
    },
    [`${modalName + capitalize(name)}Success`]: (state: AnyObject, { payload }: Action) => {
      state[name].loading = false;
      state[name].response = payload || null;
    },
    [`${modalName + capitalize(name)}Error`]: (state: AnyObject, { payload }: Action) => {
      state[name].loading = false;
      state[name].error = payload;
    },
    [`${modalName + capitalize(name)}ErrorReset`]: (state: AnyObject) => {
      state[name].loading = false;
      state[name].error = null;
    },
    [`${modalName + capitalize(name)}Clear`]: (state: AnyObject) => {
      state[name].loading = false;
      state[name].response = null;
    },
  };
}

export function createCRUDModel<S = AnyObject>(
  models: string[], // ['list', 'byId', 'create', 'update', 'delete'],
  modalName: string,
  reducers: { [kes: string]: (state: S, action: Action) => void } = {},
): { [kes: string]: (state: S, action: Action) => void } {
  const result = {};

  for (const name of models) {
    Object.assign(result, createModel(modalName, name));
  }

  Object.assign(result, reducers);

  return result;
}
