import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';
import { AddressBook } from '../../../../hooks/api/addressBook';
import { SimpleProviderProps } from '../../../../types';

export interface ParticipantContextProps {
  initialState: AddressBook | null,
  setInitialState: (data: AddressBook) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: AddressBook) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const ParticipantContext = createContext<ParticipantContextProps>(defaultValue);

function ParticipantProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<AddressBook | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <ParticipantContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
}

export default ParticipantProvider;

export const useParticipantContext = ():
  ParticipantContextProps => React.useContext(ParticipantContext);
