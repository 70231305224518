import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { ActionType, ProColumns } from '@ant-design/pro-table/es/typing';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import {
  DeleteOutlined, ExclamationCircleFilled, PlusOutlined,
} from '@ant-design/icons';
import { Rule } from 'rc-field-form/lib/interface';
import OrderInput, { OrderInputProps } from '../Input';
import { getSorterParams, getUserRequestPath, queryFilterParams } from '../../../../../../utils';
import { FormName, useOrderContextForm } from '../../context';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import {
  AddressBookTable, useAddressBookTableGet, useParticipantDelete,
} from '../../../../../../hooks/api/addressBook';
import { useAuth } from '../../../../../../store/auth';

import styles from '../../index.module.scss';

interface InputSelectCompanyAddressProps extends OrderInputProps, FormName {
  validationRules: Rule;
}

export default function InputSelectCompanyAddress({
  // item,
  formName,
  validationRules,
  name,
  inputProps,
  prefix,
  ...props
}: InputSelectCompanyAddressProps) {
  const actionRef = useRef<ActionType | undefined>();
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();
  const addressBookGet = useAddressBookTableGet(getUserRequestPath(user?.role));
  const addressDelete = useParticipantDelete(getUserRequestPath(user?.role));
  const { open, contextHolder } = useSimpleModal();

  useEffect(() => {
    if (!addressDelete.error && !addressDelete.loading && addressDelete.response
      && actionRef && actionRef.current && actionRef.current.reloadAndRest
    ) {
      actionRef.current.reloadAndRest();
    }
  }, [addressDelete.data]);

  useMessageError([addressDelete]);
  useMessageSuccess([addressDelete], 'Address deleted successfully');

  const columns: ProColumns<AddressBookTable>[] = [
    {
      title: 'Display name',
      dataIndex: 'displayName',
      key: 'displayName',
      ellipsis: true,
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      hideInSearch: true,
    },
    {
      title: 'Contact',
      dataIndex: 'contactName',
      key: 'contactName',
      hideInSearch: true,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => (
        [record.address1, record.address2, record.city, record.state, record.postalCode, record.country]
          .filter((i) => !!i).join(', ')
      ),
      hideInSearch: true,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
      align: 'center',
      render: (id, { displayName }) => (
        <DeleteOutlined
          style={{ color: 'rgba(0,0,0,.45)', fontSize: '16px' }}
          onClick={(e) => {
            e.stopPropagation();
            open({
              icon: <ExclamationCircleFilled />,
              title: 'Remove address?',
              content: (
                <span>
                  Are you sure you want to remove address
                  {' '}
                  <b>{displayName}</b>
                  {' '}
                  from the list?
                </span>
              ),
              cancelText: 'Cancel',
              okText: 'Remove',
              okButtonProps: {
                danger: true,
              },
              onOk: () => addressDelete.fetch(id as string),
            });
          }}
        />
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelect = ({
    key: tableKey,
    company,
    tinNumber,
    companyName,
    ...addressProps
  }: AddressBookTable) => { // id,
    const data = {
      company: companyName,
    };

    Object.assign(data, {
      company: companyName,
      tin: tinNumber,
      ...addressProps,
    });

    Object.keys(data)
      .forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form.setFieldValue(prefix ? [prefix, key] : key, data[key]);
      });

    handleOk();
    setTimeout(() => {
      form.validateFields();
      triggerValidationAllForm();
    }, 100);
  };

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<AddressBookTable>>> => addressBookGet.fetch(queryFilterParams({
    page: current ? `${current}` : '1',
    pageSize: pageSize ? `${pageSize}` : '10',
    ...args,
    ...getSorterParams(sorter),
  })).then((response) => {
    if (response) {
      const { data, total } = response;

      return ({ data: data || [], success: true, total });
    }

    return ({ data: [], success: false, total: 0 });
  });

  return (
    <>
      <OrderInput
        className={styles.inputSelectProduct}
        label="Company"
        prefix={formName}
        name={name}
        rules={[validationRules, { required: true, message: <div /> }]}
        inputProps={{
          addonAfter: (
            <Tooltip
              placement="top"
              title="Choose a address from previously saved."
            >
              <PlusOutlined
                onClick={(e) => {
                  e.preventDefault();
                  showModal();
                }}
              />
            </Tooltip>
          ),
          maxLength: 250,
          ...inputProps,
        }}
        {...props}
      />
      <Modal
        centered
        title="Address book"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className={styles.modalTable}
        destroyOnClose
      >
        <ProTable<AddressBookTable>
          loading={addressBookGet.loading}
          actionRef={actionRef}
          onRow={(record) => ({
            onClick: (event) => {
              event.preventDefault();
              try {
                handleSelect(record);
              } catch (error) {
                console.log(error);
              }
            },
          })}
          columns={columns}
          request={tableRequest}
          search={false}
          rowKey="key"
          headerTitle="List of Addresses"
          pagination={{
            defaultPageSize: 10,
            size: 'default',
            showQuickJumper: true,
            showTotal: undefined,
          }}
          options={{
            density: false,
            search: {
              name: 'search',
            },
            reloadIcon: false,
            setting: false,
          }}
        />
      </Modal>
      {contextHolder}
    </>
  );
}
