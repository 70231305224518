import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Action, AnyObject } from '@triare/auth-redux';
import {
  signUpByService as signUpByServiceAction,
  signUpErrorClear as signUpErrorClearAction,
  SignUpByServiceAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { useTranslation } from 'react-i18next';
import { IResolveParams, LoginSocialGoogle } from 'reactjs-social-login';
import { SignInByServiceAction } from '@triare/auth-redux/dist/saga/auth/signIn';

interface ButtonGoogleProps {
  signUpByService: (payload: SignUpByServiceAction) => Action;
  signUpErrorClear: (payload: AnyObject) => Action;
}

function ButtonGoogle({
  signUpByService,
  signUpErrorClear,
}: ButtonGoogleProps): React.ReactNode {
  // const [providerName, setProviderName] = useState('');

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  const onLogoutSuccess = useCallback(() => {
    // setProviderName('');
    // alert('logout success')
  }, []);

  const { t } = useTranslation();

  return (
    <LoginSocialGoogle
      isOnlyGetToken
      client_id={process.env.REACT_APP_GG_APP_ID || ''}
      onLoginStart={onLoginStart}
      scope="openid email"
      onResolve={({ provider, data }: IResolveParams) => {
        // setProviderName(provider);

        if (data && data.access_token) {
          const payload: SignInByServiceAction = {
            // _query: {
            //   lang: 'en',
            // },
            method: 'google',
            accessToken: data.access_token,
          };

          signUpByService(payload);
        }
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <Button type="primary" style={{ width: '100%' }}>
        {t('signUp.google')}
      </Button>
    </LoginSocialGoogle>
  );
}

export default connect(null, {
  signUpByService: signUpByServiceAction,
  signUpErrorClear: signUpErrorClearAction,
})(ButtonGoogle);
