import { Button } from 'antd';
import {
  DeleteOutlined, EditOutlined, ExclamationCircleFilled, PaperClipOutlined,
} from '@ant-design/icons';
import React from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ButtonProps } from './index';
import { useOrderGoodDelete } from '../../../../../../hooks/api/order';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { SelectOption } from '../../../../../../types/select';

import commonStyles from '../../index.module.scss';
import styles from './index.module.scss';
import { EnumPackageKind } from '../../../Adapter';

// There's no 'Packages view' screen yet. It's just a copy of ProductsList/view component.
export function ViewButton({
  item, index, remove,
}: ButtonProps) {
  const { open, contextHolder } = useSimpleModal();
  const { id } = useParams();
  const navigate = useNavigate();
  const orderGoodDelete = useOrderGoodDelete();

  return (
    <>
      <Button
        icon={<EditOutlined />}
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/orders/${id}/edit?tab=Products&product=${index}`);
        }}
      />
      <Button
        loading={orderGoodDelete.loading}
        icon={<DeleteOutlined />}
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete item?',
            content: (
              <span>
                Are you sure you want to delete this item?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => {
              orderGoodDelete.fetch(item.id).finally(remove);
            },
          });
        }}
      />
      {contextHolder}
    </>
  );
}

export function ViewContent({
  item, index, remove,
}: ButtonProps) {
  return (
    <div className={clsx(commonStyles.wrapper, styles.viewContent)}>
      {/** View not needed at the moment on this page! */}
      <div>
        {item.items.length}
      </div>
      <div>
        {EnumPackageKind[item.kind]}
      </div>
      {/* <div>
        <div>
          <div>
            <div>
              <span>Name: </span>
              {'NAME_REPLACER' || '-'}
            </div>
            <div>
              <span>Dangerous Goods: </span>
              {item?.dangerousGood?.packingGroup}
              {' '}
              {item?.dangerousGood?.unNumber}
              {' - '}
              {item?.dangerousGood?.name}
              {' '}
              {item?.dangerousGood?.classificationCode}
            </div>
            <div>
              <span>Packaging group: </span>
              {item.packagingGroup || '-'}
            </div>
            <div>
              <span>HS code: </span>
              {item.hsCode?.label || '-'}
            </div>
            <div>
              <span>English Tech. Name: </span>
              {item.englishTechName || '-'}
            </div>
          </div>
          <div>
            <div>
              <span>Value: </span>
              {item.value || '-'}
              {' '}
              {item.currency}
            </div>
            <div>
              <span>Net: </span>
              {item.net || '-'}
              {' '}
              {item.massUnit}
            </div>
            <div>
              <span>Gross: </span>
              {item.gross || '-'}
              {' '}
              kg
            </div>
            <div>
              <span>Size: </span>
              L
              {item.length > 0 ? item.length : '0'}
              {' x W'}
              {item.width || '0'}
              {' x H'}
              {item.height || '0'}
              {' cm'}
            </div>
            <div>
              <span>Volume: </span>
              {item.volume || '-'}
              {' '}
              m
              <sup>3</sup>
            </div>
          </div>
        </div>

        {item?.innerPackaging ? (
          <div>
            <div>
              <div>
                <span>Inner packaging: </span>
                {item.innerPackagingQuantity}
                {' x '}
                 eslint-disable-next-line @typescript-eslint/ban-ts-comment
                 @ts-ignore
                {item.innerPackagingType ? item.innerPackagingType?.label : null}
                {' '}
                {item.innerPackagingNetUnitaryQuantity}
                {' kg'}
              </div>
            </div>
            <div>
              <div>
                <span>Net explosive mass per inn. pack.: </span>
                {item.innerPackagingNetExposiveQuantity || '-'}
                {' kg'}
              </div>
            </div>
          </div>
        ) : null}

        <div>
          <div>
            <div>
              <span>Environmentally Hazardous: </span>
              {item.environmentallyHazardous ? 'Yes' : 'No'}
            </div>
            <div>
              <span>Physical State: </span>
              {(item.physicalState as SelectOption)?.label}
            </div>
            <div>
              <span>Density: </span>
              {item.density}
              {' kg/L'}
            </div>
            <div>
              <span>Pure, Mix or Solution: </span>
              {item.pureMixSolution}
            </div>
          </div>
          <div>
            <div>
              <span>Flash Point (ºC c.c.): </span>
              {item.flashPoint}
            </div>
            <div>
              <span>Net explosive mass per package: </span>
              {item.netExplosivePerPackage}
              {' kg'}
            </div>
            <div>
              <span>Segregation Group: </span>
              {(item.segregationGroup as SelectOption[])?.map(({ label }) => label).join(', ')}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <span>Temperature Logger: </span>
              {item.temperatureLogger ? 'Yes' : 'No'}
            </div>
            <div>
              <span>Real Time Monitoring: </span>
              {item.realTimeMonitoring ? 'Yes' : 'No'}
            </div>
            <div>
              <span>Special temperature mode: </span>
              {item.temperatureMode}
            </div>
            {item?.temperatureMode === 'FROBO79 (Frozen Box UN 1845 -79 °C)' && item.netWeightOfDryIce ? (
              <div>
                <span>Net weight of Dry Ice: </span>
                {item.netWeightOfDryIce}
                {' kg'}
              </div>
            ) : null}
          </div>
          <div>
            <div>
              <span>Permit: </span>
              {item.permit}
            </div>
            <div>
              <span>Permit No.: </span>
              {item.permitNumber}
            </div>
            <div>
              <span>Permit date: </span>
              {dayjs(item.permitDate).utc().format('DD/MM/YYYY')}
            </div>
            {item.msdsDocument?.fileList?.map(({ url, name }) => (
              <div key={url}>
                <a href={url} target="_blank" rel="noreferrer">
                  <PaperClipOutlined />
                  {name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default {
  ViewButton,
  ViewContent,
};
