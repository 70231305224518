import React, { useEffect } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signUp as signUpAction, SignUpAction } from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import { useNavigate } from 'react-router-dom';
import Form from '../Form';
import { validationSignUpEmail, createRulesForAntd } from '../../../../../utils/validations';
import { ContentProps } from '../index';
import { useAuth } from '../../../../../store/auth';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

const validationRules = createRulesForAntd(validationSignUpEmail);

interface EmailProps extends ContentProps {
  signUp: (payload: SignUpAction) => Action;
}

function Email({ signUp }: EmailProps): React.ReactNode {
  const { signUp: { loading, response, error } } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (response && error === null && !loading && response.secretKey) {
      navigate('./information');
    }
  }, [response, loading, error]);

  return (
    <>
      <Form<SignUpAction>
        validationRules={validationRules}
        signUp={signUp}
        name="signUpEmail"
      >
        <AntdForm.Item
          name="email"
          label={`${t('email')} *`}
          rules={[validationRules]}
        >
          <Input
            size="large"
            placeholder={t('email') || 'Email'}
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>
      </Form>

      <Separator />

      <AntdForm.Item>
        <ButtonGoogle />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonApple />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonFacebook />
      </AntdForm.Item>

      <Footer />
    </>
  );
}

export default connect(null, {
  signUp: signUpAction,
})(Email);
