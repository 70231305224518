import React, { createContext, useEffect, useMemo } from 'react';
import { Company, useCompanyClientGet } from '../../../../hooks/api/company';
import { DefaultFetchError, FetchGetId } from '../../../../hooks/fetch';
import { useAuth } from '../../../../store/auth';
import { SimpleProviderProps } from '../../../../types';
import { useMessageError } from '../../../../hooks/common';

export interface CustomerAccountContextProps {
  /** Use only for customer/company form */
  companyClient: FetchGetId<Company, DefaultFetchError, unknown, Company>,
}

const defaultValue: CustomerAccountContextProps = {
  companyClient: {} as FetchGetId<Company, DefaultFetchError, unknown, Company>,
};

export const CustomerAccountContext = createContext<CustomerAccountContextProps>(defaultValue);

function CustomerAccountProvider({
  children,
}: SimpleProviderProps) {
  const { user } = useAuth();
  const companyClient = useCompanyClientGet();

  useEffect(() => {
    /** If user who visiting accounts page is company user - then fetch his company instantly */
    if (user?.role === 'user' && user?.company?.id) {
      companyClient.fetch();
    }
  }, [user?.role]);

  const memoizedValue: CustomerAccountContextProps = useMemo(() => ({
    companyClient,
  }), [companyClient]);

  useMessageError([companyClient]);

  return (
    <CustomerAccountContext.Provider
      value={memoizedValue}
    >
      {children}
    </CustomerAccountContext.Provider>
  );
}

export default CustomerAccountProvider;

export const useCustomerAccountContext = ():
  CustomerAccountContextProps => React.useContext(CustomerAccountContext);
