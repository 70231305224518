import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { FormName, useOrderContextForm } from '../context';
import Product from './ProductList';
import { FormWrapper } from '../index';
import PackagesList from './PackagesList';
import { ClientDataGood } from '../../Adapter';
import { useOrderContext } from '../../View/context';

import styles from '../index.module.scss';

type ProductsProps = FormName

export default function Products({ formName }: ProductsProps): React.ReactNode | null {
  const { orderSave, goodsForm } = useOrderContextForm();
  const { order } = useOrderContext();

  const [activeTab, setActiveTab] = useState<'Products' | 'Packages'>('Products');
  const tabs: TabsProps['items'] = useMemo(() => (
    [
      {
        forceRender: true,
        key: 'Products',
        label: 'Products',
        children: (
          <FormWrapper formName={formName} className={styles.wrapper}>
            <Product formName={formName} />
          </FormWrapper>
        ),
      },
      {
        forceRender: true,
        key: 'Packages',
        label: 'Packages',
        children: (
          <FormWrapper formName="packages" className={styles.wrapper}>
            <PackagesList formName="packages" />
          </FormWrapper>
        ),
      },
    ]
  ), [formName]);

  useEffect(() => {
    if (activeTab === 'Packages') {
      const goodsItems: ClientDataGood[] = goodsForm.getFieldsValue()?.goods || [];

      /** If we come to Packages tab and there goods without id - save order */
      if (goodsItems?.some((goodsItem) => !goodsItem?.id)) {
        orderSave(
          undefined,
          () => (/** Needs to re-fetch order to update state of goods with id's */
            order.fetch()
          ),
          { isSimplifiedValidation: ['Packages', 'Delivery'] },
        );
      }
    }
  }, [activeTab]);

  return (
    <Tabs
      key="orderTabs"
      type="card"
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key as 'Products' | 'Packages')}
      defaultActiveKey="Products"
      items={tabs}
    />
  );
}
