import React, { useEffect, useState } from 'react';
import { Alert, Input, Spin } from 'antd';

import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {
  DeliveryServiceType,
  TrackingInfo, TrackingMethod,
  useOrderTrackingDBSchenkerGet,
  useOrderTrackingDHLGet,
  useOrderTrackingFedexGet,
} from '../../../../hooks/api/tracking';
import { DeliveryService, DeliveryServiceDisplayName, OrderStatus } from '../Adapter/enums';
import { useOrderContext } from '../View/context';

import styles from './index.module.scss';

function OpenDeliveryServiceLink({ href, label }: { href: string, label?: string }) {
  return (
    <div className={styles.link}>
      <NavLink
        to={href}
        target="_blank"
      >
        {label || 'Open on the delivery service website'}
      </NavLink>
    </div>
  );
}

interface TrackingProps {
  title: string;
  actions?: React.ReactNode;
  trackingNumber?: string;
  deliveryService?: string;
  trackingLink?: string;
  openTrackingSiteLinks?: string;
  className?: string;
}

function Tracking({
  title, actions, trackingNumber = '', deliveryService = '', trackingLink = '', openTrackingSiteLinks = '', className,
}: TrackingProps): React.ReactNode | null {
  const { order, clientOrderData } = useOrderContext();
  const [tracking, setTracking] = useState(trackingNumber);
  const orderTrackingDHLGet = useOrderTrackingDHLGet(trackingNumber);
  const orderTrackingFedexGet = useOrderTrackingFedexGet(trackingNumber);
  const orderTrackingDBSchenkerGet = useOrderTrackingDBSchenkerGet(trackingNumber);
  const [state, setState] = useState<TrackingInfo | null>(null);
  let lastStatus = '';
  const loading = orderTrackingDHLGet.loading || orderTrackingFedexGet.loading || orderTrackingDBSchenkerGet.loading;

  /* const trackingFetch = (trackNumber = tracking): Promise<void> => {
    setState(null);

    switch (deliveryService?.toLowerCase()) {
      case DeliveryService.DHL: orderTrackingDHLGet.fetch(undefined, trackNumber); break;
      case DeliveryService.FEDEX: orderTrackingFedexGet.fetch(undefined, trackNumber); break;
      case DeliveryService.SCHENKER: orderTrackingDBSchenkerGet.fetch(undefined, trackNumber); break;
      default: break;
    }
  }; */
  const trackingFetch = (trackNumber = tracking) => new Promise((resolve, reject) => {
    setState(null);

    try {
      switch (deliveryService?.toLowerCase()) {
        case DeliveryService.DHL:
          orderTrackingDHLGet.fetch(undefined, trackNumber)
            .then(resolve)
            .catch(reject);
          break;
        case DeliveryService.FEDEX:
          orderTrackingFedexGet.fetch(undefined, trackNumber)
            .then(resolve)
            .catch(reject);
          break;
        case DeliveryService.SCHENKER:
          orderTrackingDBSchenkerGet.fetch(undefined, trackNumber)
            .then(resolve)
            .catch(reject);
          break;
        default:
          resolve('Tracking fetched');
          break;
      }
    } catch (error) {
      reject(error);
    }
  });

  useEffect(() => {
    if (!orderTrackingDHLGet.error && !orderTrackingDHLGet.loading && orderTrackingDHLGet.data) {
      setState(orderTrackingDHLGet.data);
    }
  }, [orderTrackingDHLGet.data]);

  useEffect(() => {
    if (!orderTrackingFedexGet.error && !orderTrackingFedexGet.loading && orderTrackingFedexGet.data) {
      setState(orderTrackingFedexGet.data);
    }
  }, [orderTrackingFedexGet.data]);

  useEffect(() => {
    if (!orderTrackingDBSchenkerGet.error && !orderTrackingDBSchenkerGet.loading && orderTrackingDBSchenkerGet.data) {
      setState(orderTrackingDBSchenkerGet.data);
    }
  }, [orderTrackingDBSchenkerGet.data]);

  useEffect(() => {
    if (trackingNumber) {
      setTracking(trackingNumber);

      if (deliveryService) {
        trackingFetch(trackingNumber);
      }
    }

    if (!trackingNumber) {
      setTracking('');
    }
  }, [trackingNumber]);

  useEffect(() => {
    if (tracking && deliveryService) {
      trackingFetch();
    }
  }, [deliveryService]);

  return (
    <div className={clsx(styles.tracking, className)}>
      <h2>{title}</h2>

      {actions ? (
        <div className={styles.actions}>
          {actions}
        </div>
      ) : null}

      <Input
        prefix={clientOrderData?.delivery.deliveryService
          ? (
            <span className="text-secondary">
              {DeliveryServiceDisplayName[clientOrderData.delivery.deliveryService as DeliveryServiceType]}
            </span>
          )
          : undefined}
        suffix={(
          loading
            ? <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
            : (
              <ReloadOutlined
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.preventDefault();

                  trackingFetch()
                    .then(() => { order.fetch(); });
                }}
              />
            )
        )}
        value={tracking}
        onChange={({ currentTarget }) => setTracking(currentTarget.value)}
      />

      {/* eslint-disable-next-line no-nested-ternary */}
      {clientOrderData?.status === OrderStatus.SHIPPED_OUT
      || clientOrderData?.status === OrderStatus.ON_THE_WAY
      || clientOrderData?.status === OrderStatus.DELIVERED ? (
          openTrackingSiteLinks ? (
            <OpenDeliveryServiceLink href={openTrackingSiteLinks} />
          ) : null
        ) : (
          <div className="text-secondary" style={{ marginTop: '12px' }}>
            The order has not been shipped yet
          </div>
        )}

      {tracking && trackingLink && deliveryService && deliveryService === DeliveryService.OTHER ? (
        <OpenDeliveryServiceLink
          // href={trackingLink.toLowerCase().includes('https:') ? `${trackingLink}${trackingNumber}`
          //   : `https://${trackingLink}${trackingNumber}`}
          href={trackingLink}
        />
      ) : null}

      {clientOrderData?.deliveredDate ? (
        <Alert
          banner
          message={`Delivered ${dayjs(clientOrderData?.deliveredDate).utc().format('YYYY-MM-DD HH:mm:ss')}`}
          type="success"
          style={{ margin: '16px 0' }}
        />
      ) : null}

      {clientOrderData?.shippedDate ? (
        <Alert
          banner
          message={`Shipped Out ${dayjs(clientOrderData?.shippedDate).utc().format('YYYY-MM-DD HH:mm:ss')}`}
          type="info"
          style={{ margin: '16px 0' }}
        />
      ) : null}

      {state?.shipments.length ? (
        <div className={styles.list}>
          {state?.shipments.map(({
            address, description, status, timestamp,
          }, i) => {
            const timeFormatted = state.type === TrackingMethod.DHL
              ? dayjs(timestamp).format('YYYY-MM-DD HH:mm')
              : dayjs(timestamp).utc().format('YYYY-MM-DD HH:mm');

            const content = (
              <React.Fragment key={timestamp}>
                {lastStatus !== status ? (
                  <Alert
                    showIcon
                    message={`${status} ${timeFormatted}`}
                    type="success"
                  />
                ) : null}
                <div className={styles.overflow}>
                  <div className={styles.detail}>
                    <i className={i === 0 ? styles.active : undefined} />
                    <span>
                      <div>
                        {timeFormatted}
                      </div>
                      {description}
                      <div>
                        {`${address.countryCode} ${address.name}`}
                      </div>
                    </span>
                  </div>
                </div>
              </React.Fragment>
            );

            lastStatus = status;

            return content;
          })}
        </div>
      ) : null}
    </div>
  );
}

export default Tracking;
