import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess, FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';
import { RolePathType } from '../../utils';
import { Invoice, InvoiceCreateParams } from './invoices';

export enum OfferStatus {
  'pending' = 'Pending',
  'declined' = 'Declined',
  'accepted' = 'Accepted',
}

export enum OfferActionEnum {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

// Offer is the same except for 'status' field. Offer has 'offerStatus' instead
export interface Offer extends Invoice {
  id: string;
  createdAt: string;

  offerStatus: OfferStatusType;
  reason?: string | null;
}

interface OffersAllGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useOffersGet = <DD = OffersTableData>(
  decorateData?: (data: PagingDataResponse<Offer>) => DD,
): FetchGet<
    PagingDataResponse<Offer>,
    OffersAllGetParams,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'admin/offers',
    { autoStart: false, startStateLoading: false, decorateData },
  );

// export interface OfferAll {
//   number: string,
//   dueDate: string,
//   description: string,
//   currency: string,
//   conversionRate: number,
//   offerStatus: OfferStatusType,
//   order: Order,
//   services: ServicesDetails[],
//   roundDifference: number,
// }

export type OfferStatusType = 'pending' | 'declined' | 'accepted';

export interface OffersTable extends Offer {
  key: string;
}

export interface OffersTableData {
  data: OffersTable[];
  total: number;
}

export const useOffersTableGet = () => useOffersGet<OffersTableData>(
  ({ data, meta }: PagingDataResponse<Offer>): OffersTableData => ({
    data: data.map((item: Offer): OffersTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

// export const useOffersOptionsGet = () => useOffersGet<Option[]>(
//   ({ data }: PagingDataResponse<Offers>): Option[] => (
//     data.map((item) => (
//       { value: item?.status || '', label: item?.status || '' }
//     ))
//   ),
// );

export const useOffersGetById = <DD = Offer>(
  rolePath: RolePathType,
  id?: string,
  decorateData?: (data: Offer) => DD,
): FetchGetId<
  Offer,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `${rolePath}/offers`,
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export const useOffersDelete = (rolePath: RolePathType, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${rolePath}/offers`, id)
);

export interface OfferCreateParams {
  id: string,
}

export const useOfferResend = (
  rolePath: RolePathType,
  id?: string,
): FetchCreate<OfferCreateParams, DefaultFetchError> => (
  useFetchCreate(`${rolePath}/offers/${id}/send-by-mail`)
);

export const useOfferDownload = (
  rolePath: RolePathType,
  id?: string,
  orderId?: string,
  abortController?: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    rolePath === 'admin'
      ? `${rolePath}/offers${id ? `/${id}/download-as-pdf` : ''}`
      : `${rolePath}/orders/${orderId}/offers${id ? `/${id}/download-as-pdf` : ''}`,
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal,
      },
    },
    'arraybuffer',
  )
);

export const useOfferUpdate = (
  rolePath: RolePathType,
  id?: string,
): FetchUpdate<Invoice, DefaultFetchError, InvoiceCreateParams> => (
  useFetchUpdate(`${rolePath}/offers${id ? `/${id}` : ''}`)
);

export interface OfferClientAction{
  reason?: string,
}
export const useOfferClientAction = (
  rolePath: RolePathType,
  id?: string,
  offerId?: string,
  action?: string,
): FetchCreate<
  FetchSuccess, DefaultFetchError, OfferClientAction> => (
  useFetchCreate(`${rolePath}/orders/${id}/offers/${offerId}/${action}`)
);
