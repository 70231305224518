import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { SelectYesNoList } from '../../../../../../contstant';

function SelectTransportInsurance({
  options,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  return (
    <OrderSelect
      options={options || SelectYesNoList}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectTransportInsurance;
