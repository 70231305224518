import {
  DefaultFetchError, FetchCreate, FetchSuccess, useFetchCreate,
} from '../fetch';

export interface ResendSignUpParams {
  email: string;
}

export const useResendSignUpEmail = (): FetchCreate<FetchSuccess, DefaultFetchError, ResendSignUpParams> => (
  useFetchCreate('auth/resend-confirmation-email', {
    authorization: false,
  })
);

export interface ConfirmEmailParams {
  secretKey: string;
  password?: string;
}

export const useConfirmEmail = (): FetchCreate<FetchSuccess, DefaultFetchError, ConfirmEmailParams> => (
  useFetchCreate('auth/confirm/email', {
    authorization: false,
  })
);
