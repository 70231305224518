import React, { useEffect, useMemo } from 'react';
import { Button, Checkbox, Form } from 'antd';
import clsx from 'clsx';
import { PaperClipOutlined } from '@ant-design/icons';
import { useParams, useSearchParams } from 'react-router-dom';
import { useOrderContextForm } from '../context';
import { useOrderContext } from '../../View/context';
import { DeliveryServiceDisplayName, OrderStatus } from '../../Adapter/enums';
import {
  ClientDataDelivery,
  ClientDataDocuments,
  ClientDataGood,
  ClientDataImporter,
  ClientDataOrderType,
  ClientDataPackages,
  ClientDataShipper,
  ClientProductType,
} from '../../Adapter';
import { capitalizeFirstLetter } from '../../../../../utils';
import Product from '../Products/ProductList';
import { useAuth } from '../../../../../store/auth';
import { ServiceEnumDHL, ServiceEnumFedex } from '../../../../../enums/orderServices';
import { DownloadAllButton } from '../../View';
import { useFileDownload } from '../../../../../hooks/downloadNotification';
import { TermsOfServiceButton } from '../../../Companies/CreateProfile/TermsOfService';
import SummaryBox from './SummaryBox';
import { isOrderTabsDisabled } from '../index';
import { findMainInvoice } from '../Paperwork/Forms/ModalInvoice';

import styles from './index.module.scss';

interface StepConfig {
  key: string;
  order: number;
  title: string;
  text: string;
  visible: boolean;
}

const DeliveryServicesTypes = {
  ...ServiceEnumDHL,
  ...ServiceEnumFedex,
};

const displayServiceType = (serviceType?: keyof typeof DeliveryServicesTypes) => (
  serviceType ? DeliveryServicesTypes[serviceType] : '-');

/** User should come to overview page only when order ALREADY SAVED and has ID */
function Overview() {
  const { id } = useParams();
  const { user } = useAuth();
  const [_, setSearchParams] = useSearchParams();

  const {
    forms: {
      orderType,
      shipper,
      importer,
      goods,
      packages,
      documents,
      delivery,
    },
    overviewForm, // Overview not in form list, to not include it during sending and validation.
    orderSave,
  } = useOrderContextForm();
  const { order, clientOrderData } = useOrderContext();

  const { handleDownload } = useFileDownload();

  const orderTypeWatch: ClientDataOrderType = orderType.getFieldsValue();
  const shipperWatch: ClientDataShipper = shipper.getFieldsValue();
  const importerWatch: ClientDataImporter = importer.getFieldsValue();
  const goodsWatch: { goods: ClientDataGood[] } = goods.getFieldsValue();
  const packagesWatch: { packages: ClientDataPackages[], packageSummary?: string } = packages.getFieldsValue();
  const documentsWatch: ClientDataDocuments = documents.getFieldsValue();
  const deliveryWatch: ClientDataDelivery = delivery.getFieldsValue();

  const isAssistanceNeeded = !!orderTypeWatch?.isAssistanceNeeded;
  const productTypeWatch: ClientProductType | '' = orderTypeWatch?.productType;
  const isProductsDangerous = ['dangerous', 'both'].includes(productTypeWatch);
  const isPrePayment = user?.company?.paymentDays === 0;

  /** If main Invoice in status which means that 'invoice sent' - dont do updateInvoice flow. */
  const mainInvoice = findMainInvoice(clientOrderData?.invoices || []);
  const updateInvoicesFlow = ['invisible', 'draft'].includes(mainInvoice?.status)
    /** If no assistance OR assistance & status not draft and not pending - updateInvoice. */
    && (!isAssistanceNeeded
    || (isAssistanceNeeded && clientOrderData?.status && ![
      OrderStatus.DRAFT,
      OrderStatus.PENDING,
      // OrderStatus.IN_PROGRESS, // Replaced with check status of main invoice
    ].includes(clientOrderData?.status)));

  useEffect(() => {
    /** When overview tab renders we check if order already has form values (clientOrderData) and id. */
    /** If NO loadings, and blocker is active (means there some changes made) */
    if (id && clientOrderData) {
      orderSave(undefined, undefined, updateInvoicesFlow ? { query: '?updateInvoice=yes' } : undefined);
    }
  }, [id]);

  const nextStepsConfig: StepConfig[] = useMemo(() => (
    [
      {
        key: 'requestProcessing',
        order: 1,
        title: 'Request processing',
        text: 'Processing your request as soon as possible and contact you with details.',
        visible: isProductsDangerous,
      },
      {
        key: 'offering',
        order: 2,
        title: 'Offering (optional)',
        text: 'We will provide you with an offer that is optimal for your order.',
        visible: isProductsDangerous,
      },
      // Sephyre and no asistance:
      {
        key: 'shipping',
        order: isPrePayment ? 5 : 3,
        title: 'Shipping',
        text: 'The order will be automatically transferred to the delivery service after payment.',
        visible: true,
      },
      {
        key: 'invoicing',
        order: isPrePayment ? 3 : 4,
        title: 'Invoicing',
        text: 'An invoice will be provided to you in accordance with your Sephyre payment agreement.',
        visible: !isPrePayment || isAssistanceNeeded,
      },
      {
        key: 'payment',
        order: isPrePayment ? 4 : 5,
        title: 'Payment',
        text: 'Select a payment method and enter your details to pay.',
        visible: true,
      },
    ].sort((a, b) => a.order - b.order)
      .filter((item) => item.visible)
  ), [user?.company, isAssistanceNeeded, isProductsDangerous, isPrePayment]);

  const tabEditDisabled = useMemo(() => (
    clientOrderData ? isOrderTabsDisabled(clientOrderData.status, user?.role) : false
  ), [clientOrderData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftContent}>
        <div className={styles.orderType}>
          <div className={styles.header}>
            <h2>Order Type</h2>
            {user?.role === 'user' ? (
              <div>
                <Button
                  onClick={() => setSearchParams({ tab: 'Order type' })}
                  disabled={tabEditDisabled}
                >
                  Edit
                </Button>
              </div>
            ) : null}
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.col3}>
                <div>
                  <b>Shipped products</b>
                  {capitalizeFirstLetter(orderTypeWatch?.productType || 'Regular')}
                </div>
                <div>
                  <b>Temp. mode or tracking</b>
                  {orderTypeWatch?.isTemperatureControl ? 'Yes' : 'No'}
                </div>
                <div>
                  <b>Additional Sephyre assistance</b>
                  {orderTypeWatch?.isTemperatureControl
                    || orderTypeWatch?.isAssistanceNeeded
                    || ['dangerous', 'both'].includes(orderTypeWatch?.productType || '')
                    ? 'Yes' : 'No'}
                </div>

                <div>
                  <b>Offer request</b>
                  {orderTypeWatch?.isOfferNeeded ? 'Yes' : 'No'}
                </div>
              </div>
              {orderTypeWatch?.isAssistanceNeeded && orderTypeWatch?.assistanceNote ? (
                <div style={{ marginTop: '8px' }}>
                  <b>Request assistance details</b>
                  <span>
                    {orderTypeWatch?.assistanceNote}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.deliveryInfo}>
          <div className={styles.header}>
            <h2>Delivery Info</h2>
            <div>
              <Button
                onClick={() => setSearchParams({ tab: 'Delivery' })}
                // disabled={tabEditDisabled}
              >
                Edit
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Mode of transport</b>
                  {deliveryWatch?.modeOfTransport}
                </div>
                {/* <div>
                  <b>Consignment date</b>
                  {order?.data?.consignmentDate
                    ? dayjs(order?.data?.consignmentDate).utc().format('DD/MM/YYYY') : '-'}
                </div> */}
                <div>
                  <b>Pickup date & Time</b>
                  {deliveryWatch?.pickupTime && deliveryWatch?.pickupTime?.length === 2
                    ? `${deliveryWatch?.pickupTime[0]?.utc().format('DD/MM/YYYY') || ''} `
                      + `${deliveryWatch?.pickupTime[0]?.utc().format('HH:mm') || ''}-`
                      + `${deliveryWatch?.pickupTime[1]?.utc().format('HH:mm') || ''}`
                    : '-'}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Delivery terms</b>
                  {deliveryWatch?.deliveryTerms || '-'}
                </div>

                <div>
                  <b>Delivery service</b>
                  {deliveryWatch?.deliveryService ? DeliveryServiceDisplayName[deliveryWatch?.deliveryService] : '-'}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Transport Insurance</b>
                  {deliveryWatch?.insuranceValue && deliveryWatch?.insuranceCurrency ? (
                    <>
                      {' '}
                      {deliveryWatch?.insuranceValue}
                      {' '}
                      {deliveryWatch?.insuranceCurrency}
                    </>
                  ) : '-'}
                </div>

                <div>
                  <b>Delivery service type</b>
                  {displayServiceType(deliveryWatch?.serviceType as keyof typeof DeliveryServicesTypes)}
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Billing account number</b>
                  {deliveryWatch?.billingNumber || '-'}
                </div>

                {clientOrderData?.general?.waybillNo ? (
                  <div>
                    <b>Waybill No.</b>
                    {clientOrderData?.general?.waybillNo || '-'}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.row}>
              <b>Important notes for Sephyre</b>
              <div>
                {deliveryWatch?.notes || '-'}
              </div>
            </div>

          </div>
        </div>

        {/** Shipper * Consignee: */}
        <div className={styles.shipper}>
          <div className={styles.header}>
            <h2>Shipper</h2>
            <div>
              <Button
                onClick={() => setSearchParams({ tab: 'Shipper' })}
                disabled={tabEditDisabled}
              >
                Edit
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Shipper details</b>
                  <div>{shipperWatch?.company}</div>
                  <div>{shipperWatch?.contactName}</div>
                  <a href={`tel:${shipperWatch?.phone}`}>{shipperWatch?.phone}</a>
                  <a href={`mailto:${shipperWatch?.email}}`}>{shipperWatch?.email}</a>
                  <div>
                    {shipperWatch?.address1}
                    {' '}
                    {shipperWatch?.address2}
                  </div>
                  <div>{shipperWatch?.postalCode}</div>
                  <div>{shipperWatch?.country}</div>
                  <div>{shipperWatch?.state}</div>
                  <div>{shipperWatch?.city}</div>
                  <div>
                    TIN no.
                    {' '}
                    {shipperWatch?.tin}
                  </div>
                  <div>
                    Reg. no.
                    {' '}
                    {shipperWatch?.registerNumber}
                  </div>
                  <div>
                    Reference
                    {' '}
                    {shipperWatch?.reference}
                  </div>
                </div>
                <div>
                  <b>Pickup details</b>
                  {shipperWatch?.isAddressDifferent ? (
                    <>
                      <div>{shipperWatch?.details?.company}</div>
                      <div>{shipperWatch?.details?.contactName}</div>
                      <a href={`tel:${shipperWatch?.details?.phone}`}>
                        {shipperWatch?.details?.phone}
                      </a>
                      <a href={`mailto:${shipperWatch?.details?.email}`}>
                        {shipperWatch?.details?.email}
                      </a>
                      <div>
                        {shipperWatch?.details?.address1}
                        {' '}
                        {shipperWatch?.details?.address2}
                      </div>
                      <div>{shipperWatch?.details?.postalCode}</div>
                      <div>{shipperWatch?.details?.country}</div>
                      <div>{shipperWatch?.details?.state}</div>
                      <div>{shipperWatch?.details?.city}</div>
                    </>
                  ) : (
                    <span style={{ fontSize: '16px' }}>The same as Shipper details</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.importer}>
          <div className={styles.header}>
            <h2>Consignee</h2>
            <div>
              <Button
                onClick={() => setSearchParams({ tab: 'Consignee' })}
                disabled={tabEditDisabled}
              >
                Edit
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.col2}>
                <div>
                  <b>Importer details</b>
                  <div>{importerWatch?.company}</div>
                  <div>{importerWatch?.contactName}</div>
                  <a href={`tel:${importerWatch?.phone}`}>{importerWatch?.phone}</a>
                  <a href={`mailto:${importerWatch?.email}}`}>{importerWatch?.email}</a>
                  <div>
                    {importerWatch?.address1}
                    {' '}
                    {importerWatch?.address2}
                  </div>
                  <div>{importerWatch?.postalCode}</div>
                  <div>{importerWatch?.country}</div>
                  <div>{importerWatch?.state}</div>
                  <div>{importerWatch?.city}</div>
                  <div>
                    TIN no.
                    {' '}
                    {importerWatch?.tin}
                  </div>
                  <div>
                    Reg. no.
                    {' '}
                    {importerWatch?.registerNumber}
                  </div>
                  <div>
                    Reference
                    {' '}
                    {importerWatch?.reference}
                  </div>
                </div>
                <div>
                  <b>Delivery details</b>
                  {importerWatch?.isAddressDifferent ? (
                    <>
                      <div>{importerWatch?.details?.company}</div>
                      <div>{importerWatch?.details?.contactName}</div>
                      <a href={`tel:${importerWatch?.details?.phone}`}>
                        {importerWatch?.details?.phone}
                      </a>
                      <a href={`mailto:${importerWatch?.details?.email}`}>
                        {importerWatch?.details?.email}
                      </a>
                      <div>
                        {importerWatch?.details?.address1}
                        {' '}
                        {importerWatch?.details?.address2}
                      </div>
                      <div>{importerWatch?.details?.postalCode}</div>
                      <div>{importerWatch?.details?.country}</div>
                      <div>{importerWatch?.details?.state}</div>
                      <div>{importerWatch?.details?.city}</div>
                    </>
                  ) : (
                    <span style={{ fontSize: '16px' }}>The same as Importer details</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.products}>
          <div className={styles.header}>
            <h2>Products</h2>
            <div>
              <Button
                onClick={() => setSearchParams({ tab: 'Products' })}
                disabled={tabEditDisabled}
              >
                Add
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <Product
                modeRewrite="view"
                formName="goods"
                key={JSON.stringify(goodsWatch?.goods)}
                list={goodsWatch?.goods}
                update={() => {
                  order.fetch();
                }}
              />
            </div>
          </div>
        </div>

        <div className={clsx(styles.documents, 'documents')}>
          <div className={styles.header}>
            <h2>Documents</h2>
            <div>
              {id ? (
                <DownloadAllButton id={id} />
              ) : null}
              <Button onClick={() => setSearchParams({ tab: 'Documents' })}>
                Edit
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <div>
              <div className={styles.title}>
                Proforma Invoice
              </div>
              <div className={styles.list}>
                {documentsWatch?.proformaInvoices?.fileList?.length
                  ? documentsWatch?.proformaInvoices.fileList.map(({ url, name, uid }) => (
                    <div key={url}>
                      <a
                        href={url}
                          // target="_blank" rel="noreferrer" download={name}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload({ url, name, uid });
                        }}
                      >
                        <PaperClipOutlined />
                        {name}
                      </a>
                    </div>
                    // orderProformaInvoicesGet exist only on UPDATE page. think about this.
                  )) : null}
              </div>
            </div>

            <div>
              <div>
                <div className={styles.title}>
                  Additional documents
                </div>
                <div className={styles.list}>
                  {documentsWatch?.additionalDocuments?.fileList?.length
                    ? documentsWatch.additionalDocuments.fileList.map(({ url, name, uid }) => (
                      <div key={url}>
                        <a
                          href={url}
                            // target="_blank" rel="noreferrer" download={name}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownload({ url, name, uid });
                          }}
                        >
                          <PaperClipOutlined />
                          {name}
                        </a>
                      </div>
                    )) : null}
                </div>
              </div>
              <div>
                <span>Emergency phone number: </span>
                {deliveryWatch?.emergencyPhoneNumber}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={styles.rightContent}>
        <div className={styles.summaryInfo}>
          <SummaryBox
            clientOrderData={clientOrderData}
            isAssistanceNeeded={isAssistanceNeeded}
          />

          {clientOrderData?.status && [
            OrderStatus.DRAFT,
          ].includes(clientOrderData?.status) ? (
            <Form form={overviewForm} className={styles.overviewForm}>
              <Form.Item
                name="agreeTerms"
                valuePropName="checked"
                style={{ width: 'unset' }}
                rules={[{
                  required: true,
                  message: 'Please agree to the Terms of Service',
                  validator: (__, value) => (
                    value ? Promise.resolve() : Promise.reject(new Error('You must accept the agreement'))),
                }]}
              >
                <Checkbox className={styles.checkbox} style={{ width: 'unset' }}>
                  I agree with
                  {' '}
                  <TermsOfServiceButton variant="link" />
                </Checkbox>
              </Form.Item>
            </Form>
            ) : null}

          <div className={styles.steps}>
            <div className={styles.stepsTitle}>Next Steps</div>
            {nextStepsConfig.map((item) => (
              <div key={item.key} className={styles.step}>
                <strong>{item.title}</strong>
                <p className="text-secondary">{item.text}</p>
              </div>
            ))}
          </div>

        </div>
      </div>

    </div>
  );
}

export default Overview;
