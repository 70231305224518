/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Button, Col, Divider, Dropdown, Modal, Row,
  Spin,
} from 'antd';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { downloadFromAnchor } from '../../../../../../utils';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';
import { InvoiceFooterProps, InvoiceModalProps } from '../../../../Invoices/View';
import {
  DeliveryNote,
  useDeliveryNoteDelete,
  useDeliveryNoteDownload, useDeliveryNoteGetByOrderId,
  useDeliveryNoteResend,
} from '../../../../../../hooks/api/deliveryNotes';
import { useOrderContext } from '../../../View/context';
import useInvoiceCalculations from '../../../../Invoices/View/DetailsContainer/useInvoiceCalculations';
import { ServiceUnitShortEnum } from '../../../../../../hooks/api/services';
import { ClientData } from '../../../Adapter';
import { extractUnNumbersFromRes } from '../../Paperwork/Forms/ModalDeliveryNote';

import styles from './index.module.scss';

function FooterActions({
  orderId, itemId, itemNumber, handleClose, afterAction, clearSelectedState,
}: Omit<InvoiceFooterProps, 'status' | 'setTableKey'>) {
  const { open, contextHolder } = useSimpleModal();
  const deliveryNoteResend = useDeliveryNoteResend(orderId, itemId);
  const deliveryNoteDelete = useDeliveryNoteDelete(orderId, itemId);
  const downloadDeliveryNote = useDeliveryNoteDownload(orderId, itemId);
  const { order } = useOrderContext();

  useMessageError([deliveryNoteDelete, downloadDeliveryNote]);
  useMessageSuccess([deliveryNoteDelete], 'DeliveryNote deleted successfully');
  useMessageSuccess([downloadDeliveryNote], 'Download successfully');

  useEffect(() => {
    if ((!deliveryNoteDelete.error && !deliveryNoteDelete.loading && deliveryNoteDelete.data)) {
      order.fetch();
      clearSelectedState?.();
      handleClose();
    }
  }, [
    deliveryNoteDelete.error, deliveryNoteDelete.loading, deliveryNoteDelete.data,
  ]);

  return (
    <div className={styles.footerActions}>
      <Dropdown
        menu={{
          items: [
            {
              key: 'Send',
              label: 'Send',
              onClick: ({ domEvent }) => {
                domEvent.preventDefault();
                deliveryNoteResend.fetch();
              },
            },
            {
              key: 'Download',
              label: 'Download',
              onClick: ({ domEvent }) => {
                domEvent.preventDefault();
                downloadDeliveryNote.fetch()
                  .then((response) => {
                    if (!response) return;

                    downloadFromAnchor(response, `deliveryNote_${itemNumber}`, 'application/pdf');
                  });
              },
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => {
                open({
                  icon: <ExclamationCircleFilled />,
                  title: 'Delete delivery note?',
                  content: (
                    <span>Are you sure you want to delete delivery note?</span>
                  ),
                  cancelText: 'Cancel',
                  okText: 'Delete',
                  okButtonProps: { danger: true },
                  onOk: () => {
                    deliveryNoteDelete.fetch();
                  },
                });
              },
              danger: true,
            },
          ],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          icon={<EllipsisOutlined />}
          style={{ transform: 'translate(0px, 1.5px)' }}
        />
      </Dropdown>
      {contextHolder}
    </div>
  );
}

interface DeliveryNoteModalProps extends InvoiceModalProps<DeliveryNote> {
  orderId: string;
  dataSource?: DeliveryNote;
  clearSelectedState?: () => void;
}

export interface DeliveryNoteTableProps {
  orderData: ClientData | null | undefined;
}

export function DeliveryNoteTable({ orderData }: DeliveryNoteTableProps) {
  const { units } = orderData?.deliveryNotes || {};

  const {
    totalQuantity,
    totalVolume,
    totalWeight,
    totalGross,
  } = useInvoiceCalculations(orderData, [], 0);

  const calculateQuantityCol = () => {
    let quantity;

    if (units === 'kilograms') {
      quantity = totalGross || totalWeight;
    } else if (units === 'pieces') {
      quantity = totalQuantity;
    } else {
      quantity = 0;
    }

    return quantity;
  };

  return (
    <div>
      <Row
        gutter={24}
        justify="center"
        className={styles.borderBottomBlack}
      >
        {[
          { label: 'Item description', span: 16 },
          { label: 'Unit', span: 3, style: { alignItems: 'center' } },
          { label: 'Quantity', span: 4, style: { alignItems: 'end' } },
        ].map(({ label, span, style }) => (
          <Col key={label} span={span} className={styles.tableCol} style={style}>
            <strong className={styles.tableRow}>
              {label}
            </strong>
          </Col>
        ))}
      </Row>
      <Row
        gutter={24}
        justify="center"
        className={styles.borderBottom}
        style={{ alignItems: 'center' }}
      >
        <Col span={16} className={styles.tableCol}>
          <div
            className={styles.tableRow}
            style={{
              flexDirection: 'column', justifyContent: 'center', alignItems: 'start',
            }}
          >
            <strong>
              Shipping details
            </strong>
            <span>
              {/* style={{ width: '450px' }} */}
              {orderData?.goods?.goods ? (
                ` ${[...new Set(orderData.goods.goods
                  .map((item) => item.name || '')
                  .filter(Boolean))].join(', ')} `
              ) : null}
              {totalQuantity ? `${totalQuantity}x,` : ''}
              {totalWeight ? ` ${totalWeight}kg,` : ''}
              {totalVolume ? ` ${Number(totalVolume).toFixed(3)}m³ ` : ''}
              {/* {orderData?.goods?.goods?.some((item) => item.dangerousGood?.unNumber) && (
                ` / ${[...new Set(orderData.goods.goods
                  .map((item) => (item.dangerousGood?.unNumber ? `UN${item.dangerousGood.unNumber}` : ''))
                  .filter(Boolean))].join(', ')}`
              )} */}
              {extractUnNumbersFromRes(orderData?.goods?.goods)}
            </span>
          </div>
        </Col>
        {[
          { label: ServiceUnitShortEnum[units === 'pieces' ? 'pieces' : 'kilograms'], span: 3, style: { justifyContent: 'start', alignItems: 'center' } },
          { label: calculateQuantityCol(), span: 4, style: { justifyContent: 'start', alignItems: 'end' } },
        ].map(({ label, span, style }) => (
          <Col key={label} span={span} className={styles.tableCol} style={style}>
            <span className={styles.tableRow}>
              {label}
            </span>
          </Col>
        ))}
      </Row>
    </div>
  );
}

function DeliveryNoteModal({
  orderId, dataSource,
  showFooter = false, id, isModalOpen, handleClose, afterAction, clearSelectedState,
}: DeliveryNoteModalProps) {
  const { clientOrderData } = useOrderContext();
  const deliveryNoteById = useDeliveryNoteGetByOrderId(orderId);

  useEffect(() => {
    if (orderId && !dataSource && isModalOpen) {
      deliveryNoteById.fetch();
    }
  }, [orderId, id, dataSource, isModalOpen]);

  const deliveryNoteData = deliveryNoteById.data || dataSource;

  const {
    contactName,
    address1,
    address2,
    postalCode,
    city,
    country,
    tin,
  } = clientOrderData?.shipper || {};
  const {
    contactName: shipperContactName,
    phone,
    company: shipperToCompany,
    address1: shipperToAddress,
    postalCode: shipperToPostalCode,
    city: shipperToCity,
    country: shipperToCountry,
  } = clientOrderData?.importer?.isAddressDifferent === true
    ? clientOrderData?.importer?.details || {}
    : clientOrderData?.importer || {};
  const {
    orderNumber,
  } = clientOrderData?.delivery || {};

  const { number } = clientOrderData?.deliveryNotes || {};

  /* const totalWeight = clientOrderData?.packages?.packages.length
    ? clientOrderData?.packages?.packages.reduce((sum, pack) => sum + pack.weight, 0)
    : clientOrderData?.goods?.goods?.reduce((sum, pack) => sum + (pack.gross * pack.quantity), 0); */

  return (
    <Modal
      title="Delivery note details"
      open={isModalOpen}
      destroyOnClose
      centered
      closable={!showFooter}
      maskClosable={false}
      width={1000}
      footer={false}
      onCancel={() => {
        handleClose();
      }}
    >
      <Divider />
      <div>
        <div className={styles.invoiceGrid}>
          <div className={styles.invoiceHeader}>
            <h2>
              DELIVERY NOTE No.
              {number || ''}
            </h2>
            <img src="/logo.png" alt="Logo" />
          </div>

          <div className={styles.invoiceInfo} style={{ marginTop: '50px' }}>
            <div className={styles.column}>
              <div>
                {contactName ? (
                  <strong>{contactName}</strong>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>To</strong>
              </div>
            </div>

            <div className={styles.column}>
              {address1 ? (
                <span>{address1}</span>
              ) : null}
              {address2 ? (
                <span>{address2}</span>
              ) : null}
              {postalCode ? (
                <span>{postalCode}</span>
              ) : null}
              {city ? (
                <span>{city}</span>
              ) : null}
              {country ? (
                <span>{country}</span>
              ) : null}
              {tin ? (
                <span>{tin}</span>
              ) : null}
            </div>
            <div className={styles.column}>
              {shipperToCompany ? (
                <span>{shipperToCompany}</span>
              ) : null}
              {shipperToAddress ? (
                <span>{shipperToAddress}</span>
              ) : null}
              {shipperToPostalCode ? (
                <span>{shipperToPostalCode}</span>
              ) : null}
              {shipperToCity ? (
                <span>{shipperToCity}</span>
              ) : null}
              {shipperToCountry ? (
                <span>{shipperToCountry}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.invoiceInfo} style={{ marginTop: '12px' }}>
            <div className={styles.column}>
              <strong>Created date: </strong>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Contact person:</strong>
                {shipperContactName ? (
                  <span>{shipperContactName}</span>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Order number:</strong>
                {orderNumber ? (
                  <span>{orderNumber}</span>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Phone number:</strong>
                {phone ? (
                  <span>{phone}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '0 25px' }}>
          <DeliveryNoteTable orderData={clientOrderData} />

          <div className={styles.invoiceInfo} style={{ marginTop: '48px' }}>
            <div className={styles.column}>
              <strong>Sephyre is very happy to deliver this shipment to you!</strong>
              <span style={{ margin: '24px 0' }}>Merchandise received in good order</span>
              {/* TODO rewrite without hardcoded sizes */}
              <div style={{ width: '420px', justifyContent: 'space-between' }}>
                <div style={{ width: '190px', height: '70px', borderBottom: '1px solid black' }}>Location / Date</div>
                <div style={{ width: '190px', height: '70px', borderBottom: '1px solid black' }}>Signature</div>
              </div>
            </div>
          </div>
          <div className={styles.invoiceFooter}>
            <div className={styles.footerInfo}>
              <strong>Sephyre GmbH</strong>
              <span>CH-4450 Sissach, Switzerland</span>
              <strong>Bank:</strong>
              <span>Raiffeisen</span>
              <strong>Account owner:</strong>
              <span>Sephyre GmbH</span>
              <strong>BIC:</strong>
              <span>RAIFCH22XXX</span>
            </div>
            <div className={styles.footerInfo}>
              <strong>IBAN:</strong>
              <span>CH16 8080 8006 3843 9480 1</span>
              <strong>VAT identification number:</strong>
              <span>CHE-232.506.635 MWST</span>
              <strong>Website:</strong>
              <span>sephyre.com</span>
            </div>
            <Divider />
            <div>
              <FooterActions
                clearSelectedState={clearSelectedState}
                orderId={clientOrderData?.id as string}
                itemId={id}
                itemNumber={deliveryNoteData?.number as string}
                handleClose={handleClose}
                afterAction={afterAction}
              />
            </div>
          </div>
        </div>
      </div>
      {deliveryNoteById.loading ? (
        <div className="spin-loading">
          <Spin />
        </div>
      ) : null}
    </Modal>
  );
}

export default DeliveryNoteModal;
