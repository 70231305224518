import React, { useEffect, useMemo, useState } from 'react';
import { Badge, BadgeProps, Col } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled, EuroCircleOutlined,
  ExclamationCircleFilled, FileTextOutlined,
  InfoCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useOrderContext } from '../context';
import { useAuth, UserRole } from '../../../../../store/auth';
import { Offer, OfferStatusType } from '../../../../../hooks/api/offers';
import { isRoleEnough } from '../../../../../enums/user';
import { getInvoiceStatusIcon, getVisibleInvoices, ListModalState } from '../InvoicesList';
import styles from '../InvoicesList/index.module.scss';
import IconWithBadge from '../../../../Common/IconWithBadge';
import { getInvoiceStatusColor } from '../../../Invoices';
import { capitalize } from '../../../../../utils';
import InvoicesModal from '../../../Invoices/View';
import OffersModal from '../../../Offers/View';
import { Invoice } from '../../../../../hooks/api/invoices';

type OfferStatusReturnType = { color: BadgeProps['color'], icon: React.ElementType };

export const getOfferStatusIcon = (status?: OfferStatusType): OfferStatusReturnType => {
  switch (status) {
    case 'pending':
      return {
        icon: InfoCircleFilled,
        color: 'gold',
      };

    case 'declined':
      return {
        icon: CloseCircleFilled,
        color: 'red',
      };

    case 'accepted':
      return {
        icon: CheckCircleFilled,
        color: 'green',
      };

    default: // Draft
      return {
        icon: MinusCircleFilled,
        color: '',
      };
  }
};

export const getVisibleOffers = (offers: Offer[], userRole?: UserRole) => (
  (offers || [])
  // .filter((item) => !(['invisible'] as OfferStatusType[]).includes(item.status))
  // .filter((item) => isRoleEnough(userRole, 'admin')
  //   || !(['declined'] as OfferStatusType[]).includes(item.offerStatus))
);

export function getPrioritizedOffers(visibleOffers: Offer[]): Offer | undefined {
  if (visibleOffers?.length === 0) return undefined;

  const statusPriority: Record<OfferStatusType, number> = {
    pending: 1,
    accepted: 2,
    declined: 3,
  };

  return visibleOffers.reduce((prev, current) => (
    statusPriority[current.offerStatus] < statusPriority[prev.offerStatus] ? current : prev), visibleOffers[0]);
}

function OffersList({ disableEmptyText = false }) {
  const { user } = useAuth();
  const { order, clientOrderData } = useOrderContext();
  const [modalState, setModalState] = useState<ListModalState<Offer>>({
    open: false, itemId: '', item: undefined,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = ({ item, ...props }: ListModalState<Offer>) => {
    setModalState({ ...props, item: item ? { ...item, order: order?.data } : undefined });
  };

  const visibleOffers = useMemo(() => (
    getVisibleOffers(clientOrderData?.offers || [], user?.role)
  ), [clientOrderData, user?.role]);

  useEffect(() => {
    const itemParamId = searchParams.get('offer');
    const item = visibleOffers.find((i) => itemParamId === i.id);

    if (itemParamId && item) {
      handleOpen({ open: true, itemId: itemParamId, item });
    }
  }, [visibleOffers, searchParams]);

  return (
    <div>
      {visibleOffers?.length ? (
        visibleOffers
          .map((offer) => {
            const iconConfig = getOfferStatusIcon(offer.offerStatus);

            return (
              <div
                key={offer.id}
                className={styles.item}
                tabIndex={-1}
                role="button"
                onClick={() => handleOpen({ open: true, itemId: offer.id, item: offer })}
                style={{ cursor: 'pointer' }}
              >
                <div className={styles.icon}>
                  <IconWithBadge
                    BadgeIcon={iconConfig.icon}
                    badgeColor={iconConfig.color}
                    Icon={FileTextOutlined}
                  />
                </div>

                <Col>
                  <div className={styles.invoiceNumber}>
                    {`Offer ${offer.number || '-'}`}
                  </div>
                  <div className={styles.date}>
                    {`Created: ${dayjs(offer.createdAt).format('DD/MM/YYYY')}`}
                  </div>
                </Col>

                <div style={{ textAlign: 'right' }}>
                  <Badge
                    color={getInvoiceStatusColor(offer.offerStatus)}
                    text={capitalize(offer?.offerStatus)}
                  />
                </div>
              </div>
            );
          })
      ) : (
        <div className="text-secondary">
          {disableEmptyText ? null : 'No documents yet'}
        </div>
      )}

      <OffersModal
        showFooter={false}
        id={modalState.itemId}
        isModalOpen={modalState.open}
        dataSource={modalState.item}
        handleClose={() => {
          handleOpen({ open: false, itemId: '', item: undefined });
          setSearchParams({});
        }}
        afterAction={() => order.fetch()}
      />
    </div>
  );
}

export default OffersList;
