import { DangerousGoodsItem, ValueAddedService } from '../../Adapter/types';
import { DHLOptionalServiceCodes } from '../../../../../enums/orderServices';

export const serviceDefinitions: { [key: string]: ValueAddedService } = {
  [DHLOptionalServiceCodes.adultSignature]: { label: 'Adult Signature', serviceCode: 'SD' },
  [DHLOptionalServiceCodes.neutralDelivery]: { label: 'Neutral Delivery', serviceCode: 'NN' },
  [DHLOptionalServiceCodes.directSignature]: { label: 'Direct Signature', serviceCode: 'SF' },
  [DHLOptionalServiceCodes.noSignatureRequired]: { label: 'No Signature required', serviceCode: 'SX' },
  [DHLOptionalServiceCodes.brokerNotification]: { label: 'Broker Notification', serviceCode: 'WG' },
};

export const getFormattedUnNumbers = (valueArr: string[]) => ((valueArr || [])
  .map((number) => `UN${number}`).join(', '));

export const createDangerousGoodsObject = (
  label: string,
  serviceCode: string,
  quantity: number | undefined,
  contentId: string,
  customDescription: string,
  extraFields: Partial<DangerousGoodsItem> = {},
): ValueAddedService => ({
  label,
  serviceCode,
  dangerousGoods: [
    {
      quantity: quantity || 0,
      contentId,
      customDescription,
      ...extraFields,
    },
  ],
});

export const handleDryIceChange = (value: number | null) => {
  if (typeof value === 'number' && value !== null) {
    const roundedValue = Number.parseFloat(value.toFixed(3));

    return roundedValue;
  }

  return 0;
};
export const handleUnNumbersChange = (value = '') => {
  // e: React.ChangeEvent<HTMLInputElement> | undefined
  const inputValue = value || '';
  const digits = inputValue.replace(/\D/g, '');
  const numbersArray = digits.match(/.{1,4}/g) || [];
  const uniqueNumbers = [...new Set(numbersArray)];

  return uniqueNumbers;
};

/*
useEffect(() => {
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dangerousGoods]) {
    setDangerousGoodsObject({
      label: 'Dangerous Goods PAX',
      serviceCode: 'HE',
      dangerousGoods: [
        {
          quantity: addedServicesWatch?.dangerousGoodsQuantity || 1,
          contentId: '910',
          customDescription: `${addedServicesWatch?.dangerousGoodsQuantity
          } package DANGEROUS GOODS AS PER ASSOCIATED DGD - PAX`,
        },
      ],
    });
  } else {
    setDangerousGoodsObject(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.limitedQuantity]) {
    setLimitedQuantityObject({
      label: 'Limited Quantity',
      serviceCode: 'HL',
      dangerousGoods: [
        {
          quantity: limitedQuantity || 0,
          contentId: 'A01',
          customDescription: `${limitedQuantity} package LIMITED QUANTITIES IN ROAD ONLY`,
        },
      ],
    });
  } else {
    setLimitedQuantityObject(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity]) {
    setExceptedQuantityObject({
      label: 'Excepted Quantity',
      serviceCode: 'HH',
      dangerousGoods: [
        {
          quantity: exceptedQuantity || 0,
          contentId: 'E01',
          customDescription: `DANGEROUS GOODS IN EXCEPTED QUANTITIES, ${exceptedQuantity} package ${unNumber}`,
          unCodes: unNumber,
        },
      ],
    });
  } else {
    setExceptedQuantityObject(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce]) {
    setDryIceObject({
      label: 'Dry Ice UN 1845',
      serviceCode: 'HC',
      dangerousGoods: [
        {
          quantity: dryIce || 0,
          contentId: '901',
          dryIceTotalNetWeight: dryIceKg,
          customDescription: 'UN1845 DRY ICE, Net',
        },
      ],
    });
  } else {
    setDryIceObject(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal969]) {
    setLithiumMetal969Object({
      label: 'Lithium Metal PI1969',
      serviceCode: 'HM',
      dangerousGoods: [
        {
          quantity: lithiumMetal969 || 0,
          contentId: '969',
          customDescription: `${lithiumMetal969
          } package LITHIUM METAL BATTERIES IN COMPLIANCE WITH SECTION II OF PI 969`,
        },
      ],
    });
  } else {
    setLithiumMetal969Object(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal970]) {
    setLithiumMetal970Object({
      label: 'Lithium Metal PI1970',
      serviceCode: 'HW',
      dangerousGoods: [
        {
          quantity: lithiumMetal970 || 0,
          contentId: '970',
          customDescription: `${lithiumMetal970
          } package LITHIUM METAL BATTERIES IN COMPLIANCE WITH SECTION II OF PI 970`,
        },
      ],
    });
  } else {
    setLithiumMetal970Object(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon966]) {
    setLithiumIonl966Object({
      label: 'Lithium Ion PI1966',
      serviceCode: 'HD',
      dangerousGoods: [
        {
          quantity: lithiumIon966 || 0,
          contentId: '966',
          customDescription: `${lithiumIon966
          } package LITHIUM ION BATTERIES IN COMPLIANCE WITH SECTION II OF PI 966`,
        },
      ],
    });
  } else {
    setLithiumIonl966Object(null);
  }
  if (addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon967]) {
    setLithiumIon967Object({
      label: 'Lithium Ion PI1967',
      serviceCode: 'HV',
      dangerousGoods: [
        {
          quantity: lithiumIon967 || 0,
          contentId: '967',
          customDescription: `${lithiumIon967} package LITHIUM ION BATTERIES IN COMPLIANCE WITH SECTION II OF PI 967`,
        },
      ],
    });
  } else {
    setLithiumIon967Object(null);
  }
}, [addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dangerousGoods],
  addedServicesWatch?.dangerousGoodsQuantity, // dangerousGoodsPax
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.limitedQuantity],
  addedServicesWatch?.limitedQuantity,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.exceptedQuantity],
  addedServicesWatch?.exceptedQuantity,
  addedServicesWatch?.unCodes,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.dryIce],
  addedServicesWatch?.dryIce,
  addedServicesWatch?.dryIceKg,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal969],
  addedServicesWatch?.lithiumMetal969,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumMetal970],
  addedServicesWatch?.lithiumMetal970,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon966],
  addedServicesWatch?.lithiumIon966,
  addedServicesCheckboxesWatch?.[DHLOptionalServiceCodes.lithiumIon967],
  addedServicesWatch?.lithiumIon967,
]); */
