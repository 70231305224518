import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useOrderContextForm } from '../context';
import {
  useIntegrationsShipmentCancel,
  useIntegrationsShipmentCreate,
  useOrderAccountNumbersWithLabel,
  useOrderMarkAsDelivered,
  useOrderSendTrackingLink,
} from '../../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import { DeliveryService, OrderStatus } from '../../Adapter/enums';
import { useOrderContext } from '../../View/context';
import { DeliveryServiceType } from '../../../../../hooks/api/tracking';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import Tracking from '../../Tracking';

import styles from './index.module.scss';

const getShipmentLabelByStatus = (
  orderStatus: OrderStatus | undefined,
  deliveryService?: DeliveryService,
) => {
  switch (orderStatus) {
    case OrderStatus.DRAFT: return 'Ensure that all required fields are filled out correctly and '
      + 'that the invoice is created.';
    case OrderStatus.DECLINED: return 'The order has Declined status and cannot be sent to the delivery service.';
    case OrderStatus.TO_BE_PAID: return "The invoice hasn't been paid yet, so "
      + "it can't be sent to the delivery service.";
    case OrderStatus.EXPIRED: return 'The order has expired and cannot be sent to the delivery service.';
    case OrderStatus.READY_FOR_DELIVERY: return 'Make sure that all required fields are filled '
      + 'in correctly and order is ready to be sent to the delivery service.';
    case OrderStatus.IN_PROGRESS:
      return deliveryService === DeliveryService.OTHER
        ? 'After transferring the products to a delivery service, send the tracking link to the customer.'
        : 'Make sure that all required fields are filled '
          + 'in correctly and order is ready to be sent to the delivery service.';
    case OrderStatus.ON_THE_WAY: return 'Shipment is on their way.';
    case OrderStatus.REJECTED: return 'Make sure that all required fields are filled in '
      + 'correctly and order is ready to be sent to the delivery service.';
    case OrderStatus.DELIVERED: return 'Shipment has been delivered.';
    case OrderStatus.SHIPPED_OUT:
      return deliveryService === DeliveryService.OTHER
        ? 'Link sent successfully.'
        : 'Shipment has shipped out successfully.';
    case OrderStatus.PROCESSING: return 'Shipment created successfully.';
    case OrderStatus.PENDING: return 'Ensure that all required fields are filled '
      + 'out correctly and that the invoice is created.';

    default: return 'Make sure that all required fields are filled in correctly and order is ready to be sent to '
      + 'the delivery service.';
  }
};

function TrackingTab() {
  const { id } = useParams();
  const { open, contextHolder } = useSimpleModal();
  const { order, clientOrderData, handleClientOrderData } = useOrderContext();
  const {
    deliveryForm, orderUpdate, orderSave, orderCreate,
    initialStates: { delivery: initialState },
  } = useOrderContextForm();

  const integrationsShipmentCreate = useIntegrationsShipmentCreate(id);
  const integrationsShipmentCancel = useIntegrationsShipmentCancel(id);
  const orderSendTrackingLink = useOrderSendTrackingLink(id);
  const orderMarkAsDelivered = useOrderMarkAsDelivered(id);
  const orderAccountNumbersWithLabel = useOrderAccountNumbersWithLabel();

  const [trigger, setTrigger] = useState<number>(0);

  useMessageError([integrationsShipmentCreate, integrationsShipmentCancel], 60);
  useMessageError([orderSendTrackingLink, orderAccountNumbersWithLabel, orderMarkAsDelivered]);

  useMessageSuccess([orderSendTrackingLink], 'Tracking link sent successfully');
  useMessageSuccess([integrationsShipmentCreate], 'Shipment created successfully');
  useMessageSuccess([integrationsShipmentCancel], 'Shipment canceled successfully');
  useMessageSuccess([orderMarkAsDelivered], 'Mark as delivered successfully');

  const deliveryService: DeliveryServiceType | '' = Form.useWatch('deliveryService', deliveryForm);
  const trackingLink = Form.useWatch('trackingLink', deliveryForm);
  const trackingNumber = Form.useWatch('trackingNumber', deliveryForm);

  useEffect(() => {
    if (deliveryService) {
      orderAccountNumbersWithLabel.fetch({
        deliveryService,
      });
    }
  }, [deliveryService]);

  /** Requests which don't have updated entity in response trigger order.fetch() to update form state */
  useEffect(() => {
    if ((orderSendTrackingLink.data && !orderSendTrackingLink.error && !orderSendTrackingLink.loading)
      || (orderMarkAsDelivered.data && !orderMarkAsDelivered.error && !orderMarkAsDelivered.loading)
    ) {
      order.fetch();
    }
  }, [orderSendTrackingLink.response, orderMarkAsDelivered.response]);

  /** Requests which have updated entity in response instantly updates order-data state if there's no error */
  useEffect(() => {
    if (integrationsShipmentCreate.data && !integrationsShipmentCreate.error && !integrationsShipmentCreate.loading) {
      handleClientOrderData(integrationsShipmentCreate.data);
      integrationsShipmentCreate.clearResponse(); // Clear response state after setting it in form.
    }
    if (integrationsShipmentCancel.data && !integrationsShipmentCancel.error && !integrationsShipmentCancel.loading) {
      handleClientOrderData(integrationsShipmentCancel.data);
      integrationsShipmentCancel.clearResponse();
    }
  }, [integrationsShipmentCreate.response, integrationsShipmentCancel.response]);
  useEffect(() => {
    if (trigger
      && deliveryService
      && orderAccountNumbersWithLabel.data?.length
      && !orderAccountNumbersWithLabel.error
      && !orderAccountNumbersWithLabel.loading
    ) {
      setTrigger(0);
      integrationsShipmentCreate.fetch({
        deliveryService,
        shipmentCreation: deliveryForm.getFieldValue('shipmentCreation'),
        accountNumber: deliveryForm.getFieldValue('accountNumber') || orderAccountNumbersWithLabel.data?.[0]?.account,
        serviceType: deliveryForm.getFieldValue('serviceType'),
        serviceName: deliveryForm.getFieldValue('serviceName') || clientOrderData?.delivery?.serviceName || '',
        trackingLink: deliveryForm.getFieldValue('trackingLink') || clientOrderData?.delivery?.trackingLink || '',
        trackingNumber: deliveryForm.getFieldValue('trackingNumber') || clientOrderData?.delivery?.trackingNumber || '',
      });
    }
  }, [orderAccountNumbersWithLabel.response, trigger]);

  const getOpenTrackingLink = (deliveryServ: DeliveryService): string => {
    switch (deliveryServ) {
      case DeliveryService.DHL: {
        return `https://www.dhl.com/ua-en/home/tracking.html?tracking-id=${initialState?.trackingNumber}`;
      }
      case DeliveryService.FEDEX: {
        return `https://www.fedex.com/shippingplus/en-de/shipment/view/${
          initialState?.trackingNumber?.slice(0, 8)
        }-${
          initialState?.trackingNumber?.slice(-4)
        }-0000-0000-000000000000?context=shipments-overview`;
      }
      case DeliveryService.SCHENKER: {
        return 'https://www.dbschenker.com';
      }
      case DeliveryService.OTHER: {
        return '';
      }
      default:
        return '';
    }
  };

  /** Buttons */
  const createShipmentButton = clientOrderData?.status && [
    OrderStatus.PROCESSING,
    OrderStatus.EXPIRED,
    OrderStatus.SHIPPED_OUT,
    OrderStatus.ON_THE_WAY,
    OrderStatus.DELIVERED,
    OrderStatus.DECLINED,
    OrderStatus.TO_BE_PAID,
  ].includes(clientOrderData?.status) ? null : (
    <Button
      icon={<MailOutlined />}
      disabled={orderAccountNumbersWithLabel.loading}
      loading={integrationsShipmentCreate.loading || orderUpdate.loading || orderCreate.loading}
      type="primary"
      onClick={(e) => {
        e.preventDefault();
        orderSave(e, () => {
          setTrigger(Date.now());
        });
      }}
    >
      Create shipment
    </Button>
    );

  const handleCancelShipment = (e: React.MouseEvent<HTMLElement>) => {
    open({
      title: 'Cancel shipment',
      content: (
        <span>
          Are you sure you want to cancel this shipment?
          {' '}
          <br />
          This action cannot be undone.
        </span>
      ),
      cancelText: 'Cancel',
      okText: 'Cancel Shipment',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => orderSave(e, () => {
        integrationsShipmentCancel.fetch();
      }),
    });
  };

  const cancelShipmentButton = clientOrderData?.status && [
    OrderStatus.PROCESSING,
  ].includes(clientOrderData?.status) ? (
    <Button
      danger
      type="primary"
      onClick={handleCancelShipment}
      loading={integrationsShipmentCreate.loading || integrationsShipmentCancel.loading
        || orderUpdate.loading || orderCreate.loading}
      disabled={false}
    >
      Cancel shipment
    </Button>
    ) : null;

  return (
    <div className={styles.tracking}>
      {contextHolder}
      <Tracking
        title="Shipment tracking"
        trackingNumber={initialState?.trackingNumber} // {trackingNumber}
        deliveryService={deliveryService}
        trackingLink={trackingLink}
        openTrackingSiteLinks={getOpenTrackingLink(deliveryService as DeliveryService)}
        className={styles.trackingShipment}
      />

      <div className={styles.shipmentManagement}>
        <h2>Shipment management</h2>
        <div className="text-secondary" style={{ marginBottom: '12px' }}>
          {getShipmentLabelByStatus(clientOrderData?.status, deliveryService as DeliveryService)}
        </div>

        <div>
          {deliveryService?.toLowerCase() === DeliveryService.FEDEX ? (
            <>
              {createShipmentButton}
              {cancelShipmentButton}
            </>
          ) : null}
          {deliveryService?.toLowerCase() === DeliveryService.SCHENKER ? (
            <>
              {createShipmentButton}
              {cancelShipmentButton}
            </>
          ) : null}
          {deliveryService?.toLowerCase() === DeliveryService.DHL ? (
            <>
              {createShipmentButton}
              {cancelShipmentButton}
            </>
          ) : null}

          {deliveryService?.toLowerCase() === DeliveryService.OTHER ? (
            <>
              {clientOrderData?.status !== 'Delivered' ? (
                <Button
                  icon={<MailOutlined />}
                  loading={orderSendTrackingLink.loading}
                  type="default"
                  onClick={(e) => {
                    e.preventDefault();
                    orderSave(e, () => {
                      orderSendTrackingLink.fetch();
                    });
                  }}
                >
                  {clientOrderData?.shippedDate && clientOrderData?.status === 'Shipped Out' ? (
                    'Resend Tracking link'
                  ) : (
                    'Send Tracking link'
                  )}
                </Button>
              ) : null}

              {clientOrderData?.shippedDate && clientOrderData?.status === 'Shipped Out' ? (
                <Button
                  style={{ marginTop: '8px' }}
                  loading={orderMarkAsDelivered.loading}
                  type="default"
                  onClick={(e) => {
                    e.preventDefault();
                    orderMarkAsDelivered.fetch();
                  }}
                >
                  Mark as delivered
                </Button>
              ) : null}

              {trackingNumber && trackingLink ? (
                <div style={{ marginTop: '12px' }}>
                  If you want to cancel this shipment, please do so in your personal account on the
                  {' '}
                  <a
                    href={trackingLink}
                    // href={`${trackingLink.toLowerCase().includes('https:')
                    //   ? `${trackingLink}${trackingNumber}`
                    //   : `https://${trackingLink}`}${trackingNumber}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    delivery service website
                  </a>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TrackingTab;
