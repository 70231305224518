import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import Menu from '../../../../components/Layout/Menu';
import DefaultServicesEditContent, { HeaderActions } from '../../../../components/Pages/Services/DefaultServices/Edit';
import ServicesProvider from '../../../../components/Pages/Services/DefaultServices/Form/context';

const { displayName } = config;

export default function DefaultServiceEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit default services`;

  return (
    <ServicesProvider>
      <Layout
        title="Edit default services"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <DefaultServicesEditContent />
      </Layout>
    </ServicesProvider>
  );
}
