import React from 'react';
import { config } from '@triare/auth-redux';
import ParticipantProvider from '../../../../components/Pages/AddressBook/Form/context';
import Layout from '../../../../components/Layout';
import Menu from '../../../../components/Layout/Menu';
import ParticipantEditContent, { HeaderActions } from '../../../../components/Pages/AddressBook/View/Edit';

const { displayName } = config;

export default function ParticipantEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit address`;

  return (
    <ParticipantProvider>
      <Layout
        title="Edit address"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ParticipantEditContent />
      </Layout>
    </ParticipantProvider>
  );
}
