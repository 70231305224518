import React, { CSSProperties } from 'react';
import {
  DatePicker,
  Form, FormInstance, Input, InputProps,
} from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { AnyObject } from '@triare/auth-redux';
import dayjs from 'dayjs';

import styles from '../../index.module.scss';
import { dateFormat } from '../../../../../../contstant';

interface InputPermitNumberAndDateProps extends FormItemProps {
  prefix?: string;
  rules?: Rule[];
  label: string;
  name: [NamePath<string>, NamePath<string>];
  style?: CSSProperties;
  children?: React.ReactNode;
  inputProps?: InputProps;
  rightText?: string;
  rest?: AnyObject;
  form: FormInstance;
}

function InputPermitNumberAndDate({
  form,
  prefix,
  inputProps = {},
  rest = {},
  name,
  children,
  rightText,
  ...props
}: InputPermitNumberAndDateProps) {
  return (
    <Form.Item
      {...props}
      {...rest}
      name={name[0]}
      className={props.className}
      initialValue=""
      extra={(
        <>
          <Form.Item
            name={name[1]}
            initialValue="" // dayjs().utc()
          >
            <DatePicker format={dateFormat} defaultValue="" />
          </Form.Item>

          {children}
          <div className={styles.text}>
            {rightText}
          </div>
        </>
      )}
    >
      <Input
        {...inputProps}
      />
    </Form.Item>
  );
}

export default InputPermitNumberAndDate;
