import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import OrderViewContent, { HeaderActions } from '../../../components/Pages/Orders/View';
import Menu from '../../../components/Layout/Menu';
import OrderProvider, { useOrderContext } from '../../../components/Pages/Orders/View/context';
import OrderProviderForm from '../../../components/Pages/Orders/Form/context';

const { displayName } = config;

export default function OrderView(): React.ReactNode | null {
  const { order } = useOrderContext();

  document.title = `${displayName}: View order${order?.data ? ` №${order.data?.id}` : ''}`;

  return (
    <OrderProvider mode="view">
      <OrderProviderForm>
        <Layout
          title={`Order${order?.data ? ` №${order.data?.id}` : ''}`}
          leftMenu={<Menu />}
          headerContent={<HeaderActions />}
        >
          <OrderViewContent />
        </Layout>
      </OrderProviderForm>
    </OrderProvider>
  );
}
