import React, { ReactElement } from 'react';
import { useAuth, User } from '../store/auth';
import SignIn from '../pages/Auth/SignIn';
import { Route, RouteElements, RouteRoles } from './index';
import NotFound from '../pages/NotFound';
import CreateCompanyProfile from '../pages/Companies/CreateProfile';
import Simple from '../components/Layout/Simple';

function LogoScreen() {
  return (
    <Simple>
      <div style={{ minWidth: '90vw', textAlign: 'center' }}>
        <img
          src="/logo.png"
          alt="Logo"
          style={{
            margin: '24px auto',
            width: '300px',
          }}
        />
      </div>
    </Simple>
  );
}

export function doesUserHaveAccess(roles?: RouteRoles, user?: User): boolean {
  if (roles && roles.length > 0 && user) {
    const roleListString = roles.filter((role) => typeof role === 'string') as string[];

    if (roleListString.length > 0 && roleListString.includes(user?.role)) {
      return true;
    }

    const roleListFn = roles.filter((role) => typeof role === 'function') as ((user: User) => boolean)[];

    if (roleListFn.length > 0 && roleListFn.some((role) => role(user))) {
      return true;
    }

    return false;
  }

  return true;
}

interface PrivateRouteProps {
  children?: ReactElement | React.ReactNode

  item?: Route

  element?: React.ReactNode

  elements?: RouteElements
}

function PrivateRoute({
  children, item, element, elements,
}: PrivateRouteProps): React.ReactNode | null | undefined {
  const { authorized, user } = useAuth();

  /** If user is company attached user - then don't let they visit any pages until they fill in their profile.
   * If user has company, but his company profile not completed, then let him finish profile with prefilled form. */
  if (user?.role === 'user' && (!user?.company?.id || !user?.isCompanyAccepted)) {
    return <CreateCompanyProfile />;
  }

  if (user && item && item.roles) {
    let isBlocked = false;
    let roles;
    let route = item;

    while (route.roles) {
      roles = route.roles;

      if (!doesUserHaveAccess(roles, user)) {
        isBlocked = true;
        break;
      } else if (route.parent) {
        route = route.parent;
      } else {
        break;
      }
    }

    if (isBlocked) {
      return <NotFound />;
    }
  }

  if (!authorized) {
    return <SignIn />;
  }

  /** If user authorized and still have no role - show splash screen, until request 'user/me' finished */
  if (!user?.role) {
    return (
      <LogoScreen />
    );
  }

  if (elements && user) {
    if (elements[user.role]) {
      return elements[user.role];
    }

    if (elements['*']) {
      return elements['*'];
    }

    return <NotFound />;
  }

  if (element) {
    return element;
  }

  return children;
}

export default PrivateRoute;
