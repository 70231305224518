import {
  DefaultFetchError, FetchCreate, FetchUpdate, useFetchCreate, useFetchPut,
} from '../fetch';

export interface AttachmentItem {
  fileName: string; // 'label-2191728313.pdf'
  shouldBeSent: boolean;
}

export interface EmailsSettings<ListType = string> {
  isAutomaticSending: boolean;
  toCompany: boolean;
  toShipper: boolean;
  toConsignee: boolean;
  additionalList: ListType;
  attachments: AttachmentItem[];
  customSettings: EmailSettingsCustomPayload<ListType>;
}

export interface EmailSettingsCustomPayload<ListType = string> {
  body: string;
  subject: string;
  toCompany: boolean;
  toShipper: boolean;
  toConsignee: boolean;
  attachments: AttachmentItem[];
  additionalList: ListType;
}

export const useOrderEmailsPutData = <Payload = EmailsSettings>(id?: string): FetchUpdate<
  EmailsSettings, DefaultFetchError, Payload
> => (
    useFetchPut(`orders${id ? `/${id}/email-settings` : ''}`, '')
  );

export const useOrderEmailsSendEmail = (id: string): FetchCreate<
  EmailsSettings, DefaultFetchError, EmailSettingsCustomPayload | undefined> => (
  useFetchCreate(`orders/${id}/send-shipped-out-email`)
);
