/* eslint-disable no-param-reassign */

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import listSaga from './list';
import { BaseState, createCRUDModel, createCRUDStates } from '../creator';
import { RootState } from '../index';

export const moduleName = 'user';

export interface State {
  list: BaseState,
  byId: BaseState,
  create: BaseState,
  update: BaseState,
  remove: BaseState
}

const models = ['list', 'byId', 'create', 'update', 'remove'];

export const defaultState: State = createCRUDStates<State>(models);

export const user = createSlice({
  name: moduleName,
  initialState: defaultState,
  reducers: createCRUDModel<State>(models, moduleName),
});

export const { reducer, actions } = user;

export function useUser(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export function* saga() {
  yield listSaga();
}
