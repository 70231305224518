import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox, Form,
  Input, message, Select, Switch, Tabs, TabsProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useParams } from 'react-router-dom';
import { useOrderContextForm } from '../context';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { ClientDataImporter, ClientDataShipper } from '../../Adapter';
import { useOrderContext } from '../../View/context';
import {
  AttachmentItem, EmailSettingsCustomPayload,
  EmailsSettings,
  useOrderEmailsPutData,
  useOrderEmailsSendEmail,
} from '../../../../../hooks/api/orderEmails';
import { asyncDelay, isObjEqual } from '../../../../../utils';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';

import styles from '../index.module.scss';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

interface TabItemFields {
  isAutomaticSending?: boolean; // only for normal
  toCompany: boolean;
  toShipper: boolean;
  toConsignee: boolean;
  additionalList: string[];
  attachments: EmailsSettings['attachments'];
  subject?: string; // only for custom
  body?: string; // only for custom
}

const initialSettingItem: TabItemFields = {
  // isAutomaticSending: false,
  toCompany: true,
  toShipper: false,
  toConsignee: false,
  additionalList: [],
  attachments: [],
};

const initialState: EmailsSettings<string[]> = {
  isAutomaticSending: !!initialSettingItem?.isAutomaticSending,
  ...initialSettingItem,
  customSettings: {
    ...initialSettingItem,
    subject: '',
    body: '',
  },
};

function EmailContent({ prefix, activeTab }: { prefix?: string, activeTab?: 'shipped_out' | 'custom' }) {
  const form = useFormInstance();
  const { clientOrderData } = useOrderContext();
  const { shipperForm, consigneeForm } = useOrderContextForm();

  const attachmentsWatch: AttachmentItem[] = Form.useWatch([...(prefix ? [prefix] : []), 'attachments'], form) || [];

  const shipperEmailWatch: ClientDataShipper = Form.useWatch('email', shipperForm);
  const consigneeEmailWatch: ClientDataImporter = Form.useWatch('email', consigneeForm);

  const checkboxesRecipients = useMemo(() => ([
    { name: 'toCompany', title: 'Client', value: clientOrderData?.company?.email || '' },
    { name: 'toShipper', title: 'Shipper', value: shipperEmailWatch || '' },
    { name: 'toConsignee', title: 'Consignee', value: consigneeEmailWatch || '' },
  ]), [clientOrderData, shipperEmailWatch, consigneeEmailWatch]);

  return (
    <>
      {!prefix ? (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Form.Item name={[...(prefix ? [prefix] : []), 'isAutomaticSending']} style={{ margin: '0' }}>
            <Switch />
          </Form.Item>
          <div style={{ paddingTop: '5px' }}>Automatic sending</div>
        </div>
      ) : null}
      <h3 className={styles.emailsTitle}>Recipients</h3>
      {checkboxesRecipients.map((item) => (
        <Form.Item
          name={[...(prefix ? [prefix] : []), item.name]}
          key={item.name}
          valuePropName="checked"
          style={{ marginBottom: '0' }}
        >
          <Checkbox>
            {`${item.title}: ${item.value}`}
          </Checkbox>
        </Form.Item>
      ))}

      <div style={{ margin: '8px 0' }}>
        Additional recipients
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> (optional)</span>
      </div>
      <Form.Item
        name={[...(prefix ? [prefix] : []), 'additionalList']}
        style={{ marginBottom: '5px' }}
      >
        <Select
          mode="tags"
          style={{ width: '100%' }}
          showSearch={false}
          suffixIcon={null}
          tokenSeparators={[',']}
          onSelect={(value) => {
            if (!emailRegex.test(value)) {
              message.error('The item you entered is not a valid email address. Please enter a valid email.');

              // Remove the invalid entry if needed:
              const currentValues = form.getFieldValue([...(prefix ? [prefix] : []), 'additionalList']) || [];

              form.setFieldValue(
                [...(prefix ? [prefix] : []), 'additionalList'],
                currentValues.filter((item: string) => item !== value),
              );
            }
          }}
        />
      </Form.Item>
      <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Add emails in list.</span>
      {prefix
        ? (
          <>
            <h3 className={styles.emailsTitle}>Message</h3>
            {/* <span>Subject</span> */}
            <Form.Item
              label="Subject"
              name={[...(prefix ? [prefix] : []), 'subject']}
              rules={activeTab === 'custom' ? [{ required: true }] : undefined}
            >
              <Input maxLength={300} />
            </Form.Item>
            {/* <span>Body text</span> */}
            <Form.Item
              label="Body text"
              name={[...(prefix ? [prefix] : []), 'body']}
              rules={activeTab === 'custom' ? [{ required: true }] : undefined}
            >
              <TextArea rows={8} maxLength={2000} />
            </Form.Item>
          </>
        )
        : null}
      <h3 className={styles.emailsTitle}>Attachments</h3>
      <Form.List name={[...(prefix ? [prefix] : []), 'attachments']}>
        {(fields) => (
          <div>
            {fields.map((field) => {
              const foundDocument = (attachmentsWatch || [])[field.name];

              return (
                <Form.Item
                  name={[field.name, 'shouldBeSent']}
                  key={field.name}
                  valuePropName="checked"
                  style={{ marginBottom: '0' }}
                >
                  <Checkbox style={{ color: COLOR_PRIMARY }}>
                    {foundDocument?.fileName || 'attachment'}
                  </Checkbox>
                </Form.Item>
              );
            })}
          </div>
        )}
      </Form.List>
    </>
  );
}

export default function Emails() {
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const { order, clientOrderData } = useOrderContext();
  const {
    isEmailsChanged, setIsEmailsChanged, isValid, orderSave, triggerValidationAllForm,
  } = useOrderContextForm();
  const orderEmailsPutData = useOrderEmailsPutData(id);
  const orderEmailsSendEmail = useOrderEmailsSendEmail(id);

  const [activeTab, setActiveTab] = useState<'shipped_out' | 'custom'>('shipped_out');
  const tabs: TabsProps['items'] = useMemo(() => (
    [
      {
        forceRender: true,
        key: 'shipped_out',
        label: 'Shipped out',
        children: (<EmailContent activeTab={activeTab} />),
      },
      {
        forceRender: true,
        key: 'custom',
        label: 'Custom',
        children: (<EmailContent activeTab={activeTab} prefix="customSettings" />),
      },
    ]
  ), [activeTab]);

  const [initialValues, setInitialValues] = useState<EmailsSettings<string[]>>(initialState);
  const prepareInitialValues = (emailSettings: EmailsSettings): EmailsSettings<string[]> => {
    const newValues = emailSettings || initialState;

    return ({
      ...newValues,
      additionalList: emailSettings?.additionalList ? emailSettings?.additionalList?.split(',') : [],
      customSettings: {
        ...newValues?.customSettings,
        // If there no custom settings attachments yet, take they from normal list.
        attachments: emailSettings?.customSettings?.attachments?.length
          ? emailSettings?.customSettings?.attachments : emailSettings.attachments || [],
        additionalList: emailSettings?.customSettings?.additionalList
          ? emailSettings?.customSettings?.additionalList?.split(',') : [],
      },
    });
  };

  useEffect(() => {
    if (clientOrderData?.emailSettings) {
      setInitialValues(prepareInitialValues(clientOrderData?.emailSettings));
      setTrigger((prevState) => prevState + 1);
    }
  }, [clientOrderData]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = async () => {
    const values: EmailsSettings<string[]> = form.getFieldsValue();

    // await form.validateFields().then((values: EmailsSettings<string[]>) => {
    const newValues: EmailsSettings = {
      ...values,
      additionalList: values?.additionalList?.join(',') || '',
      customSettings: {
        ...values.customSettings,
        additionalList: values.customSettings?.additionalList?.join(',') || '',
      },
    };

    orderEmailsPutData.fetch(newValues);
    // });
  };

  useEffect(() => {
    if (orderEmailsPutData.response?.status === 204 && !orderEmailsSendEmail.error) {
      orderEmailsPutData.clearResponse();
      orderEmailsPutData.clearError();
      order.fetch();
      setIsEmailsChanged(true);
    }
  }, [orderEmailsPutData.response]);

  const handleSendEmail = async () => {
    const values = form.getFieldsValue();

    // Validations
    let hasRecipientChecked = false;
    let hasAdditionalRecipients = false;

    const recipientsKeys = ['toCompany', 'toShipper', 'toConsignee'];

    if (activeTab === 'shipped_out') {
      hasRecipientChecked = recipientsKeys.some((item) => form.getFieldValue([item]));
      hasAdditionalRecipients = values?.additionalList?.length > 0;
    } else {
      hasRecipientChecked = recipientsKeys.some((item) => form.getFieldValue(['customSettings', item]));
      hasAdditionalRecipients = values?.customSettings?.additionalList?.length > 0;
    }

    // If no recipient is selected and additionalList is empty, show an error and prevent sending
    if (!isValid || (!hasRecipientChecked && !hasAdditionalRecipients)) {
      message.error('Please select at least one recipient or add an additional recipient.');

      return;
    }

    // Sending
    if (isEmailsChanged && activeTab === 'shipped_out') {
      await handleSubmit();
      await asyncDelay(150);
    }

    if (activeTab === 'shipped_out') {
      orderEmailsSendEmail.fetch();
    } else {
      // const values = form.getFieldsValue();
      const newData: EmailSettingsCustomPayload = {
        ...values.customSettings,
        additionalList: values.customSettings?.additionalList.join(','),
      };

      form.validateFields([['customSettings', 'subject'], ['customSettings', 'body']]).then(() => (
        orderEmailsSendEmail.fetch(newData)
      )).catch((_error) => console.log(_error));
    }
  };

  useMessageError([orderEmailsPutData, orderEmailsSendEmail]);
  useMessageSuccess([orderEmailsSendEmail], 'Email sent successfully');

  /** Handle fields change to show unsaved changes popup */
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isChanged = !isObjEqual(form.getFieldsValue(), initialValues);

      setIsEmailsChanged(isChanged);
    }, 400);

    return () => clearTimeout(timeout);
  }, [trigger]);

  const actionValidate = async (action: () => void) => {
    const newIsValid = triggerValidationAllForm();

    if (newIsValid) {
      action();
    } else {
      orderSave();
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      onFieldsChange={() => { setTrigger((prevState) => prevState + 1); }}
      className="no-padding-form-item"
    >

      <Tabs
        key="emailsTabs"
        type="card"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as 'shipped_out' | 'custom')}
        defaultActiveKey="shipped_out"
        items={tabs}
      />

      <div style={{ marginTop: '18px' }}>
        <div>
          {activeTab === 'shipped_out' ? (
            <Button
              type="primary"
              style={{ marginRight: '10px' }}
              onClick={() => {
                actionValidate(() => form.submit());
              }}
              loading={orderEmailsPutData.loading || orderEmailsSendEmail.loading}
            >
              Save Emails settings
            </Button>
          ) : null}
          <Button
            type="default"
            onClick={() => {
              actionValidate(handleSendEmail);
            }}
            loading={orderEmailsPutData.loading || orderEmailsSendEmail.loading}
          >
            Send emails
          </Button>
        </div>
      </div>
    </Form>
  );
}
