import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { type NamePath, Rule } from 'rc-field-form/lib/interface';
import { Country, useCountryGet } from '../../../hooks/api/country';
import { Option } from '../../../types';
import { useMessageError } from '../../../hooks/common';

interface SelectCountryProps {
  rules?: Rule[];
  label: string;
  name: NamePath<string>;
  children?: React.ReactNode;
  required?: boolean;
  valueKey?: 'name' | 'code';
}

function SelectCountry({
  label, rules, name, required, children, valueKey = 'name',
}: SelectCountryProps) {
  const countryGet = useCountryGet();
  const [countryOptions, setCountryOptions] = useState<Option[]>([]);

  useMessageError([countryGet]);

  useEffect(() => {
    if (countryGet.data) {
      setCountryOptions(countryGet.data.map((country: Country): Option => (
        { label: country.name, value: country[valueKey] }
      )));
    }
  }, [countryGet.data]);

  return (
    <Form.Item
      label={label}
      required={required}
      name={name}
      rules={rules}
      extra={children}
    >
      <Select
        showSearch
        loading={countryGet.loading}
        options={countryOptions}
        optionFilterProp="label"
      />
    </Form.Item>
  );
}

export default SelectCountry;
