import { CloseCircleFilled } from '@ant-design/icons';
import { MessageInstance } from 'antd/es/message/interface';
import { ClientDataGood, ClientDataPackages } from '../../../Adapter';

/** Check is all required for shipment fields filled */
const isValidForShipmentForm = async ({
  goodsValues, packagesValues, message,
}: {
  goodsValues: ClientDataGood[],
  packagesValues: ClientDataPackages[],
  message: MessageInstance,
}): Promise<boolean> => {
  const requiredPackageFields: (keyof ClientDataPackages)[] = [
    'weight', 'quantity', 'length', 'width', 'height', 'value',
  ];

  const requiredGoodsFields: (keyof ClientDataGood)[] = [
    'gross', 'quantity', 'length', 'width', 'height', 'value',
  ];

  const listError: { tab: string; list: string[] }[] = [];

  /** Validate packages if any exist, if not - check goods. */
  if (packagesValues.length > 0) {
    const packageErrors: string[] = [];

    packagesValues.forEach((packageItem, index) => {
      requiredPackageFields.forEach((field) => {
        // if (packageItem[field] === undefined || packageItem[field] === null) {
        if (!packageItem[field]) {
          packageErrors.push(`${index + 1} > ${field}`);
        }
      });
    });

    if (packageErrors.length > 0) {
      listError.push({ tab: 'Packages', list: packageErrors });
    }
  } else {
    const goodsErrors: string[] = [];

    goodsValues.forEach((goodItem, index) => {
      requiredGoodsFields.forEach((field) => {
        if (!goodItem[field]) {
          goodsErrors.push(`${index + 1} > ${field}`);
        }
      });
    });

    if (goodsErrors.length > 0) {
      listError.push({ tab: 'Goods', list: goodsErrors });
    }
  }

  if (listError.length > 0) {
    const key = crypto.randomUUID();

    message.open({
      key,
      type: 'error',
      content: (
        <>
          <span>
            Please fill in all required fields
          </span>
          <div
            style={{
              textAlign: 'left',
              maxHeight: '90vh',
              overflow: 'auto',
              margin: '8px 0',
            }}
          >
            {listError.map(({ tab, list }, i) => (
              <div key={tab} style={{ marginTop: 8, marginBottom: i === (listError.length - 1) ? 0 : 12 }}>
                <span style={{ fontWeight: 'bold' }}>
                  {tab}
                  :
                </span>
                {list.map((err) => (
                  <div key={err} style={{ marginLeft: 16 }}>{err}</div>
                ))}
              </div>
            ))}
          </div>
        </>
      ),
      duration: 60,
      icon: <CloseCircleFilled onClick={() => message.destroy(key)} />,
    });

    throw new Error('Validation failed');
  }

  return true; // Validation passed
};
/** validation setup */

export default isValidForShipmentForm;
