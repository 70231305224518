import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { timeFormat } from '../../../contstant';

export type NoUndefinedRangeValueType<DateType> = [start: DateType | null, end: DateType | null];

const disabledStartHours = () => {
  const disabledHoursArr = [];

  for (let i = 0; i < 24; i++) {
    if (i < 7 || i > 19) {
      disabledHoursArr.push(i);
    }
  }

  return disabledHoursArr;
};
const disabledStartMinutes = (selectedHour: number) => {
  if (selectedHour === 19) {
    return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute !== 0);
  }

  return [];
};

function RangePickerTime({ ...rest }: RangePickerProps) {
  const [startTime, setStartTime] = useState<Dayjs | null>(null);

  const handleCalendarChange = (dates: NoUndefinedRangeValueType<Dayjs> | null) => {
    if (dates && dates[0]) {
      setStartTime(dates[0]);
    } else {
      setStartTime(null);
    }
  };

  const disabledHours = () => {
    const newStartTime = startTime || dayjs();

    const disabledHoursArr = [];
    const startHour = newStartTime.hour();

    for (let i = 0; i < Math.min(startHour + 2, 23); i++) {
      disabledHoursArr.push(i);
    }

    return disabledHoursArr;
  };

  const disabledMinutes = (selectedHour: number) => {
    if (!startTime) {
      return [];
    }

    const newStartTime = startTime.clone();
    const startHour = newStartTime.hour();
    const startMinute = newStartTime.minute();

    /** Always allow to select 23:59. */
    if (startHour === 22 || startHour === 23) {
      return Array.from({ length: 60 }, (_, i) => i)
        .filter((item) => item !== 59);
    }

    /** If the selected hour is exactly 2 hours after the start hour, disable minutes less than the start minute */
    if (selectedHour === startHour + 2) {
      return Array.from({ length: startMinute }, (_, i) => i);
    }

    /** Disable all minutes if the selected hour is within the 2-hour gap */
    if (selectedHour < startHour + 2) {
      return Array.from({ length: 60 }, (_, i) => i);
    }

    return [];
  };

  return (
    <DatePicker.RangePicker
      picker="time"
      format={timeFormat}
      needConfirm={false}
      onCalendarChange={handleCalendarChange}
      // onChange={(dates, dateStrings) => {
      //   if (onChange) { onChange(dates, dateStrings); }
      // }}
      // defaultValue={[dayjs(), dayjs().add(2, 'hour')]}
      disabledTime={(_, type) => {
        if (type === 'start') {
          return {
            disabledHours: disabledStartHours,
            disabledMinutes: disabledStartMinutes,
          };
        }
        if (type === 'end' && startTime) {
          return {
            disabledHours,
            disabledMinutes,
          };
        }

        return {};
      }}
      {...rest}
    />
  );
}

export default RangePickerTime;
