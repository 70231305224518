import React, { CSSProperties } from 'react';
import {
  Select, Form, SelectProps, FormInstance,
} from 'antd';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { FormItemProps } from 'antd/es/form/FormItem';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { AnyObject } from '@triare/auth-redux';
import { Option } from '../../../../../../types';

import styles from '../../index.module.scss';

interface SelectPlaceProps extends FormItemProps {
  triggerValidationAllForm?: () => void;
  addressName?: string;
  form: FormInstance;
  prefix?: string;
  rules?: Rule[];
  label?: string;
  name: NamePath<string>;
  style?: CSSProperties;
  children?: React.ReactNode;
  rightText?: string | false;
  extra?: boolean;
  rest?: AnyObject;
  options?: Option[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectProps?: SelectProps<any, any>;
  type: string[];
  value?: string;
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  onSelect?: (value: string | undefined) => void;
  disabled?: boolean;
}

function SelectPlace({
  triggerValidationAllForm,
  addressName = 'address1',
  form,
  type,
  selectProps = {},
  rest = {},
  children,
  extra = true,
  rightText,
  onSelect,
  onChange,
  disabled,
  ...props
}: SelectPlaceProps) {
  const values = form.getFieldsValue();

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: 'AIzaSyC9gv7c0h8fSS_eey9MGWZIX6OoLgaQvKA', // TODO make this api key private. move to env.
    options: { types: type },
  });

  return (
    <Form.Item
      {...rest}
      {...props}
      extra={extra ? (
        <>
          {children}
          {rightText === false ? null : (
            <div className={styles.text}>
              {rightText}
            </div>
          )}
        </>
      ) : undefined}
    >
      <Select
        allowClear
        showSearch
        disabled={disabled}
        onSearch={(val) => {
          if (val.trim().length > 0) {
            form.setFieldValue(props.name, val);
            form.validateFields();
          }

          const cityRegex = new RegExp(values.city, 'i');
          const countryRegex = new RegExp(values.country, 'i');

          if (Array.isArray(props.name) ? (props.name[1] === addressName) : (props.name === addressName)) {
            if (values.city && !cityRegex.test(val)) {
              getPlacePredictions({ input: `${values.city} ${val}` });

              return;
            }
            if (values.country && !countryRegex.test(val)) {
              getPlacePredictions({
                input: `${values.country} ${val}`,
              });

              return;
            }
          }

          getPlacePredictions({ input: `${val}` });
        }}
        filterOption={false}
        onSelect={(val) => {
          if (onSelect) { onSelect(val); }

          const placeIndex = placePredictions.findIndex(
            (item) => item.description === val,
          );

          if (placePredictions[placeIndex] && placePredictions[placeIndex].place_id) {
            placesService?.getDetails(
              { placeId: placePredictions[placeIndex].place_id },
              (placeDetails: any) => {
                placeDetails.address_components.forEach((item: any) => {
                  if (item.types.includes('country')) {
                    if (Array.isArray(props.name)) {
                      form.setFieldValue([props.name[0], 'country'], item.long_name);
                      form.setFieldValue([props.name[0], 'countryCode'], item.short_name);
                    } else {
                      form.setFieldValue('country', item.long_name);
                      form.setFieldValue('countryCode', item.short_name);
                    }
                  }
                  if (item.types.includes('administrative_area_level_1')) {
                    if (Array.isArray(props.name)) {
                      form.setFieldValue([props.name[0], 'state'], item.long_name);
                      form.setFieldValue([props.name[0], 'stateCode'], item.short_name);
                    } else {
                      form.setFieldValue('state', item.long_name);
                      form.setFieldValue('stateCode', item.short_name);
                    }
                  }
                  if (item.types.includes('locality')) {
                    if (Array.isArray(props.name)) {
                      form.setFieldValue([props.name[0], 'city'], item.long_name);
                    } else {
                      form.setFieldValue('city', item.long_name);
                    }
                  }
                  if (item.types.includes('postal_code')) {
                    if (Array.isArray(props.name)) {
                      form.setFieldValue([props.name[0], 'postalCode'], item.long_name);
                    } else {
                      form.setFieldValue('postalCode', item.long_name);
                    }
                  }
                  if (item.types.includes('route')) {
                    if (Array.isArray(props.name)) {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      form.setFieldValue([props.name[0], addressName], item.long_name);
                    } else {
                      form.setFieldValue(addressName, item.long_name);
                    }
                  }

                  const route = placeDetails.address_components.find(
                    (place: AnyObject) => place.types.includes('route'),
                  );
                  const streat = placeDetails.address_components.find(
                    (place: AnyObject) => place.types.includes('street_number'),
                  );

                  if (route && streat) {
                    if (Array.isArray(props.name)) {
                      form.setFieldValue([props.name[0], addressName], `${route.long_name} ${streat.long_name}`);
                    } else {
                      form.setFieldValue(addressName, `${route.long_name} ${streat.long_name}`);
                    }
                  }

                  if (triggerValidationAllForm) {
                    triggerValidationAllForm();
                  }
                  setTimeout(() => form.validateFields(), 250);
                });
              },
            );
          }
        }}
        options={
          placePredictions.map((item) => ({
            value: item.description,
            label: item.description,
          })) || []
        }
        {...selectProps}
      />
    </Form.Item>
  );
}

export default SelectPlace;
