import React from 'react';
import clsx from 'clsx';
import { Spin, Tabs } from 'antd';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import CompanyProvider from '../../Companies/Form/context';
import { useCustomerAccountContext } from './customerContext';
import CustomerAccountView from './View';
import CustomerEdit from './View/Edit';
import AccountManagement from './Form/AccountManagement';
import TermsOfService from './Form/TermsOfService';
import PrivacyPolicy from './Form/PrivacyPolicy';
import Support from './Form/Support';

const tabs = [
  {
    forceRender: true,
    key: 'Company Profile',
    label: 'Company Profile',
    children: <CustomerEdit />, // TODO change to CustomerAccountView when view ready
  },
  {
    forceRender: true,
    key: 'Account Management',
    label: 'Account Management',
    children: <AccountManagement />,
  },
  {
    forceRender: true,
    key: 'Terms of Service',
    label: 'Terms of Service',
    children: <TermsOfService />,
  },
  {
    forceRender: true,
    key: 'Privacy Policy',
    label: 'Privacy Policy',
    children: <PrivacyPolicy />,
  },
  {
    forceRender: true,
    key: 'Support',
    label: 'Support',
    children: <Support />,
  },
];

function CustomerAccount() {
  const [_, setSearchParams, params] = useSearchParams();
  const { companyClient } = useCustomerAccountContext();

  return (
    <CompanyProvider>
      <div
        className={clsx('form-customized-cols', 'miniTopPadding', 'content-container', 'order-form-rewrites')}
      >
        <Tabs
          key="customerTabs"
          defaultActiveKey={(params.tab as string) || 'Company Profile'}
          activeKey={(params.tab as string) || 'Company Profile'}
          items={tabs}
          onChange={(key) => setSearchParams({ tab: key })}
        />

        {companyClient.loading ? (
          <div className="spin-loading">
            <Spin />
          </div>
        ) : null}
      </div>
    </CompanyProvider>
  );
}

export default CustomerAccount;
