import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import {
  NavLink, useNavigate, useParams,
} from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useBlockerContext } from '../../../../../context/blockerDisabler';
import { useServicesContext } from '../../Form/context';
import { useServicesCreate, useServicesDelete, useServicesUpdate } from '../../../../../hooks/api/services';
import ServicesForm from '../../Form';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    form, isValid, setInitialState,
  } = useServicesContext();
  const serviceCopy = useServicesCreate();
  const serviceUpdate = useServicesUpdate(id);
  const serviceDelete = useServicesDelete(id || 'undefined');

  const { handleIsBlockerActive } = useBlockerContext();

  useMessageError([serviceUpdate, serviceDelete, serviceCopy]);
  useMessageSuccess([serviceUpdate], 'Changes saved successfully');
  useMessageSuccess([serviceCopy], 'Copy saved successfully');
  useMessageSuccess([serviceDelete], 'Service deleted successfully');

  useEffect(() => {
    if (!serviceCopy.error && !serviceCopy.loading && serviceCopy.data) {
      navigate(`/services/${id}`);
    }
  }, [serviceCopy.data]);

  useEffect(() => {
    if (!serviceUpdate.error && !serviceUpdate.loading && serviceUpdate.data) {
      navigate(`/services/${id}`);
    }
  }, [serviceUpdate.error, serviceUpdate.loading, serviceUpdate.data]);

  useEffect(() => {
    if (!serviceDelete.error && !serviceDelete.loading && serviceDelete.data) {
      /** After delete disable blocker, and after navigation - reset to default state */
      handleIsBlockerActive(false)
        .then(() => {
          navigate('/services');
        }).then(() => handleIsBlockerActive(null));
    }
  }, [serviceDelete.error, serviceDelete.loading, serviceDelete.data]);

  return (
    <Actions>
      <Button
        danger
        loading={serviceDelete.loading}
        onClick={(e) => {
          e.preventDefault();

          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete service?',
            content: (
              <span>
                Are you sure you want to delete service
                {' '}
                <b>{form?.getFieldValue('name')}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => serviceDelete.fetch(),
          });
        }}
      >
        Delete
      </Button>
      {/* {getUserRequestPath(user?.role) === 'admin' && (
      <Button
        loading={serviceCopy.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            console.log(data);
            setInitialState(data);
            serviceCopy.fetch(data);
          }
        }}
      >
        Make a copy
      </Button>
      )} */}
      <Button
        type="primary"
        disabled={!isValid}
        loading={serviceUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            serviceUpdate.fetch(data);
          }
        }}
      >
        Save
      </Button>

      {contextHolder}
    </Actions>
  );
}

export default function ServiceEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { initialState } = useServicesContext();

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/services">Services</NavLink>,
          },
          {
            title: <NavLink to={`/services/${id}`}>{initialState?.name || 'Current Service'}</NavLink>,
          },
          {
            title: 'Edit service',
          },
        ]}
      />

      <ServicesForm />
    </>
  );
}
