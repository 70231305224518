import React from 'react';
import { Divider } from 'antd';
import { OrderStatus } from '../../../Adapter/enums';
import { ClientData } from '../../../Adapter';
import { Invoice, prioritizedInvoiceServices, ServiceKindType } from '../../../../../../hooks/api/invoices';

import styles from './index.module.scss';

export const sortServicesByPriority = (a: { kind?: ServiceKindType }, b: { kind?: ServiceKindType }) => {
  const priorityOrder = prioritizedInvoiceServices;

  /** Get the index of each item's kind in the priority order array, or -1 if it's undefined or not in the list */
  const aPriority = a.kind ? priorityOrder.indexOf(a.kind) : -1;
  const bPriority = b.kind ? priorityOrder.indexOf(b.kind) : -1;

  /** Handle cases where one kind has priority and the other doesn't */
  if (aPriority !== -1 && bPriority === -1) return -1; // a has priority
  if (bPriority !== -1 && aPriority === -1) return 1; // b has priority

  /** If both are in the priority order, sort by their index in the array */
  if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority;

  /** Keep original order for all other cases */
  return 0;
};

export const calculateInvoiceTotals = (invoices: Invoice[]) => {
  let totalTax = 0;
  let totalValue = 0;
  const invoicesCurrency = invoices?.[0]?.currency || 'CHF';

  /** Initialize an array to store individual service items */
  const servicesSummary: { name: string; value: number; kind?: ServiceKindType }[] = [];

  (invoices || []).forEach((invoice) => {
    (invoice.services || []).forEach((service) => {
      const serviceTotal = service.value * service.quantity;
      const serviceTax = (serviceTotal * service.tax) / 100;

      /** Round to 2 decimal places for CHF currency */
      const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
      const roundedServiceTax = service.tax > 0
        ? Math.max(Math.round(serviceTax * 100) / 100, 0.01)
        : 0;

      /** Accumulate total tax and total order value */
      totalTax += roundedServiceTax;
      totalValue += roundedServiceTotal + roundedServiceTax;

      servicesSummary.push({
        name: service.name,
        value: roundedServiceTotal,
        kind: service.kind,
      });
    });
  });

  /** Sort the services summary array */
  servicesSummary.sort(sortServicesByPriority);

  /** Round final total tax to 2 decimal places */
  totalTax = Math.round(totalTax * 100) / 100;
  totalValue = Math.round(totalValue * 100) / 100;

  return {
    servicesSummary, totalTax, totalValue, invoicesCurrency,
  };
};

interface SummaryBox {
  clientOrderData: ClientData | null;
  isAssistanceNeeded: boolean;
  style?: React.CSSProperties;
  className?: string;
}

function SummaryBox({ clientOrderData, isAssistanceNeeded, ...rest }: SummaryBox) {
  const {
    servicesSummary, totalTax, totalValue, invoicesCurrency,
  } = calculateInvoiceTotals(clientOrderData?.invoices || []); // order.data?.invoices

  return (
    <div className={styles.summaryBox} {...rest}>
      <div className={styles.title}>Summary</div>
      {/** If assistance needed and order still not processed by admin - dont show summary. */}
      {(isAssistanceNeeded && clientOrderData?.status && [
        OrderStatus.DRAFT,
        OrderStatus.PENDING,
        OrderStatus.IN_PROGRESS,
      ].includes(clientOrderData?.status))
      || !clientOrderData?.invoices?.length ? (
        <p className="text-secondary">
          The list of services and their cost will be determined after considering your request
        </p>
        ) : null}

      {/** If there's no assistance needed - or order already processed by admin - show prices */}
      {clientOrderData?.invoices?.length && (!isAssistanceNeeded || (clientOrderData?.status && ![
        OrderStatus.DRAFT,
        OrderStatus.PENDING,
        OrderStatus.IN_PROGRESS,
      ].includes(clientOrderData.status))) ? (
        <>
          {(servicesSummary || []).map((item) => (
            <div className={styles.item} key={item.name}>
              <span title={item.name} className="truncate">
                {item.name}
              </span>
              <span style={{ whiteSpace: 'nowrap' }}>
                {item.value?.toFixed(2)}
                {' '}
                {invoicesCurrency}
              </span>
            </div>
          ))}
          {totalTax ? (
            <div className={styles.item}>
              <span>Taxes</span>
              <span>
                {totalTax?.toFixed(2)}
                {' '}
                {invoicesCurrency}
              </span>
            </div>
          ) : null}
          <Divider style={{ margin: '16px 0' }} />

          <div className={styles.total}>
            <span>Order total</span>
            <span>
              {totalValue?.toFixed(2)}
              {' '}
              {invoicesCurrency}
            </span>
          </div>
        </>
        ) : null}
    </div>
  );
}

export default SummaryBox;
