import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { AnyObject, Error } from '@triare/auth-redux/dist/saga/common';
import { User } from '@triare/auth-redux/dist/saga/auth/useMe';
import { AlertProps } from 'antd/es/alert';
import { RootState } from '../../../../../../../store';
import { actions, moduleName } from '../../../../../../../store/auth';

export interface AlertSuccessProps extends AlertProps {
  disable?: boolean;
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  user: User | null;
}

function AlertSuccess({
  disable = false, authorized, user, error, response, ...props
}: AlertSuccessProps): React.ReactNode {
  const dispatch = useDispatch();

  if (disable === false && authorized && user && !error && response) {
    return (
      <Alert
        type="success"
        message="Signed in successfully!"
        closable
        onClose={() => dispatch({
          type: actions.signInClear.toString(),
        })}
        {...props}
      />
    );
  }

  return null;
}

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  error: state[moduleName].signIn.error,
  user: state[moduleName].user,
}))(AlertSuccess);
