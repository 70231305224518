import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { ButtonProps } from './index';
import ProductForm from './Form';

export function EditButton({
  item, index, remove,
}: ButtonProps) {
  return (
    <Button
      icon={<DeleteOutlined />}
      type="text"
      onClick={(e) => {
        e.stopPropagation();
        remove();
      }}
    />
  );
}

export function EditContent(props: ButtonProps) {
  return (
    <ProductForm {...props} />
  );
}

export default {
  EditButton,
  EditContent,
};
