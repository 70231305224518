import React from 'react';
import { Checkbox, Form as AntdForm, Typography } from 'antd';
import { Action, AnyObject } from '@triare/auth-redux';
import { Callbacks, Rule, ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FormProps as AntdFormProps } from 'rc-field-form/lib/Form';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { RequiredMark } from 'antd/es/form/Form';
import { useAuth } from '../../../../../store/auth';
import AlertSuccess, { AlertSuccessProps } from './Alert/Success';
import AlertError, { AlertErrorProps } from './Alert/Error';
import Submit from '../../../../Common/Form/Button/Submit';

import styles from '../../index.module.scss';

interface FormProps<P> extends AntdFormProps {
  disableAlertSuccess?: true;
  requiredFields?: '*' | string[];
  validationRules: Rule;
  styles?: { [key: string]: string };

  children: React.ReactNode;
  alertSuccess?: AlertSuccessProps;
  alertError?: AlertErrorProps;

  signIn: (payload: P) => Action | void;
  onFinishFailed?: Callbacks<P>['onFinishFailed'];
  validateSubmit: (fieldsValue: AnyObject) => boolean;

  requiredMark?: RequiredMark;
}

function FormSignIn<P>({
  disableAlertSuccess = undefined,
  requiredFields = '*',
  alertSuccess = undefined,
  alertError = undefined,
  initialValues = { remember: true },
  onFinishFailed = (errorInfo: ValidateErrorEntity<P>) => {
    console.log('Failed =', errorInfo);
  },
  signIn,
  validationRules,
  validateSubmit,
  form,
  styles: additionalStyles,
  children,
  ...props
}: FormProps<P>): React.ReactNode {
  const { signIn: { loading, response } } = useAuth();

  return (
    <AntdForm
      className={clsx(styles.form, additionalStyles?.form)}
      name="signIn"
      autoComplete="off"
      layout="vertical"
      onFinish={signIn}
      onFinishFailed={onFinishFailed}
      {...props}
    >
      <Typography.Text className={styles.text} strong>
        Log in using your credentials
      </Typography.Text>

      <div className={styles.alertWrapper}>
        {disableAlertSuccess ? null : (
          <AlertSuccess response={response} {...alertSuccess} />
        )}
        <AlertError {...alertError} />
      </div>

      {children}

      <AntdForm.Item>
        <AntdForm.Item
          className={clsx(styles?.remember, additionalStyles?.remember)}
          name="remember"
          valuePropName="checked"
          noStyle
        >
          <Checkbox>Remember me</Checkbox>
        </AntdForm.Item>

        <NavLink to="/forgot-password" style={{ float: 'right' }}>
          Forgot password?
        </NavLink>
      </AntdForm.Item>

      <Submit
        validateSubmit={validateSubmit}
        requiredFields={requiredFields}
        itemClassName={clsx(styles.submit, additionalStyles?.submit)}
        loading={loading}
        className={styles.btn}
        size="large"
      >
        Log in
      </Submit>
    </AntdForm>
  );
}

export default FormSignIn;
