import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useDeliveryTermsGetAll } from '../../../../../../hooks/api/order';

function SelectDeliveryTerms({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useDeliveryTermsGetAll();

  return (
    <OrderSelect fetch={dualUseGet} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectDeliveryTerms;
