import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import Menu from '../../components/Layout/Menu';
import AddressBookContent from '../../components/Pages/AddressBook';

const { displayName } = config;

export default function AddressBook(): React.ReactNode | null {
  document.title = `${displayName}: AddressBook`;

  return (
    <Layout
      title="Address Book"
      leftMenu={<Menu />}
    >
      <AddressBookContent />
    </Layout>
  );
}
