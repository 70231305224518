import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  Button, Form, Input, message, Modal,
} from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import OrderSwitch from '../Common/Switch';
import {
  createRulesForAntd, createValidatorTextField, email,
} from '../../../../../utils/validations';
import OrderInput from '../Common/Input';
import { FormName, FormNames, useOrderContextForm } from '../context';
import { FormWrapper } from '../index';
import SelectPlace from '../Common/SelectPlace';
import {
  filterObjectProps,
  formatPhoneNumber, getUserRequestPath, pickObjFields,
} from '../../../../../utils';
import InputSelectCompanyAddress from '../Common/InputSelectCompanyAddress';
import { AddressBookCreateParams, useParticipantCreate } from '../../../../../hooks/api/addressBook';
import { useAuth } from '../../../../../store/auth';
import { useMessageError } from '../../../../../hooks/common';

import styles from '../index.module.scss';

function FullAddress({
  patch = '', formName, validationRules, disabledFields,
}: {
  patch?: string;
  formName: FormNames;
  validationRules: Rule;
  disabledFields?: Record<string, boolean>; // 'country' | 'address1' | 'address2' | 'postalCode' | 'city' | 'state'
}) {
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const countryCodeWatch = Form.useWatch('countryCode', form);

  useEffect(() => {
    if (form) {
      form.setFieldValue(['details', 'countryCode'], countryCodeWatch);
    }
  }, [countryCodeWatch]);

  return (
    <>
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['country']}
        form={form}
        label="Country"
        name={patch ? [patch, 'country'] : 'country'}
        rules={[validationRules, { required: true, message: <div /> }]}
        onSelect={(value) => {
          ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
            form.setFieldValue(patch ? [patch, key] : key, undefined);
            if (!patch) {
              form.setFieldValue(['details', key], undefined);
            }
          });
          form.setFieldValue(['details', 'country'], value);
        }}
        disabled={disabledFields?.country}
      >
        <div className={styles.text}>
          DGA, PI, Delivery
        </div>
      </SelectPlace>
      <OrderInput
        style={{ display: 'none' }}
        name={patch ? [patch, 'countryCode'] : 'countryCode'}
      />
      <OrderInput
        style={{ display: 'none' }}
        name={patch ? [patch, 'stateCode'] : 'stateCode'}
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['address']}
        form={form}
        label="Address"
        name={patch ? [patch, 'address1'] : 'address1'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <OrderInput
        label="Address2"
        name={patch ? [patch, 'address2'] : 'address2'}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['postal_code']}
        form={form}
        label="Postal code"
        name={patch ? [patch, 'postalCode'] : 'postalCode'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['locality']}
        form={form}
        label="City / Town"
        name={patch ? [patch, 'city'] : 'city'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <SelectPlace
        triggerValidationAllForm={triggerValidationAllForm}
        type={['administrative_area_level_1']}
        form={form}
        label="Province / Region / State"
        name={patch ? [patch, 'state'] : 'state'}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
    </>
  );
}

const shipperPickKeys = [
  'company',
  'contactName',
  'phone',
  'email',
  'country',
  // 'countryCode',
  'address1',
  'address2',
  'postalCode',
  'city',
  'state',
  'tin',
  'registerNumber',
  // 'reference',
];

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);

interface ShipperProps extends FormName {
  titleAdditional: string;
  switchName: string;
}

const baseForm = {
  company: textFieldRequired,
  contactName: textFieldRequired,
  phone: textFieldRequired,
  email,
  country: textFieldRequired,
  address1: textFieldRequired,
  address2: textField,
  postalCode: textFieldRequired,
  city: textFieldRequired,
  state: textFieldRequired,
};

function Shipper({ formName, titleAdditional, switchName }: ShipperProps): React.ReactNode | null {
  const { setValidator, forms: { [formName]: form }, generalForm } = useOrderContextForm();
  const { user } = useAuth();
  const addressItemCreate = useParticipantCreate(getUserRequestPath(user?.role));

  const validationRules = useMemo(() => createRulesForAntd(yup.object().shape({
    ...baseForm,
    tin: textField,
    registerNumber: textField,
    reference: textField,
    details: yup.object({
      ...baseForm,
    }),
  }), form.getFieldsValue), [form]);

  useEffect(() => {
    setValidator(formName, () => {
      const {
        details: detailsData,
        isAddressDifferent,
        address2,
        registerNumber,
        tin,
        reference,
        stateCode,
        ...mainData
      } = form.getFieldsValue();
      const { address2: detailAddress2, ...details } = detailsData || {};

      // return !(Object.values(mainData).some((value) => value === '' || value === undefined)
      //   || Object.values(details).some((value) => value === '' || value === undefined)
      //   || form.getFieldsError().some(({ errors }) => errors.length));

      return !(form.getFieldsError().some(({ errors }) => errors.length));
    });
  }, [form]);

  const addressDifferent = Form.useWatch('isAddressDifferent', form);
  const countryMainWatch = Form.useWatch('country', form);

  /** Create address book item: */
  const [addressModal, setAddressModal] = useState<{
    open: boolean,
    prefix: 'details' | null,
  }>({ open: false, prefix: null });
  const [displayNameVal, setDisplayNameVal] = useState('');

  const handleCreateAddress = (displayName: string, prefix?: 'details' | null) => {
    const formValues = prefix ? pickObjFields(form.getFieldsValue()?.[prefix], shipperPickKeys)
      : pickObjFields(form.getFieldsValue(), shipperPickKeys);

    const newValues = {
      ...filterObjectProps(formValues, ['tin', 'company']),
      displayName,
      tinNumber: formValues?.tin || '',
      companyName: formValues?.company || '',
    } as AddressBookCreateParams;

    if (user?.role !== 'user') {
      const companyId = generalForm.getFieldValue('company');

      if (companyId) {
        Object.assign(newValues, { company: { id: companyId } });
      }
    }

    addressItemCreate.fetch(newValues)
      .then((res) => {
        if (res?.id) { handleModalClose(); }
      });
  };

  const handleModalOpen = (prefix?: 'details') => {
    const fieldsKeys = prefix ? shipperPickKeys.map((item) => [prefix, item]) : shipperPickKeys;

    /** Validate address fields before opening modal. */
    form.validateFields(fieldsKeys)
      .then(() => {
        setAddressModal({ open: true, prefix: prefix || null });
        setDisplayNameVal('');
      })
      .catch(() => {
        message.error('Fill all required fields!');
      });
  };

  const handleModalClose = () => {
    setAddressModal({ open: false, prefix: null });
    setDisplayNameVal('');
  };

  useMessageError([addressItemCreate]);

  return (
    <FormWrapper
      formName={formName}
      className={styles.wrapper}
    >
      <Modal
        centered
        title="Create address"
        open={addressModal.open}
        onCancel={handleModalClose}
        okText="Add"
        onOk={() => handleCreateAddress(displayNameVal, addressModal.prefix)}
        okButtonProps={{
          disabled: displayNameVal.length < 3,
          loading: addressItemCreate.loading,
        }}
        destroyOnClose
      >
        <Input
          placeholder="Enter address display name"
          onChange={(e) => setDisplayNameVal(e.target.value)}
          maxLength={250}
        />
      </Modal>

      <h3>
        <span>{formName}</span>
      </h3>

      <InputSelectCompanyAddress
        name="company"
        formName={formName}
        validationRules={validationRules}
        rightText="DGA, PI, Delivery"
      />

      <OrderInput
        label="Contact name"
        name="contactName"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Mobile number"
        name="phone"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="PI, Delivery"
        getValueProps={formatPhoneNumber}
      />
      <OrderInput
        label="Email"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="DGA, PI, Delivery"
      />
      <FullAddress formName={formName} validationRules={validationRules} />
      <OrderInput
        label="TIN number"
        name="tin"
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Register number"
        name="registerNumber"
        rightText="PI, Delivery"
      />
      <OrderInput
        label="Reference"
        name="reference"
        rightText="PI, Delivery"
      />

      <Form.Item label=" " className="form-hidden-after">
        <Button
          onClick={() => handleModalOpen()}
          loading={addressItemCreate.loading}
        >
          Save to Address Book
        </Button>
      </Form.Item>

      <h3>
        <span>{titleAdditional}</span>
      </h3>

      <OrderSwitch
        className={styles.noLabel}
        name="isAddressDifferent"
        rightText={false}
      >
        {switchName}
      </OrderSwitch>

      {addressDifferent ? (
        <>
          <InputSelectCompanyAddress
            name={['details', 'company']}
            prefix="details"
            formName={formName}
            validationRules={validationRules}
            rightText="DGA, PI, Delivery"
          />
          <OrderInput
            label="Contact name"
            name={['details', 'contactName']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="PI, Delivery"
          />
          <OrderInput
            label="Mobile number"
            name={['details', 'phone']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="PI, Delivery"
            getValueProps={formatPhoneNumber}
          />
          <OrderInput
            label="Email"
            name={['details', 'email']}
            rules={[validationRules, { required: true, message: <div /> }]}
            rightText="DGA, PI, Delivery"
          />
          {/* TODO disable country, and prefill it from top form. Country cant be different in shipper this case. */}
          <FullAddress
            patch="details"
            formName={formName}
            validationRules={validationRules}
            disabledFields={{ country: !!countryMainWatch }}
          />
          <OrderInput
            label="Register number"
            name={['details', 'registerNumber']}
            rightText="PI, Delivery"
          />
          <Form.Item label=" " className="form-hidden-after">
            <Button
              onClick={() => handleModalOpen('details')}
              loading={addressItemCreate.loading}
            >
              Save to Address Book
            </Button>
          </Form.Item>
        </>
      ) : null}
    </FormWrapper>
  );
}

export default Shipper;
