import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import styles from './index.module.scss';

interface LoadingProps {
  size?: number;
  className?: string;
  absolute?: boolean;
  withBg?: boolean;
  circleSpinner?: boolean;
}

function Loading({
  className,
  size = 24,
  absolute = false,
  withBg = false,
  circleSpinner = false,
}: LoadingProps) {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return (
    <Spin
      indicator={circleSpinner ? antIcon : undefined}
      className={clsx(className, {
        [styles.absolute]: absolute,
        [styles.withBg]: withBg,
      })}
    />
  );
}

export default Loading;
