import React, { useEffect } from 'react';
import { Breadcrumb, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useServicesContext } from '../Form/context';
import { useDefaultServicesGet, useDefaultServicesUpdate } from '../../../../../hooks/api/services';
import DefaultServicesForm from '../Form';

export function HeaderActions() {
  const navigate = useNavigate();
  const {
    form, isValid, setInitialState, initialState,
  } = useServicesContext();
  const defaultServiceId = initialState?.id;
  const serviceUpdate = useDefaultServicesUpdate();

  useMessageError([serviceUpdate]);
  useMessageSuccess([serviceUpdate], 'Changes saved successfully');

  useEffect(() => {
    if (!serviceUpdate.error && !serviceUpdate.loading && serviceUpdate.data) {
      navigate('/services');
    }
  }, [serviceUpdate.error, serviceUpdate.loading, serviceUpdate.data]);

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={serviceUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            serviceUpdate.fetch(data, defaultServiceId);
          }
        }}
      >
        Save
      </Button>
    </Actions>
  );
}

export default function DefaultServiceEdit(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/services">Services</NavLink>,
          },
          {
            title: 'Edit default services',
          },
        ]}
      />

      <DefaultServicesForm />
    </>
  );
}
