import {
  FetchGet, useFetchGet,
} from '../fetch';

export interface Country {
  code: string;
  code3: string;
  name: string;
  number: number | string;
}

export const useCountryGet = (): FetchGet<Country[]> => useFetchGet(
  'countries/all',
  {
    autoStart: true,
    startStateLoading: true,
    multiple: 'countries/all',
    cacheLifetime: 30 * 60 * 1000, // 30 minutes cache
  },
);

export interface MasterdataCountry {
  name: string;
  code: string;
  id: string;
  createdAt: string;
  updatedAt: string;
}

export const useMasterdataCountriesGet = (): FetchGet<MasterdataCountry[]> => useFetchGet(
  'masterdata/countries',
  {
    autoStart: true,
    startStateLoading: true,
    multiple: 'countries/all',
    cacheLifetime: 30 * 60 * 1000, // 30 minutes cache
  },
);
