import React, { useEffect, useState } from 'react';
import {
  Alert, Form, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '@triare/auth-redux';
import { connect } from 'react-redux';
import {
  OTP as otpAction,
  OTPAction,
  OTPClear as otpClearAction,
  OTPGenerate as otpGenerateAction,
  OTPGenerateAction,
  OTPGenerateClear as otpGenerateClearAction,
  OTPGenerateResponse,
} from '@triare/auth-redux/dist/saga/auth/OTP';
import InputCodeWrapper from '../../Form/Input/Code';
import { moduleName } from '../../../../store/auth';
import { RootState } from '../../../../store';
import { timeResendOTPCode } from '../../../../contstant';
import { getMessageInError } from '../../../../hooks/fetch';
import Submit from '../../Form/Button/Submit';

interface FormValues {
  [key: string]: number;
}

interface OTPVerificationProps {
  email?: string;
  nameBtnFinish: string;

  beforeBtnFinish?: React.ReactNode;
  afterBtnFinish?: React.ReactNode;
  className?: string;
  onInit?: (form: FormInstance) => void;

  OTP: (payload: OTPAction) => void;
  OTPClear: () => void;
  loading: boolean;
  error: null | AnyObject;

  OTPGenerate: (payload: OTPGenerateAction) => void;
  OTPGenerateClear: () => void;
  otpGenerateLoading: boolean;
  otpGenerateResponse: OTPGenerateResponse | null;
  otpGenerateError: null | AnyObject;

  alertSuccess: React.ReactNode;

  secretKey: string;
}

function OTPVerification({
  beforeBtnFinish = undefined,
  afterBtnFinish = undefined,
  className = undefined,
  onInit = undefined,
  email = undefined,
  nameBtnFinish,
  alertSuccess,
  OTP,
  OTPClear,
  loading,
  error,
  OTPGenerate,
  OTPGenerateClear,
  otpGenerateLoading,
  otpGenerateResponse,
  otpGenerateError,
  secretKey,
}: OTPVerificationProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [resendClick, setResentClick] = useState(false);
  const [startTime, setStartTime] = useState(Date.now() - timeResendOTPCode);
  const [time, setTime] = useState(0);
  const calcTime = () => Math.round((startTime + timeResendOTPCode + 1 - Date.now()) / 1000);

  useEffect(() => {
    if (otpGenerateResponse && !otpGenerateError) {
      setStartTime(Date.now());
    }
  }, [otpGenerateResponse, otpGenerateError]);

  useEffect(() => {
    if (resendClick) {
      const id = setInterval(() => {
        const seconds = calcTime();

        if (seconds > 0) {
          setTime(seconds);
        } else {
          setTime(0);
          clearInterval(id);
          setResentClick(false);
        }
      }, 1000);

      return () => clearInterval(id);
    }

    return () => {};
  }, [resendClick]);

  useEffect(() => {
    if (onInit) {
      onInit(form);
    }
  }, [form]);

  const onFinishHandler = (values: FormValues) => {
    OTP({
      secretCode: Object.values(values).join(''),
      secretKey,
    });
  };

  return (
    <Form<FormValues> form={form} className={className} autoComplete="off" layout="vertical" onFinish={onFinishHandler}>
      <h2>Please enter the 4 digit codes sent to your mobile phone</h2>
      <span>
        Didn&apos;t get the code? Please go back and check your information is correct. You can also
        {' '}
        <a
          href="/generate-code"
          onClick={(e) => {
            e.preventDefault();

            const seconds = calcTime();

            if (seconds > 0) {
              setTime(seconds);
              setResentClick(true);
            } else {
              OTPGenerate({
                secretKey,
              });
            }
          }}
        >
          send a new code to your phone
        </a>
        {email ? (
          <>
            {' '}
            or
            {' '}
            <a
              href="/generate-code"
              onClick={(e) => {
                e.preventDefault();

                const seconds = calcTime();

                if (seconds > 0) {
                  setTime(seconds);
                  setResentClick(true);
                } else {
                  OTPGenerate({
                    secretKey,
                    useEmail: true,
                  });
                }
              }}
            >
              send it to
              {' '}
              {email}
            </a>
          </>
        ) : null}
        {' '}
        instead.

        {resendClick ? (
          <div style={{ color: 'red', padding: '8px 0' }}>
            Wait
            {' '}
            {time}
            {' '}
            seconds and then you can get a new code.
          </div>
        ) : null}
      </span>

      <InputCodeWrapper form={form} style={{ marginTop: resendClick ? '26px' : '64px' }} />

      {error ? (
        <Alert
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={OTPClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {otpGenerateError ? (
        <Alert
          type="error"
          message={getMessageInError(otpGenerateError)}
          closable
          onClose={OTPGenerateClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {alertSuccess}

      <div>
        {beforeBtnFinish}

        <Submit
          loading={loading || otpGenerateLoading}
          size="large"
        >
          {nameBtnFinish}
        </Submit>

        {afterBtnFinish}
      </div>
    </Form>
  );
}

export default connect(
  (state: RootState) => ({
    error: state[moduleName].OTP.error,
    loading: state[moduleName].OTP.loading,
    otpGenerateResponse: state[moduleName].OTP.generate.response,
    otpGenerateError: state[moduleName].OTP.generate.error,
    otpGenerateLoading: state[moduleName].OTP.generate.loading,
    secretKey: state[moduleName].secretKey,
  }),
  {
    OTP: otpAction,
    OTPClear: otpClearAction,
    OTPGenerate: otpGenerateAction,
    OTPGenerateClear: otpGenerateClearAction,
  },
)(OTPVerification);
