import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import Menu from '../../../../components/Layout/Menu';
import ServicesProvider from '../../../../components/Pages/Services/Form/context';
import ServicesEditContent, { HeaderActions } from '../../../../components/Pages/Services/View/Edit';

const { displayName } = config;

export default function ServiceEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit service`;

  return (
    <ServicesProvider>
      <Layout
        title="Edit service"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <ServicesEditContent />
      </Layout>
    </ServicesProvider>
  );
}
