import React from 'react';
import { Badge, BadgeProps } from 'antd';
import { EuroCircleOutlined } from '@ant-design/icons';

interface IconWithBadgeProps {
  BadgeIcon: React.ElementType;
  badgeColor?: BadgeProps['color'];
  Icon: React.ElementType;
}

function IconWithBadge({ BadgeIcon, Icon = EuroCircleOutlined, badgeColor = '#faad14' }: IconWithBadgeProps) {
  return (
    <Badge
      count={(
        <BadgeIcon style={{
          color: badgeColor || 'lightgrey',
          backgroundColor: 'white',
          borderRadius: '50%',
        }}
        />
        )}
      offset={[-4, 20]}
    >
      <Icon style={{ fontSize: '24px' }} />
    </Badge>
  );
}

export default IconWithBadge;
