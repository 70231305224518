import React, { useEffect } from 'react';
import { Form } from 'antd';
import { useMaterialCodeGetAll } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';
import { FormNames, useOrderContextForm } from '../../context';

interface SelectMaterialCodeProps extends OrderSelectProps {
  prefix: FormNames
}

function SelectMaterialCode({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: SelectMaterialCodeProps) {
  const { forms: { [props.prefix]: form } } = useOrderContextForm();
  const initPackagingValue = form.getFieldValue([props.prefix, (props.name as string[])[0], 'packaging']);
  const dualUseGet = useMaterialCodeGetAll(
    // eslint-disable-next-line no-nested-ternary
    typeof initPackagingValue === 'object'
      ? (initPackagingValue ? initPackagingValue.value : undefined) : initPackagingValue,
  );
  const packaging = Form.useWatch([props.prefix, (props.name as string[])[0], 'packaging'], form);

  useEffect(() => {
    if (packaging) {
      dualUseGet.fetch({
        packageId: typeof packaging === 'object' ? packaging.value : packaging,
      });
    }
  }, [packaging]);

  return (
    <OrderSelect
      fetch={dualUseGet}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectMaterialCode;
