import React, { useEffect } from 'react';

import { Breadcrumb, Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';
import ServicesForm from '../Form';
import { useServicesContext } from '../Form/context';
import { useServicesCreate } from '../../../../hooks/api/services';

export function HeaderActions() {
  const navigate = useNavigate();
  const { form, isValid, setInitialState } = useServicesContext();
  const serviceCreate = useServicesCreate();

  useMessageError([serviceCreate]);
  useMessageSuccess([serviceCreate], 'Changes saved successfully');

  useEffect(() => {
    if (!serviceCreate.error && !serviceCreate.loading && serviceCreate.data) {
      navigate('/services');
    }
  }, [serviceCreate.error, serviceCreate.loading, serviceCreate.data]);

  return (
    <Actions>
      <Button
        type="primary"
        disabled={!isValid}
        loading={serviceCreate.loading}
        onClick={(e) => {
          e.preventDefault();

          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            serviceCreate.fetch(data);
          }
        }}
      >
        Save
      </Button>
    </Actions>
  );
}

export default function ServicesCreate(): React.ReactNode | null {
  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/services">Services</NavLink>,
          },
          {
            title: 'Add service',
          },
        ]}
      />
      <ServicesForm />
    </>
  );
}
