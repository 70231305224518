import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Input, Modal,
} from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { ActionType, ProColumns } from '@ant-design/pro-table/es/typing';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { DangerousGoodsTable, useDangerousGoodsGet } from '../../../../../../hooks/api/order';
import { OrderInputProps } from '../Input';
import { getSorterParams, queryFilterParams } from '../../../../../../utils';
import { FormName, useOrderContextForm } from '../../context';
import useComponentDidUpdate from '../../../../../../hooks/componentDidUpdate';

import styles from '../../index.module.scss';

interface SelectInputDangerousGoodsProps extends OrderInputProps, FormName {
  name: (string | number)[];
  onSelectRecord?: (record: DangerousGoodsTable) => void;
}

const listIcon: { [key: string]: React.ReactNode } = {
  'Beförderung verboten': <StopOutlined style={{ color: '#F5222D' }} />,
  Betroffen: <CheckOutlined style={{ color: '#52C41A' }} />,
  'Nicht betroffen': (
    <>
      <CheckOutlined style={{ color: '#52C41A', transform: 'translate(5px, 0)' }} />
      <CheckOutlined style={{ color: '#52C41A', transform: 'translate(-5px, 0)' }} />
    </>
  ),
  Subject: <CheckOutlined style={{ color: '#52C41A' }} />,
  'Forbidden Transport': <StopOutlined style={{ color: '#F5222D' }} />,
  'Not Subject': (
    <>
      <CheckOutlined style={{ color: '#52C41A', transform: 'translate(5px, 0)' }} />
      <CheckOutlined style={{ color: '#52C41A', transform: 'translate(-5px, 0)' }} />
    </>
  ),
};

const columns: ProColumns<DangerousGoodsTable>[] = [
  {
    title: 'UN no.',
    dataIndex: 'unNumber',
    key: 'unNumber',
    width: '60px',
  },
  {
    title: 'Name of goods',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Packing',
    dataIndex: 'packingGroup',
    key: 'packingGroup',
    width: '80px',
  },
  {
    title: 'Classification',
    dataIndex: 'classificationCode',
    key: 'classificationCode',
    width: '80px',
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    key: 'labels',
    width: '50px',
  },
  {
    title: 'IATA',
    dataIndex: 'statusIATA',
    key: 'statusIATA',
    width: '60px',
    align: 'center',
    render: (value) => listIcon[value as string],
  },
  {
    title: 'IMDG',
    dataIndex: 'statusIMDG',
    key: 'statusIMDG',
    width: '60px',
    align: 'center',
    render: (value) => listIcon[value as string],
  },
  {
    title: 'ADR',
    dataIndex: 'statusADR',
    key: 'statusADR',
    width: '60px',
    align: 'center',
    render: (value) => listIcon[value as string],
  },
];

function SelectInputDangerousGoods({
  inputProps = {},
  rest = {},
  formName,
  children,
  rightText,
  onSelectRecord,
  name, ...props
}: SelectInputDangerousGoodsProps) {
  const actionRef = useRef<ActionType | undefined>();
  const [filter, setFilter] = useState('all');
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dangerousGoodsGet = useDangerousGoodsGet();
  const dangerousGood = Form.useWatch([formName, ...name], form);

  useEffect(() => {
    if (typeof dangerousGood === 'object' && dangerousGood && dangerousGood.unNumber) {
      handleSelect(dangerousGood, false);
    }
  }, [dangerousGood]);

  useComponentDidUpdate(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [filter]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelect = (record: DangerousGoodsTable, trigger = true) => {
    form.setFieldValue([formName, ...name], record.dangerousGoodId);
    form.setFieldValue(
      [formName, name[0], `${name[1]}_view`],
      `${record.unNumber} ${record.name} ${record.classificationCode}`,
    );
    form.setFieldValue(
      (Array.isArray(name) ? [formName, name[0], 'packagingGroup'] : [formName, 'packagingGroup']),
      record.packingGroup,
    );
    if (onSelectRecord && (record !== dangerousGood)) { onSelectRecord(record); }
    handleOk();

    if (trigger) {
      triggerValidationAllForm();
    }
  };

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<DangerousGoodsTable>>> => dangerousGoodsGet.fetch(queryFilterParams({
    page: current ? `${current}` : '1',
    pageSize: pageSize ? `${pageSize}` : '10',
    letter: filter === 'all' ? '' : filter,
    ...args,
    ...getSorterParams(sorter),
  })).then((response) => {
    if (response) {
      const { data, total } = response;

      return ({ data: data || [], success: true, total });
    }

    return ({ data: [], success: false, total: 0 });
  });

  return (
    <>
      <Form.Item
        {...rest}
        {...props}
        name={name}
        style={{ display: 'none' }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        {...props}
        name={[name[0], `${name[1]}_view`]}
        extra={(
          <>
            {children}
            {rightText === false ? null : (
              <div className={styles.text}>
                {rightText}
              </div>
            )}
          </>
        )}
      >
        <Input
          {...inputProps}
          onClick={(e) => {
            e.preventDefault();
            showModal();
          }}
          readOnly
        />
      </Form.Item>
      <Modal
        centered
        title="Dangerous Goods Selection"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className={styles.modalTable}
      >
        <div className={styles.filter}>
          {['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
            'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
            .map((key) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                key={key}
                className={key.toLowerCase() === filter ? styles.active : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  setFilter(key.toLowerCase());
                }}
              >
                {key}
              </div>
            ))}
        </div>
        <ProTable<DangerousGoodsTable>
          actionRef={actionRef}
          onRow={(record) => ({
            onClick: (event) => {
              event.preventDefault();
              handleSelect(record);
            },
          })}
          columns={columns}
          request={tableRequest}
          search={false}
          rowKey="key"
          dateFormatter="string"
          headerTitle="List of Dangerous Goods"
          pagination={{
            defaultPageSize: 10,
            size: 'default',
            showQuickJumper: true,
            showTotal: undefined,
          }}
          options={{
            density: false,
            search: {
              name: 'search',
            },
            reloadIcon: false,
            setting: false,
          }}
        />
      </Modal>
    </>
  );
}

export default SelectInputDangerousGoods;
