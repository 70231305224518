import React, { useEffect, useState } from 'react';

import {
  Form, Input, InputNumber, Select, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  createRulesForAntd, createValidatorTextField,
} from '../../../../utils/validations';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { useMessageError } from '../../../../hooks/common';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { enumToOptionsArray, getOnlyValueRecursive } from '../../../../utils';
import { IService, ServiceUnitEnum, useServicesGetById } from '../../../../hooks/api/services';
import { useServicesContext } from './context';
import { useBlockerContext } from '../../../../context/blockerDisabler';

import styles from '../../../Common/Form/index.module.scss';

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);
export const valueField: yup.StringSchema = textFieldRequired.min(1, 'Must be at least 1 characters');

export const validationCreateService = yup.object().shape({
  // company: textFieldRequired,
  name: textFieldRequired,
  value: valueField,
});

type ServicesFormProps = AnyObject
const { Option } = Select;

export default function ServicesForm({ ...props }: ServicesFormProps): React.ReactNode | null {
  const { id } = useParams();
  const [form] = Form.useForm<IService>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useServicesContext();
  const serviceById = useServicesGetById(id);
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );
  const { handleIsBlockerActive } = useBlockerContext();
  const { loading, data, error } = serviceById;
  const validationRules = createRulesForAntd(validationCreateService);

  useMessageError([serviceById]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      setInitialState(data);
      form?.setFieldsValue(data);
    }
  }, [loading, data, error]);

  useEffect(() => {
    handleIsBlockerActive(false);
  }, [initialState]);

  return (
    <Form
      className={clsx(styles.form, styles.miniTopPadding)}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={async () => {
        setTriggerChange(Date.now());
        handleIsBlockerActive(true);
        const formValues = form.getFieldsValue();

        try {
          await validationCreateService.validate(formValues, { abortEarly: false });
          setValid(true);
        } catch {
          setValid(false);
        }
      }}
      initialValues={{ ...initialState }}
      {...props}
            // onFieldsChange={() => {
      //   setTriggerChange(Date.now());

      //   setValid(!(Object.keys(form.getFieldsValue())
      //     .filter((key) => !(['address2', 'registerNumber', 'tinNumber'].includes(key)))
      //     .map((key) => form.getFieldValue(key))
      //     .some((value) => value === '' || value === undefined)
      //     || form.getFieldsError().some(({ errors }) => errors.length)));
      // }}
    >
      <Form.Item<IService>
        label="Name"
        name="name"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<IService>
        label="Units"
        name="units"
        rules={[{ required: true, message: <div /> }]}
      >
        <Select options={enumToOptionsArray(ServiceUnitEnum)} />
      </Form.Item>

      <Form.Item<IService>
        label="Price"
        name="value"
        rules={[{ required: true, message: <div /> }]}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          min={0}
          max={9999}
          suffix="CHF"
          controls={false}
          style={{ width: '100%' }}

        />
      </Form.Item>

      {loading ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : null}

      {routeBlocker.contextHolder}
    </Form>
  );
}
