import React from 'react';
import { config } from '@triare/auth-redux';
import { Link } from 'react-router-dom';
import Simple from '../../components/Layout/Simple';

const { displayName } = config;

export default function NotFound(): React.ReactNode {
  document.title = `${displayName}: Not found`;

  return (
    <Simple>
      <div style={{ textAlign: 'center' }}>
        <p>
          Not found 404
        </p>
        <Link to="/" replace>
          Go home
        </Link>
      </div>
    </Simple>
  );
}
