import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';
import { IService } from '../../../../hooks/api/services';
import { SimpleProviderProps } from '../../../../types';

export interface ServicesContextProps {
  initialState: IService | null,
  setInitialState: (data: IService) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: IService) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const ServicesContext = createContext<ServicesContextProps>(defaultValue);

function ServicesProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<IService | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <ServicesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
}

export default ServicesProvider;

export const useServicesContext = ():
ServicesContextProps => React.useContext(ServicesContext);
