import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../components/Layout';
import CreateCompanyProfileContent from '../../../components/Pages/Companies/CreateProfile';
import Menu from '../../../components/Layout/Menu';
import CompanyProvider from '../../../components/Pages/Companies/Form/context';

const { displayName } = config;

export default function CreateCompanyProfile(): React.ReactNode | null {
  document.title = `${displayName}: Create Company profile`;

  return (
    <CompanyProvider>
      <Layout
        title="Create your company profile"
        leftMenu={<Menu onlyLogOut />}
      >
        <CreateCompanyProfileContent />
      </Layout>
    </CompanyProvider>
  );
}
