import { User, UserRole } from '../store/auth';

export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  PENDING = 'pending',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
  ROOT = 'root',
}

export const ROLE_LIST = [Role.USER, Role.ADMIN, Role.ROOT];

export function isRoleEnough(
  currentRole: Role | string | undefined,
  requiredRole: Role | string,
): boolean {
  if (currentRole === requiredRole) {
    return true;
  }

  return (
    ROLE_LIST.indexOf(currentRole as Role)
    >= ROLE_LIST.indexOf(requiredRole as Role)
  );
}

/** isRoleEnough which works directly with localStorage: */
export function getCurrentRole(): UserRole | undefined {
  const storageAuth = localStorage.getItem('_auth_');
  const storedUser: User = JSON.parse(storageAuth || '{}')?.user;

  if (storedUser) {
    return storedUser.role;
  }

  return undefined;
}

export function isRoleEnoughAuto(requiredRole: Role | string): boolean {
  const currentRole = getCurrentRole();

  if (!currentRole) {
    return false;
  }

  if (currentRole === requiredRole) {
    return true;
  }

  return ROLE_LIST.indexOf(currentRole as Role) >= ROLE_LIST.indexOf(requiredRole as Role);
}
