import React from 'react';
import OrderSelect, { OrderSelectProps } from '../Select';

export const DeliveryServiceList = [
  {
    value: 'dhl',
    label: 'DHL',
  },
  {
    value: 'fedex',
    label: 'Fedex',
  },
  {
    value: 'schenker',
    label: 'DB Schenker',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const preferredServiceKeys = new Set(['dhl', 'fedex']);

const PreferredServiceList = DeliveryServiceList.filter(({ value }) => (
  preferredServiceKeys.has(value)
));

function SelectDeliveryService({
  fetch,
  optionsType = 'default',
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps & { optionsType?: 'default' | 'preferredDelivery' }) {
  // const dualUseGet = useDeliveryServiceGetAll();

  return (
    <OrderSelect
      options={optionsType === 'preferredDelivery' ? PreferredServiceList : DeliveryServiceList}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectDeliveryService;
