import React from 'react';
import { config } from '@triare/auth-redux';
import SignInContent from '../../../components/Pages/Auth/SignIn';
import EmailAndPassword from '../../../components/Pages/Auth/SignIn/EmailAndPassword';
import Simple from '../../../components/Layout/Simple';

const { displayName } = config;

function SignIn(): React.ReactNode {
  document.title = `${displayName}: Sign in`;

  return (
    <Simple>
      <SignInContent content={EmailAndPassword} />
    </Simple>
  );
}

export default SignIn;
