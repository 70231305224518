import React from 'react';
import { TermsOfServiceButton } from '../../../../Companies/CreateProfile/TermsOfService';

export default function TermsOfService() {
  return (
    <div>
      <span>
        Review our Terms of Service to understand the rules and guidelines for using our platform.
        Your continued
        <br />
        use of our services constitutes your agreement to these terms.
      </span>
      <div style={{ marginTop: '21px' }}>
        <TermsOfServiceButton text="View Terms of Service" />
      </div>
    </div>
  );
}
