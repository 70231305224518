import React, { useEffect, useMemo } from 'react';
import {
  App,
  Form, Input, InputNumber, Select,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import { createRulesForAntd, createValidatorTextField } from '../../../../../../../utils/validations';
import { ContentProps } from '../index';
import OrderInput from '../../../Common/Input';
import SelectInputDangerousGoods from '../../../Common/SelectInputDangerousGoods';
import SelectKindPackage from '../../../Common/SelectKindPackage';
import SelectMaterialCode from '../../../Common/SelectMaterialCode';
import SelectEmptyUncleaned from '../../../Common/SelectIsEmptyUncleaned';
import SelectEnvironmentallyHazardous from '../../../Common/SelectEnvironmentallyHazardous';
import SelectPhysicalState from '../../../Common/SelectPhysicalState';
import SelectPureMixSolution from '../../../Common/SelectPureMixSolution';
import OrderUpload from '../../../Common/Upload';
import OrderSwitch from '../../../Common/Switch';
import SelectSpecialTemperatureMode from '../../../Common/SelectSpecialTemperatureMode';
import SelectSegregationGroup from '../../../Common/SelectSegregationGroup';
import { FormName, useOrderContextForm } from '../../../context';
import SelectInnerPackagingType from '../../../Common/SelectInnerPackagingType';
import { ClientDataGood, ClientProductType } from '../../../../Adapter';
import SelectHSCode from '../../../Common/HSCode';
import InputSelectGood from '../../../Common/InputSelectGood';
import { Mass, PhysicalState } from '../../../../Adapter/enums';
import SelectPermit from '../../../Common/SelectPermit';
import InputPermitNumberAndDate from '../../../Common/InputPermitNumberAndDate';
import { Goods } from '../../../../../../../hooks/api/order';
import { getFormattedUnNumbers, handleUnNumbersChange } from '../../../Delivery/utils';

import styles from '../../../index.module.scss';
import { isRoleEnough } from '../../../../../../../enums/user';
import { useAuth } from '../../../../../../../store/auth';

interface ReCalcNetValueProps {
  net: number;
  innerPackagingNetUnitaryQuantity: string;
  innerPackagingQuantity: string;
}
export const reCalcNetValue = ({
  net, innerPackagingNetUnitaryQuantity, innerPackagingQuantity,
}: ReCalcNetValueProps) => {
  let value: string | number = net;

  if (innerPackagingNetUnitaryQuantity) {
    value = Number.parseFloat(innerPackagingNetUnitaryQuantity);
  }
  if (innerPackagingQuantity) {
    value = Number.parseFloat((((+value) || 0) * Number.parseFloat(innerPackagingQuantity)).toFixed(3));
  }

  return value;
};

interface ProductFormProps extends ContentProps, FormName {
  item: ClientDataGood;
}

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);

export default function ProductForm({ formName, item, index }: ProductFormProps) {
  const { message } = App.useApp();
  const {
    setValidator, forms: { [formName]: form }, triggerValidationAllForm, generalForm,
    deliveryForm,
    orderTypeForm,
  } = useOrderContextForm();

  const { user } = useAuth();
  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  useEffect(() => {
    setValidator(formName, () => !form.getFieldsValue().goods?.some(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (good: Goods) => ['name', 'permit'].some(((key) => good && good[key]?.toString().length === 0)),
    ) && !(form.getFieldsValue()[formName]
      .some((data: AnyObject) => (data && data.value ? data.value === '' || data.value === undefined : false))
        || form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const userRequiredStringFields = [
    'hsCode', // 'packaging', 'materialAndCode' - Can be objects. Handled with antd required rule
  ]; // 'name', 'permit'. These fields are required for all roles, so they not in this array.
  const userRequiredNumberFields = ['quantity', 'value', 'net', 'gross', 'length', 'width', 'height', 'volume'];
  const validationRules = useMemo(() => createRulesForAntd(yup.object().shape(
    {
      [formName]: yup.array().of(
        yup.object({
          name: textFieldRequired,
          permit: textFieldRequired,
          // hsCode: yup.string().when([], {
          //   is: () => !isUserAdmin,
          //   then: textFieldRequired,
          //   otherwise: yup.string().notRequired(),
          // }),
          ...userRequiredStringFields.reduce((schema, field) => ({
            ...schema,
            [field]: yup.string().when([], {
              is: () => !isUserAdmin,
              then: textFieldRequired.nullable(),
              otherwise: yup.string().notRequired().nullable(),
            }),
          }), {}),
          ...userRequiredNumberFields.reduce((schema, field) => ({
            ...schema,
            [field]: yup.number()
              .nullable()
              // .transform((value) => (Number.isNaN(value) ? 0 : value))
              .when([], {
                is: () => !isUserAdmin,
                then: yup.number().required('Required').nullable(),
                otherwise: yup.number().nullable().notRequired(),
              }),
          }), {}),
        }),
      ),
    },
  ), form.getFieldsValue), [formName, isUserAdmin]);

  const dangerousGoods = Form.useWatch([formName, index, 'dangerousGoods'], form);
  const dangerousGoodWatch = Form.useWatch([formName, index, 'dangerousGood'], form);
  const innerPackaging = Form.useWatch([formName, index, 'innerPackaging'], form);
  const temperatureMode = Form.useWatch([formName, index, 'temperatureMode'], form);
  const specialTemperatureMode = Form.useWatch([formName, index, 'specialTemperatureMode'], form);
  const isEmptyUncleaned = Form.useWatch([formName, index, 'isEmptyUncleaned'], form);
  const currency = Form.useWatch([formName, index, 'currency'], form);
  const massUnit = Form.useWatch([formName, index, 'massUnit'], form);
  const net = Form.useWatch([formName, index, 'net'], form);
  const gross = Form.useWatch([formName, index, 'gross'], form);
  const physicalState = Form.useWatch([formName, index, 'physicalState']);
  const isGrossWeightDisable = (typeof physicalState === 'object' && physicalState
    ? physicalState.value : physicalState) === PhysicalState.LIQUID && massUnit === Mass.LITER;
  const innerPackagingNetUnitaryQuantity = Form.useWatch([formName, index, 'innerPackagingNetUnitaryQuantity'], form);
  const innerPackagingQuantity = Form.useWatch([formName, index, 'innerPackagingQuantity'], form);
  const permit = Form.useWatch([formName, index, 'permit'], form);

  const isAssistanceNeeded = Form.useWatch('isAssistanceNeeded', orderTypeForm) || false;
  const productTypeWatch: ClientProductType | '' = Form.useWatch('productType', orderTypeForm); // for customer only
  const isProductsDangerous = ['dangerous', 'both'].includes(productTypeWatch);

  useEffect(() => {
    const calcValue = reCalcNetValue({
      net, innerPackagingNetUnitaryQuantity, innerPackagingQuantity,
    });

    form.setFieldValue([formName, index, 'net'], calcValue);
  }, [innerPackagingNetUnitaryQuantity, innerPackagingQuantity, innerPackaging]);

  const calcGross = () => {
    if (isGrossWeightDisable) {
      const densityValue = Number.parseFloat(form.getFieldValue([formName, index, 'density'])) || 1;
      const netValue = Number.parseFloat(form.getFieldValue([formName, index, 'net']));
      const value = netValue * densityValue;

      form.setFieldValue([formName, index, 'gross'], Number.isNaN(value) ? '' : value);
    }
  };

  const calcVolume = () => {
    form.setFieldValue(
      [formName, index, 'volume'],
      Number.parseFloat((
        ((Number.parseFloat(form.getFieldValue([formName, index, 'length'])) || 0) / 100)
        * ((Number.parseFloat(form.getFieldValue([formName, index, 'width'])) || 0) / 100)
        * ((Number.parseFloat(form.getFieldValue([formName, index, 'height'])) || 0) / 100)
      ).toFixed(3)),
    );
    triggerValidationAllForm();
    setTimeout(() => form.validateFields([[formName, index, 'volume']]), 100);
  };

  /** Sync currency value between forms. Check both 'admin' and 'client' view forms  */
  const insuranceCurrencyWatch = Form.useWatch('insuranceCurrency', generalForm);
  const insuranceCurrencyDeliveryWatch = Form.useWatch('insuranceCurrency', deliveryForm);

  useEffect(() => {
    if (insuranceCurrencyWatch || insuranceCurrencyDeliveryWatch) {
      form.setFieldValue([formName, index, 'currency'], insuranceCurrencyWatch || insuranceCurrencyDeliveryWatch);
    }
  }, [insuranceCurrencyWatch, insuranceCurrencyDeliveryWatch]);

  return (
    <div className={styles.productForm}>
      <h3>
        <span>General info</span>
      </h3>
      {isUserAdmin ? (
        <>
          <OrderSwitch
            rest={item}
            label="Dangerous goods"
            prefix={formName}
            name={[index, 'dangerousGoods']}
          />
          <OrderSwitch
            rest={item}
            label="Inner packaging"
            prefix={formName}
            name={[index, 'innerPackaging']}
          />
        </>
      ) : null}
      <InputSelectGood
        item={item}
        formName={formName}
        index={index}
        validationRules={validationRules}
        name={[index, 'name']}
      />
      {(isUserAdmin && dangerousGoods) || (user?.role === 'user' && isProductsDangerous) ? (
        <SelectInputDangerousGoods
          formName={formName}
          rest={item}
          label="Dangerous goods"
          prefix={formName}
          name={[index, 'dangerousGood']}
          rightText="DGA, PI, Delivery"
          onSelectRecord={(record) => {
            if (record?.name) {
              form.setFieldValue([formName, index, 'dangerousGoods'], true);

              /** If user changes dangerous goods, and productType not dangerous - make it  */
              const prevValue = orderTypeForm.getFieldValue('productType');

              if (!prevValue || prevValue === 'regular') {
                message.info('"Product type" changed to "Dangerous" because you selected dangerous goods');
                orderTypeForm.setFieldValue('productType', 'dangerous');
              }
            }
          }}
          inputProps={{
            suffix: dangerousGoodWatch ? ( // addonAfter
              <CloseCircleOutlined
                onClick={() => {
                  form.setFieldValue([formName, index, 'dangerousGoods'], false);
                  form.setFieldValue([formName, index, 'dangerousGood'], null);
                  form.setFieldValue([formName, index, 'dangerousGood_view'], null);
                  form.setFieldValue([formName, index, 'packagingGroup'], null);
                }}
              />
            ) : null,
          }}
        />
      ) : null}
      {isUserAdmin && innerPackaging ? (
        <OrderInput
          rest={item}
          inputProps={{
            disabled: true,
          }}
          label="Packaging group"
          prefix={formName}
          name={[index, 'packagingGroup']}
          rightText="DGA, PI, Delivery"
        />
      ) : null}
      <SelectHSCode
        rest={item}
        label="HS code"
        prefix={formName}
        name={[index, 'hsCode']}
        rightText="PI, Delivery"
        // rules={isUserAdmin || isAssistanceNeeded ? undefined : [{ required: true, message: 'Required' }]}
      />
      {isUserAdmin ? (
        <OrderInput
          rest={item}
          label="English Tech. Name"
          prefix={formName}
          name={[index, 'englishTechName']}
          rightText="DGA"
        />
      ) : null}
      <OrderSwitch
        rest={item}
        label="Save Product To Database"
        prefix={formName}
        name={[index, 'saveProductToDatabase']}
      />

      <OrderInput
        rest={item}
        textarea
        label="Item Description"
        prefix={formName}
        name={[index, 'description']}
        rightText="PI"
        tooltip='Additional information for the "Item Description" field in the
          Proforma Invoice (Country of origin, Brand name, etc.).'
      />
      <SelectKindPackage
        rest={item}
        label="Kind of Package"
        prefix={formName}
        name={[index, 'packaging']}
        rightText="DGA, PI, Delivery"
        selectProps={{
          onChange: (_, option) => {
            form.setFieldValue([formName, index, 'materialAndCode'], null);
            form.setFieldValue([formName, index, 'packaging'], option || undefined);
          },
        }}
        // This field can be object, so easier to ignore yup validation and use just antd's
        rules={isUserAdmin || isAssistanceNeeded ? undefined : [{ required: true, message: 'Required' }]}
        // validationRules
      />

      <SelectMaterialCode
        rest={item}
        label="Material and Code"
        prefix={formName}
        name={[index, 'materialAndCode']}
        rightText="DGA"
      />
      {isUserAdmin ? (
        <SelectEmptyUncleaned
          rest={item}
          label="Is Empty Uncleaned?"
          prefix={formName}
          name={[index, 'isEmptyUncleaned']}
          rightText="DGA"
        />
      ) : null}

      <h3>
        <span>Parameters</span>
      </h3>
      <OrderInput
        rest={item}
        inputNumber
        label="Number of Packages"
        prefix={formName}
        name={[index, 'quantity']}
        rightText="DGA, PI, Delivery"
        inputNumberProps={{
          onChange: calcVolume,
          precision: 0,
        }}
        rules={isUserAdmin || isAssistanceNeeded ? undefined : [validationRules, { required: true, message: <div /> }]}
      />
      <Form.Item name={[index, 'currency']} initialValue="CHF" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item
        {...item}
        label="Value"
        name={[index, 'value']}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        rules={isUserAdmin || isAssistanceNeeded ? undefined : [validationRules, { required: true, message: <div /> }]}
      >
        <InputNumber
          min={0}
          addonAfter={(
            <Select
              value={currency || 'EUR'}
              onChange={(value) => {
                form.setFieldValue([formName, index, 'currency'], value);
                generalForm.setFieldValue('insuranceCurrency', value); // Sync currency value between forms
                deliveryForm.setFieldValue('insuranceCurrency', value);
                triggerValidationAllForm();
              }}
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
            )}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item name={[index, 'massUnit']} initialValue="kg" style={{ display: 'none' }}>
        <Input onChange={calcGross} />
      </Form.Item>

      {!(typeof isEmptyUncleaned === 'object' ? isEmptyUncleaned?.value : isEmptyUncleaned) ? (
        <Form.Item
          {...item}
          label="Net Quantity per Package"
          name={[index, 'net']}
          extra={(
            <div className={styles.text}>
              DGA
            </div>
          )}
          normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          rules={isUserAdmin || isAssistanceNeeded
            ? undefined : [validationRules, { required: true, message: <div /> }]}
        >
          <InputNumber
            disabled={innerPackaging}
            onChange={calcGross}
            min={0}
            addonAfter={(
              <Select
                value={massUnit || 'kg'}
                onChange={(value) => {
                  form.setFieldValue([formName, index, 'massUnit'], value);
                  calcGross();
                  triggerValidationAllForm();
                }}
              >
                <Select.Option value={Mass.KG}>
                  {Mass.KG}
                </Select.Option>
                <Select.Option value={Mass.LITER}>
                  {Mass.LITER}
                </Select.Option>
              </Select>
            )}
            style={{ width: '100%' }}
          />
        </Form.Item>
      ) : null}

      <OrderInput
        rest={item}
        label="Gross Weight per Package"
        prefix={formName}
        name={[index, 'gross']}
        rightText="Delivery"
        inputNumber
        inputNumberProps={{
          status: !isGrossWeightDisable && gross && Number.parseFloat(gross) && net > gross ? 'error' : undefined,
          suffix: 'kg',
          controls: false,
        }}
        normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
        rules={isUserAdmin || isAssistanceNeeded
          ? undefined : [validationRules, { required: true, message: <div /> }]}
      />

      <Form.Item
        label={isUserAdmin || isAssistanceNeeded ? 'Length' : (
          <>
            Length
            <span style={{ color: 'red' }}> *</span>
          </>
        )}
        className={styles.sizeLWH}
        extra={(
          <div className={styles.text}>
            DGA
          </div>
        )}
      >
        <OrderInput
          rest={item}
          name={[index, 'length']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin || isAssistanceNeeded
            ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
        <OrderInput
          rest={item}
          label="Width"
          name={[index, 'width']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin || isAssistanceNeeded
            ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
        <OrderInput
          rest={item}
          label="Height"
          name={[index, 'height']}
          rightText={false}
          inputNumber
          inputNumberProps={{
            addonAfter: 'cm',
            className: 'addon-sm-padding',
            onChange: calcVolume,
          }}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
          rules={isUserAdmin || isAssistanceNeeded
            ? undefined : [validationRules, { required: true, message: <div /> }]}
        />
      </Form.Item>

      <OrderInput
        rest={item}
        label="Volume per Package"
        prefix={formName}
        name={[index, 'volume']}
        rightText="Delivery"
        inputNumber
        inputNumberProps={{
          suffix: (
            <>
              m
              <sup>3</sup>
            </>
          ),
          controls: false,
        }}
        rules={isUserAdmin || isAssistanceNeeded
          ? undefined : [validationRules, { required: true, message: <div /> }]}
      />

      {isUserAdmin && innerPackaging ? (
        <>
          <h3>
            <span>Inner packaging</span>
          </h3>
          <OrderInput
            inputNumber
            rest={item}
            label="Number of inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingQuantity']}
            rightText="DGA"
            inputNumberProps={{
              precision: 0,
            }}
          />
          <SelectInnerPackagingType
            rest={item}
            label="Inner packaging"
            prefix={formName}
            name={[index, 'innerPackagingType']}
            rightText="DGA"
          />
          <OrderInput
            rest={item}
            label="Net unitary quantity inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingNetUnitaryQuantity']}
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: massUnit || 'kg',
              controls: false,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
          <OrderInput
            rest={item}
            label="Net explosive mass per inner packagings"
            prefix={formName}
            name={[index, 'innerPackagingNetExposiveQuantity']} // TODO check naming is wrong
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: 'kg',
              controls: false,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
        </>
      ) : null}

      {isUserAdmin ? (
        <>
          <h3>
            <span>Goods properties</span>
          </h3>
          <SelectEnvironmentallyHazardous
            rest={item}
            label="Environmentally Hazardous"
            prefix={formName}
            name={[index, 'environmentallyHazardous']}
            rightText="DGA"
          />
          <SelectPhysicalState
            rest={item}
            label="Physical State"
            prefix={formName}
            name={[index, 'physicalState']}
            rightText="DGA"
            selectProps={{
              onChange: calcGross,
            }}
          />
          <OrderInput
            rest={item}
            label="Density"
            prefix={formName}
            name={[index, 'density']}
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: 'kg/L',
              controls: false,
              onChange: calcGross,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
          <SelectPureMixSolution
            form={form}
            className={styles.doubleInput}
            rest={item}
            label="Pure, Mix or Solution"
            prefix={formName}
            name={[[index, 'pureMixSolution'], [index, 'pureMixSolutionPercentage']]}
            rightText="DGA"
          />
          <OrderInput
            rest={item}
            label="Net explosive mass per package"
            prefix={formName}
            name={[index, 'netExplosivePerPackage']}
            rightText="DGA"
            inputNumber
            inputNumberProps={{
              suffix: 'kg',
              controls: false,
            }}
            normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
          />
          <OrderInput
            rest={item}
            label="Flash Point"
            prefix={formName}
            name={[index, 'flashPoint']}
            rightText="DGA"
            inputProps={{
              suffix: 'ºC c.c.',
            }}
          />
          {/* <SelectSegregation */}
          {/*  rest={item} */}
          {/*  label="Segregation" */}
          {/*  prefix={formName} */}
          {/*  name={[index, 'segregation']} */}
          {/*  rightText="DGA" */}
          {/* /> */}
          <SelectSegregationGroup
            rest={item}
            label="Segregation group"
            prefix={formName}
            name={[index, 'segregationGroup']}
            rightText="DGA"
          />
        </>
      ) : null}

      <h3>
        <span>Export/Import permit</span>
      </h3>
      <SelectPermit
        rest={item}
        label="Permit"
        prefix={formName}
        name={[index, 'permit']}
        rules={[validationRules, { required: true, message: <div /> }]}
        rightText="Delivery"
      />
      {permit === 'obligation' ? (
        <InputPermitNumberAndDate
          form={form}
          className={styles.doubleInput}
          rest={item}
          label="Permit Number & Date"
          prefix={formName}
          name={[[index, 'permitNumber'], [index, 'permitDate']]}
          rightText="Delivery"
        />
      ) : null}
      {/* <SelectExportLicense */}
      {/*  rest={item} */}
      {/*  label="Export License" */}
      {/*  prefix={formName} */}
      {/*  name={[index, 'exportLicense']} */}
      {/*  rightText="Delivery" */}
      {/* /> */}
      {dangerousGoods ? (
        <OrderUpload
          formName={formName}
          rest={item}
          label="Upload MSDS/SDS"
          prefix={formName}
          name={[index, 'msdsDocument']}
          rightText="Delivery"
          uploadProps={{
            maxCount: 1,
          }}
        />
      ) : null}

      {isUserAdmin || isAssistanceNeeded ? (
        <>
          <h3>
            <span>Additional services</span>
          </h3>
          <OrderSwitch
            rest={item}
            label="Temperature Logger"
            prefix={formName}
            name={[index, 'temperatureLogger']}
          />
          <OrderSwitch
            rest={item}
            label="Real Time Monitoring"
            prefix={formName}
            name={[index, 'realTimeMonitoring']}
          />
          <OrderSwitch
            rest={item}
            label="Special temperature mode"
            prefix={formName}
            name={[index, 'specialTemperatureMode']}
          />
          {specialTemperatureMode ? (
            <>
              <SelectSpecialTemperatureMode
                rest={item}
                label="Special temperature mode"
                prefix={formName}
                name={[index, 'temperatureMode']}
              />
              {temperatureMode === 'FROBO79 (Frozen Box UN 1845 -79 °C)' ? (
                <OrderInput
                  rest={item}
                  label="Net weight of Dry Ice"
                  prefix={formName}
                  name={[index, 'netWeightOfDryIce']}
                  inputNumber
                  inputNumberProps={{
                    suffix: 'kg',
                    controls: false,
                  }}
                  normalize={(value) => value && Number.parseFloat(value.toFixed(3))}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
