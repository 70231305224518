import React from 'react';
import { config } from '@triare/auth-redux';
import { Tabs, TabsProps } from 'antd';
import SignUpContent from '../../../components/Pages/Auth/SignUp';
import Layout from '../../../components/Layout';
import FullRows from '../../../components/Pages/Auth/SignUp/FullRows';
import PhoneAndPassword from '../../../components/Pages/Auth/SignUp/PhoneAndPassword';
import EmailNameAndPassword from '../../../components/Pages/Auth/SignUp/EmailNameAndPassword';
import Phone from '../../../components/Pages/Auth/SignUp/Phone';
import Email from '../../../components/Pages/Auth/SignUp/Email';
import Simple from '../../../components/Layout/Simple';

const { displayName } = config;

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Email & Password',
    children: <SignUpContent content={EmailNameAndPassword} />,
  },
  {
    key: '2',
    label: 'Email',
    children: <SignUpContent content={Email} />,
  },
  {
    key: '3',
    label: 'Phone',
    children: <SignUpContent content={Phone} />,
  },
  {
    key: '4',
    label: 'Phone & Password',
    children: <SignUpContent content={PhoneAndPassword} />,
  },
  {
    key: '5',
    label: 'Full info',
    children: <SignUpContent content={FullRows} />,
  },
];

function SignUp(): React.ReactNode {
  document.title = `${displayName}: Sign up`;

  return (
    /* <Layout
      title="bla"
    >
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey="1"
        centered
        items={items}
        style={{ padding: '0 16px 16px 16px' }}
      />
    </Layout> */
    <Simple>
      <SignUpContent content={EmailNameAndPassword} />
    </Simple>
  );
}

export default SignUp;
