/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Dropdown, MenuProps, Modal,
  Spin,
  Tag,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DownloadOutlined, EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { downloadFromAnchor, getUserRequestPath } from '../../../../utils';
import { useAuth } from '../../../../store/auth';
import { ClientData, getClientData } from '../../Orders/Adapter';
import { Order } from '../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import { getOffersStatusColor } from '..';
import {
  Offer, OfferActionEnum, OfferStatusType,
  useOfferClientAction,
  useOfferDownload, useOfferResend, useOffersDelete, useOffersGetById,
} from '../../../../hooks/api/offers';
import { InvoiceFooterProps, InvoiceModalProps } from '../../Invoices/View';
import InvoiceDetailsContainer from '../../Invoices/View/DetailsContainer';

import styles from './index.module.scss';
import { isRoleEnough } from '../../../../enums/user';
import { DeclinationReasonModalButton } from './ DeclinationReason';
import { useOrderContext } from '../../Orders/View/context';

interface HeaderTitleProps {
  status: string;
}

export function HeaderTitle({ status }: HeaderTitleProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
      <div className="ant-modal-title">Offer Details</div>
      <Tag color={getOffersStatusColor(status)} style={{ fontWeight: '400' }}>
        {status}
      </Tag>
    </div>
  );
}

function FooterActions({
  orderId, itemId, itemNumber, itemData, handleClose, setTableKey, afterAction, afterDelete,
}: InvoiceFooterProps<Offer>) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const { order } = useOrderContext();
  const offerResend = useOfferResend(getUserRequestPath(user?.role), itemId);
  const offerDelete = useOffersDelete(getUserRequestPath(user?.role), itemId);
  const downloadOffer = useOfferDownload(getUserRequestPath(user?.role), itemId, orderId);
  const offerClientAction = useOfferClientAction(getUserRequestPath(user?.role), orderId, itemId, OfferActionEnum.ACCEPT);
  const [isModalOpen, setModalOpen] = useState(false);

  useMessageError([downloadOffer, offerDelete, offerResend, offerClientAction]);
  useMessageSuccess([offerDelete], 'Offer deleted successfully');
  useMessageSuccess([downloadOffer], 'Download successfully');
  useMessageSuccess([offerClientAction], 'Offer accept successfully');

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
  };

  const isUserAdmin = isRoleEnough(user?.role, 'admin');
  const status = itemData?.offerStatus || 'pending';

  useEffect(() => {
    if (!offerDelete.error && !offerDelete.loading && offerDelete.data) {
      setTableKey?.(Date.now());
      handleClose();
    }
  }, [
    offerDelete.error, offerDelete.loading, offerDelete.data,
  ]);

  useEffect(() => {
    if (!offerClientAction.error && !offerClientAction.loading && offerClientAction.data) {
      order.fetch();
    }
  }, [offerClientAction.data]);

  useEffect(() => {
    if (afterAction && (
      (offerDelete.data && !offerDelete.error && !offerDelete.loading)
    )) {
      afterAction();
      afterDelete?.();
      offerDelete.clearResponse();
    }
  }, [afterAction, offerDelete.response]);

  const getOfferActions = (itemStatus: OfferStatusType) => {
    const commonItems: MenuProps['items'] = [
      {
        key: 'Resend',
        label: 'Resend',
        onClick: ({ domEvent }: any) => {
          domEvent.preventDefault();
          offerResend.fetch();
        },
      },
      {
        key: 'Download',
        label: 'Download',
        onClick: ({ domEvent }: any) => {
          domEvent.preventDefault();
          downloadOffer.fetch().then((response) => {
            if (!response) return;
            downloadFromAnchor(response, `offer_${itemNumber}`, 'application/pdf');
          });
        },
      },
    ];

    if (itemStatus !== 'accepted') {
      commonItems.push({
        key: 'Delete',
        label: 'Delete',
        danger: true,
        onClick: () => {
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete offer?',
            content: <span>{`Are you sure you want to delete offer ${itemNumber}?`}</span>,
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: () => {
              offerDelete.fetch();
            },
          });
        },
      });
    }

    return commonItems;
  };

  const offerDropdown = (
    <Dropdown
      menu={{ items: getOfferActions(status) }}
      placement="bottomRight"
      arrow
    >
      <Button icon={<EllipsisOutlined />} style={{ transform: 'translate(0px, 1.5px)' }} />
    </Dropdown>
  );

  return (
    <div className={styles.footerActions}>
      <div>
        {isUserAdmin
          ? (
            <>
              <Button
                type="primary"
                style={{ marginRight: '12px' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/orders/${orderId}`);
                }}
              >
                View order
              </Button>
              {offerDropdown}
            </>
          )
          : (
            <>
              {(status !== 'declined' && status !== 'accepted')
                ? (
                  <>
                    <DeclinationReasonModalButton
                      offerId={itemId}
                      text="Decline"
                      isModalOpen={isModalOpen}
                      handleClick={handleClick}
                      handleCloseModal={handleCloseModal}
                      closeOfferModal={handleClose}
                    />
                    <Button
                      type="primary"
                      style={{ margin: '0 12px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        offerClientAction.fetch();
                        handleClose();
                      }}
                    >
                      Accept
                    </Button>
                  </>
                )
                : null}
              <Button
                icon={<DownloadOutlined />}
                loading={downloadOffer.loading}
                onClick={(e) => {
                  e.preventDefault();
                  downloadOffer.fetch().then((response) => {
                    if (!response) return;
                    downloadFromAnchor(response, `offer_${itemNumber}`, 'application/pdf');
                  });
                }}
              />
            </>

          )}
        {contextHolder}
      </div>
    </div>
  );
}

function OffersModal({
  showFooter = false, id, isModalOpen, setTableKey, handleClose, afterAction, afterDelete, dataSource,
}: InvoiceModalProps<Offer>) {
  const { user } = useAuth();
  /** Offer by id available only for admin */
  const offersGetById = useOffersGetById(getUserRequestPath(user?.role), id);
  const [orderData, setOrderData] = useState<ClientData | undefined>({} as ClientData);

  useEffect(() => {
    if (id && !dataSource) {
      offersGetById.fetch(undefined, id);
    }
  }, [id, dataSource]);

  useEffect(() => {
    if (offersGetById.data || dataSource?.order) {
      setOrderData(getClientData(
        offersGetById.data?.order
        || dataSource?.order
        || {} as Order,
        'edit',
      ));
    }
  }, [offersGetById.data, dataSource]);

  useMessageError([offersGetById]);

  const offerData = offersGetById.data || dataSource;

  const {
    number,
    createdAt,
    dueDate,
    offerStatus = 'pending',
  } = offerData || {};
  const {
    companyName,
    email,
    address1,
    address2,
    postalCode,
    city,
    country,
  } = orderData?.company || {};
  const {
    reference,
  } = orderData?.shipper || {};

  return (
    <Modal
      title={<HeaderTitle status={offerStatus as string} />}
      open={isModalOpen}
      centered
      closable={!showFooter}
      maskClosable={false}
      width={1000}
      footer={false}
      onCancel={() => {
        handleClose();
      }}
    >
      <Divider />
      <div>
        <div className={styles.invoiceGrid}>
          <div className={styles.invoiceHeader}>
            {number ? (
              <h2>{`OFFER ${number}`}</h2>
            ) : null}
            <img src="/logo.png" alt="Logo" />
          </div>

          <div className={styles.invoiceInfo} style={{ marginTop: '50px' }}>
            <div className={styles.column}>
              <div>
                <strong>Created date:</strong>
                {createdAt ? (
                  <span>{dayjs(createdAt).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Due date:</strong>
                {dueDate ? (
                  <span>{dayjs(dueDate).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Shipper reference:</strong>
                {reference ? (
                  <span>{reference}</span>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Contact Email:</strong>
                <span>accounting@sephyre.com</span>
              </div>
            </div>

            <div className={styles.column}>
              {companyName ? (
                <span>{companyName}</span>
              ) : null}
              {/* {email ? (
                <span>{email}</span>
              ) : null} */}
              {address1 ? (
                <span>{address1}</span>
              ) : null}
              {address2 ? (
                <span>{address2}</span>
              ) : null}
              {postalCode ? (
                <span>{postalCode}</span>
              ) : null}
              {city ? (
                <span>{city}</span>
              ) : null}
              {country ? (
                <span>{country}</span>
              ) : null}
            </div>
            <div className={styles.column} />

            <InvoiceDetailsContainer.ShippingDetails
              invoice={offerData ? { ...offerData, status: offerData?.offerStatus || '' } : undefined}
              orderData={orderData}
              simplified
            />
          </div>
          <InvoiceDetailsContainer.Total
            invoice={offerData ? { ...offerData, status: offerData?.offerStatus || '' } : undefined}
            orderData={orderData}
            simplified
          />
        </div>
        <div style={{ padding: '0 25px' }}>

          <InvoiceDetailsContainer.Table
            invoice={offerData ? { ...offerData, status: offerData?.offerStatus || '' } : undefined}
            orderData={orderData}
            simplified
          />

          <div className={styles.invoiceInfo} style={{ marginTop: '48px' }}>
            <div className={styles.column}>
              <span style={{ marginTop: '12px' }}>We remain at your disposal for any further questions</span>
              <span>Kind regards</span>
            </div>
          </div>
          <div className={styles.invoiceFooter}>
            <div className={styles.footerInfo}>
              <strong>Sephyre GmbH</strong>
              <span>CH-4450 Sissach, Switzerland  </span>
              <strong>Bank:</strong>
              <span>Raiffeisen</span>
              <strong>Account owner:</strong>
              <span>Sephyre GmbH</span>
              <strong>BIC:</strong>
              <span>RAIFCH22XXX</span>
            </div>
            <div className={styles.footerInfo}>
              <strong>IBAN:</strong>
              <span>CH16 8080 8006 3843 9480 1</span>
              <strong>VAT identification number:</strong>
              <span>CHE-232.506.635 MWST</span>
              <strong>Website:</strong>
              <span>sephyre.com</span>
            </div>
            <Divider />
            <div>
              <FooterActions
                orderId={offerData?.order?.id || ''}
                itemId={id}
                itemNumber={number || ''}
                itemData={offerData}
                // status={offerStatus}
                handleClose={handleClose}
                setTableKey={setTableKey}
                afterAction={afterAction}
                afterDelete={afterDelete}
              />
            </div>
          </div>
        </div>
      </div>
      {offersGetById.loading ? (
        <div className="spin-loading">
          <Spin />
        </div>
      ) : null}
    </Modal>
  );
}

export default OffersModal;
