import React from 'react';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectPermit({
  options,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  return (
    <OrderSelect
      options={options || [
        {
          value: 'obligation',
          label: 'Obligation',
        },
        {
          value: 'free',
          label: 'Free',
        },
      ]}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectPermit;
