import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';
import { Option } from '../../types';
import { RolePathType } from '../../utils';

export interface AddressBook extends AddressBookCreateParams {
  id: string;
  createdAt: string;
}

interface ParticipantsGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useParticipantsGet = <DD = AddressBookTableData>(
  rolePath: RolePathType,
  decorateData?: (data: PagingDataResponse<AddressBook>) => DD,
): FetchGet<
  PagingDataResponse<AddressBook>,
  ParticipantsGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    `${rolePath}/participants`,
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface ParticipantsAll {
  id: string;
  displayName: string;
}

export interface AddressBookTable extends AddressBook {
  key: string;
}

export interface AddressBookTableData {
  data: AddressBookTable[];
  total: number;
}

export const useAddressBookTableGet = (rolePath: RolePathType) => useParticipantsGet<AddressBookTableData>(
  rolePath,
  ({ data, meta }: PagingDataResponse<AddressBook>): AddressBookTableData => ({
    data: data.map((item: AddressBook): AddressBookTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useAddressBookOptionsGet = (rolePath: RolePathType) => useParticipantsGet<Option[]>(
  rolePath,
  ({ data }: PagingDataResponse<AddressBook>): Option[] => (
    data.map((item) => (
      { value: item?.company?.id || '', label: item?.company?.companyName || '' }
    ))
  ),
);

export const useParticipantsGetById = <DD = AddressBook>(
  id?: string,
  decorateData?: (data: AddressBook) => DD,
): FetchGetId<
  AddressBook,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    // '/admin/participants',
    id || '',
    '',
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface AddressBookCreateParams {
  displayName: string;
  contactName: string;
  companyName: string;
  phone: string;
  email: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tinNumber: string;
  registerNumber: string;
  company?: {
    companyName?: string;
    id: string
};
}

export const useParticipantCreate = (
  rolePath: RolePathType,
): FetchCreate<AddressBook, DefaultFetchError, AddressBookCreateParams> => (
  useFetchCreate(`${rolePath}/participants`)
);

export type AddressBookUpdateParams = AddressBook;

export const useParticipantUpdate = (rolePath: RolePathType, id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, AddressBookUpdateParams> => (
  useFetchUpdate(`${rolePath}/participants`, id)
);

export const useParticipantDelete = (rolePath: RolePathType, id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${rolePath}/participants`, id)
);

export const useParticipantTypeGet = (): FetchGet<string[]> => useFetchGet('participants/participant-types');
