import React from 'react';
import { usePhysicalStateGetAll } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectPhysicalState({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = usePhysicalStateGetAll();

  return (
    <OrderSelect fetch={dualUseGet} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectPhysicalState;
