import { combineReducers, configureStore, Tuple } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { Store } from 'redux';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { moduleName as authModuleName, reducer as authReducer, saga as authSaga } from './auth';
import { moduleName as userModuleName, reducer as userReducer, saga as userSaga } from './user';
import { moduleName as commonModuleName, reducer as commonReducer, saga as commonSaga } from './common';

export const sagaMiddleware = createSagaMiddleware();

const store: Store = configureStore({
  reducer: combineReducers({
    [authModuleName]: authReducer,
    [userModuleName]: userReducer,
    [commonModuleName]: commonReducer,
  }),
  middleware: () => new Tuple(sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production',
});

const storeType = (store as EnhancedStore);

export type RootState = ReturnType<typeof storeType.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(function* runSaga() {
  yield spawn(authSaga, store);
  yield spawn(commonSaga, store);
  yield spawn(userSaga);
});

export default store;
