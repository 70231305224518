import React, { useEffect } from 'react';
import {
  Button,
  DatePicker, Form, Input, InputNumber, Select,
} from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AnyObject } from '@triare/auth-redux';
import SelectCompany from './SelectCompany';
import SelectModeTransport from './SelectModeTransport';
import SelectDeliveryTerms from './SelectDeliveryTerms';
import SelectTransportInsurance from './SelectTransportInsurance';
import OrderInput from '../Common/Input';
import { FormName, useOrderContextForm } from '../context';
import OrderSwitch from '../Common/Switch';
import SelectCompanyIMDG from './SelectCompanyIMDG';
import SelectNameStatusDeclarant from './SelectNameStatusDeclarant';
import SelectPlace from './SelectPlace';
import { dateFormat, SelectYesValue } from '../../../../../contstant';
import { FormWrapper } from '../index';
import SelectDeliveryService from '../Common/SelectDeliveryService';
import SelectCountry from '../../../../Common/SelectCountry';
import SelectBillingCodeType from './SelectBillingCodeType';
import RangePickerTime from '../../../../Common/RangePickerTime';

import styles from '../index.module.scss';

type GeneralProps = FormName

export default function General({ formName }: GeneralProps): React.ReactNode | null {
  const {
    setValidator, forms: { [formName]: form, goods: formGoods }, initialStates, triggerValidationAllForm, deliveryForm,
  } = useOrderContextForm();

  useEffect(() => {
    setValidator(formName, () => !(form.getFieldValue('company') === undefined
        || form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const insuranceCurrency = Form.useWatch('insuranceCurrency', form);
  const modeOfTransport = Form.useWatch('modeOfTransport', form);
  const insurance = Form.useWatch('insurance', form);

  /** Fedex & DHL delivery new info. */
  const preferredDelivery = Form.useWatch('preferredDeliveryService', form);
  const billingAccNumberWatch = Form.useWatch('billingNumber', form);

  const calcTransportInsurance = () => {
    const { goods } = formGoods.getFieldsValue();

    if (form && goods && goods.length > 0) {
      let sum = 0;

      goods.forEach((good: AnyObject) => {
        sum += (Number.parseFloat(good.value) || 0) * (Number.parseFloat(good.quantity) || 1);
      });

      form.setFieldValue('insuranceValue', sum);
    }
  };

  useEffect(() => {
    const value = form.getFieldValue('insuranceValue');

    if (Object.values(initialStates).length > 0 && (value === '' || value === null || value === undefined)) {
      calcTransportInsurance();
    }
  }, [initialStates]);

  return (
    <FormWrapper formName={formName} className={styles.wrapper}>
      <SelectCompany
        label="Company"
        name="company"
        rules={[{ required: true, message: <div /> }]}
        style={{ marginBottom: 0 }}
      />

      <h3>
        <span>Consignment data</span>
      </h3>
      <SelectModeTransport
        label="Mode of transport"
        name="modeOfTransport"
        rightText="DGA, Delivery"
      />
      <Form.Item
        label="Consignment date"
        name="consignmentDate"
        initialValue={dayjs().utc()}
        extra={(
          <div className={styles.text}>
            DGA, PI, Delivery
          </div>
        )}
      >
        <DatePicker format={dateFormat} defaultValue={dayjs().utc()} />
      </Form.Item>
      <Form.Item
        label="Pickup date"
        name="pickupDate"
        initialValue={dayjs().utc()}
        extra={<div className={styles.text} />}
      >
        <DatePicker
          format={dateFormat}
          defaultValue={dayjs().utc()}
          onChange={(date) => {
            if (!date) {
              form.setFieldValue('pickupTime', null);
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label="Pickup time"
        name="pickupTime"
        extra={<div className={styles.text} />}
      >
        <RangePickerTime />
      </Form.Item>
      <OrderSwitch
        label="Urgent"
        name="urgent"
      />
      <SelectDeliveryTerms
        label="Delivery terms"
        name="deliveryTerms"
        rightText="PI, Delivery"
      />
      <SelectTransportInsurance
        label="Transport insurance"
        name="insurance"
        rightText="PI, Delivery"
      />
      <Form.Item
        style={{ display: 'none' }}
        initialValue="CHF"
        name="insuranceCurrency"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Transport insurance value"
        name="insuranceValue"
        className={styles.btnCalc}
        extra={(
          <>
            <Button
              disabled={insurance !== SelectYesValue.value}
              style={{
                marginLeft: 8, padding: '2px 0 0', minWidth: 32, minHeight: 32,
              }}
              icon={<CalculatorOutlined />}
              onClick={() => {
                calcTransportInsurance();
                triggerValidationAllForm();
              }}
            />
            <div className={styles.text}>
              PI, Delivery
            </div>
          </>
        )}
        normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
      >
        <InputNumber
          disabled={insurance !== SelectYesValue.value}
          addonAfter={(
            <Select
              value={insuranceCurrency}
              onChange={(value) => {
                form.setFieldValue('insuranceCurrency', value);
                setTimeout(() => form.validateFields(), 0);
              }}
              disabled={insurance !== SelectYesValue.value}
              defaultValue="CHF"
            >
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="CHF">CHF</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          )}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <OrderInput
        label="Waybill No."
        name="waybillNo"
        rightText="PI"
      />

      <SelectDeliveryService
        optionsType="preferredDelivery"
        label="Preferred delivery service"
        name="preferredDeliveryService"
        rules={[{ required: false, message: <div /> }]}
        rightText="Delivery"
        selectProps={{
          onChange: () => {
            ['billingNumberCountryCode', 'billingCode', 'billingType'].forEach((fieldName) => {
              form.setFieldValue(fieldName, null);
            });
          },
        }}
      />
      <OrderInput
        label="Billing account number"
        name="billingNumber"
        rightText="Delivery"
      />
      {(preferredDelivery === 'fedex' && billingAccNumberWatch) ? (
        <>
          <SelectCountry
            label="Billing account country"
            name="billingNumberCountryCode"
            valueKey="code"
            // rightText="Delivery"
          />
          <SelectBillingCodeType
            fetchPath="billing-codes"
            label="Billing code"
            name="billingCode"
            rightText="Delivery"
          />
          <SelectBillingCodeType
            fetchPath="billing-types"
            label="Billing type"
            name="billingType"
            rightText="Delivery"
          />
        </>
      ) : null}

      <OrderInput
        label="Important notes for Sephyre"
        name="notes"
        textarea
      />

      {modeOfTransport && modeOfTransport.toString().trim().toLowerCase().includes('(iata)') ? (
        <>
          <h3>
            <span>IATA data</span>
          </h3>
          <OrderInput
            label="Name of Signatory"
            name="companyName"
            rightText="DGA"
          />
          <Form.Item
            label="Date"
            name="dataDate"
            initialValue={dayjs().utc()}
            extra={(
              <div className={styles.text}>
                DGA
              </div>
            )}
          >
            <DatePicker format={dateFormat} defaultValue={dayjs().utc()} />
          </Form.Item>
        </>
      ) : null}

      {modeOfTransport && modeOfTransport.toString().trim().toLowerCase().includes('(imdg-imo)') ? (
        <>
          <h3>
            <span>IMDG-IMO data</span>
          </h3>
          <SelectCompanyIMDG
            label="Name of company"
            name="companyName"
            rightText="DGA"
          />
          <SelectNameStatusDeclarant
            label="Name and status of declarant"
            name="declarant"
            rightText="DGA"
          />
          <Form.Item
            label="Date"
            name="dataDate"
            initialValue={dayjs().utc()}
            extra={(
              <div className={styles.text}>
                DGA
              </div>
            )}
          >
            <DatePicker format={dateFormat} defaultValue={dayjs().utc()} />
          </Form.Item>
          <SelectPlace
            label="Place"
            name="placeName"
            rightText="DGA"
          />
        </>
      ) : null}
    </FormWrapper>
  );
}
