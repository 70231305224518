import React from 'react';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { usePlaceGetAll } from '../../../../../../hooks/api/order';

function SelectPlace({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = usePlaceGetAll();

  return (
    <OrderSelect
      fetch={dualUseGet}
      selectProps={selectProps}
      rest={rest}
      {...props}
    />
  );
}

export default SelectPlace;
