/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */

import {
  config,
  State as AuthState,
  setActions,
  saga as authSaga,
  updateConfig,
  getLocalState,
  Action,
  FETCH_ME_AFTER_SUCCESS_AUTHORIZATION, createUrl, reducers, updateStorageSaga, refreshTokenSaga,
} from '@triare/auth-redux';
import { createSlice } from '@reduxjs/toolkit';
import {
  spawn, takeLatest, put, select, call,
} from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { OTPResponse } from '@triare/auth-redux/dist/saga/auth/OTP';
import { SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { UrlObject } from '@triare/auth-redux/src/config';
import { PayloadAction } from '@triare/auth-redux/src/saga/auth';
import { AnyObject } from '@triare/auth-redux/src/saga/common';
import { User as AuthUser, userMeRequestSaga } from '@triare/auth-redux/dist/saga/auth/useMe';
import { signOutRequestSaga } from '@triare/auth-redux/dist/saga/auth/signOut';
import { RootState } from '../index';
import { Company } from '../../hooks/api/company';

export * from '@triare/auth-redux';

export default updateConfig({
  name: 'sephyre',
  displayName: 'Sephyre',
  fetchDelay: Number.parseInt(process.env.REACT_APP_FETCH_DELAY || '0', 10),
  api: {
    url: process.env.REACT_APP_API || '',
  },
  signIn: {
    ...config.signIn,
    requestBody: {
      byEmail: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byUsername: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byPhone: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
      byService: ({ method, remember, ...data }: SignInAction) => JSON.stringify(data),
    },
    fetchMeAfterSuccess: FETCH_ME_AFTER_SUCCESS_AUTHORIZATION,
  },
  signUp: {
    ...config.signUp,
    url: 'auth/sign-up-as-company-admin',
  },
  OTP: {
    ...config.OTP,
    fetchMeAfterSuccess: false,
  },
  createUrl: (
    url: string | UrlObject,
    addToUrl?: string,
    payload?: PayloadAction,
    data?: AnyObject,
    _query?: AnyObject,
  ) => createUrl(url, addToUrl, payload, data, {
    ..._query,
    // lang: 'en',
  }),
});

export const moduleName = config.auth.name;

export type UserRole = 'user' | 'admin' | 'root';

export interface User extends AuthUser {
  company: null | Company;
  fullName: string;
  email: string;
  role: UserRole;
  status: UserStatusType;
  settings: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
  isCompanyAccepted?: false;
  isTermsOfServiceAccepted?: false;
}

export type UserStatusType = 'active' | 'deactivated' | 'pending' | 'archived' | 'blocked';

export interface State extends AuthState {
  user: User | null;
  secretKey: string;
}

export function useAuth(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export const auth = createSlice({
  name: moduleName,
  initialState: getLocalState<State>(),
  reducers: {
    ...reducers,
    signInSuccess: (state, { payload }) => {
      Object.assign(state, payload);

      state.signIn.loading = false;
      state.signIn.response = payload || null;
      state.authorized = !!(payload.access && payload.refresh);

      if (payload?.remember !== undefined) {
        state.remember = payload?.remember;
      }
    },
  },
});

export const { reducer, actions } = auth;

setActions(actions);

function* selectAuthState() {
  return (yield select((state: RootState) => state[config.auth.name])) as State;
}

function* handleConfirmSuccessSaga() {
  yield call(updateStorageSaga); // Update storage with new tokens
  // yield call(refreshTokenSaga);
  yield call(userMeRequestSaga); // Fetch user after confirm-success.
}

export function* saga(store: EnhancedStore) {
  yield spawn(authSaga, store);

  yield takeLatest(actions.OTPSuccess.toString(), function* trigger({ payload }: Action<OTPResponse>) {
    yield put({
      type: actions.signInSuccess.toString(),
      payload,
    });
  });

  /** Updates storage with token after confirmSuccess */
  yield takeLatest([
    config.actions.confirmSuccess.toString(),
  ], handleConfirmSuccessSaga);

  /** If user-me fails - trigger refresh-token */
  yield takeLatest([
    config.actions.userMeError.toString(),
  ], refreshTokenSaga);

  // yield takeLatest(actions.userMeSuccess.type, function* trigger() {
  //   const authData = (yield getState()) as State;
  //
  //   if (authData && authData.user && authData.user.role === UserRoles.GUEST) {
  //     yield put(signOut());
  //
  //     window.location.href = '/sign-up';
  //   }
  // });
}
