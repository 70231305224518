import React from 'react';
import { useKindPackageGetAll } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectKindPackage({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const dualUseGet = useKindPackageGetAll();

  return (
    <OrderSelect fetch={dualUseGet} selectProps={selectProps} rest={rest} {...props} />
  );
}

export default SelectKindPackage;
