import React from 'react';

export const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
  <>
    {label}
    &nbsp;
    {required && <span style={{ color: 'red' }}>*</span>}
  </>
);

export default {
  customizeRequiredMark,
};
