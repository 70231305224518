import React, { createContext, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { SimpleProviderProps } from '../../../../types';

export interface AdminAccountContextProps {
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue: AdminAccountContextProps = {
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const AdminAccountContext = createContext<AdminAccountContextProps>(defaultValue);

function AdminAccountProvider({
  children,
}: SimpleProviderProps) {
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  const memoizedValue: AdminAccountContextProps = useMemo(() => ({
    form,
    setForm,
    isValid,
    setValid,
  }), [form, setForm, isValid, setValid]);

  return (
    <AdminAccountContext.Provider
      value={memoizedValue}
    >
      {children}
    </AdminAccountContext.Provider>
  );
}

export default AdminAccountProvider;

export const useAdminAccountContext = ():
  AdminAccountContextProps => React.useContext(AdminAccountContext);
