import { useCallback, useEffect, useState } from 'react';
import { App, Progress, UploadFile } from 'antd';
import { useDownloadFile } from './file';
import { downloadFromAnchor } from '../utils';
import { useMessageError } from './common';

export default function useDownloadNotification(percentCompleted: number | null, key: string) {
  const { notification } = App.useApp();

  useEffect(() => {
    if (percentCompleted) {
      notification.open({
        key,
        message: 'Downloading',
        description: <Progress showInfo={false} percent={percentCompleted} />,
        placement: 'bottomRight',
        duration: 0, // Keep the notification open until destroyed
        closable: false,
      });
    } else {
      const timeoutId = setTimeout(() => notification.destroy(key), 500);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [percentCompleted, key]);
}

/** Hook to download file from API with progress-bar */
export function useFileDownload(fileMimeType = 'application/pdf') {
  const { message } = App.useApp();
  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);

  const abortController = new AbortController();

  const downloadFile = useDownloadFile(abortController, (progressEvent) => {
    setPercentCompleted(Math.round((progressEvent.loaded / (progressEvent?.total || 0)) * 100));
  });

  const handleDownload = useCallback((file: UploadFile | { uid: string, name: string, url: string }) => {
    if (!file.url) return; // !file.uid
    if (file.uid?.includes('rc-upload')) {
      message.error('You can not download file which you just uploaded. Save order first');

      return;
    }

    downloadFile.fetch(undefined, file.uid)
      .then((blob) => {
        if (blob) {
          downloadFromAnchor(blob, file.name || 'document', fileMimeType);
        }
      })
      .catch((error) => {
        console.error('Error downloading the file', error);
      })
      .finally(() => setPercentCompleted(null));
  }, [downloadFile]);

  useMessageError([downloadFile]);

  /** Use the custom notification hook for showing progress */
  useDownloadNotification(percentCompleted, 'fileLoading');

  return { handleDownload, percentCompleted };
}
