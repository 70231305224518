import React, { useEffect, useState } from 'react';
import {
  Form as AntdForm, Input, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signUp as signUpAction, SignUpAction } from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Form from '../Form';
import {
  createRulesForAntd,
  validationSignUpCompanyAdmin,
} from '../../../../../utils/validations';
import { ContentProps } from '../index';
import { useAuth } from '../../../../../store/auth';
import Footer from '../Footer';
import { customizeRequiredMark } from '../../../../Common/Form/Input/common';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignUpCompanyAdmin);

interface SignUpForm {
  fullName: string;
  email: string;
  password: string;
}

interface EmailNameAndPasswordProps extends ContentProps {
  signUp: (payload: SignUpAction) => Action;
}

function EmailNameAndPassword({ signUp, onSubmit }: EmailNameAndPasswordProps): React.ReactNode {
  const { signUp: { loading, response, error } } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [signInData, setSignInData] = useState<SignUpForm | null>(null);

  useEffect(() => {
    if (!error && !loading && response && onSubmit && signInData) {
      onSubmit(signInData);
      navigate(`./information?email=${encodeURIComponent(signInData.email || '')}`);
    }
  }, [response, error, loading, onSubmit, signInData]);

  return (
    <>
      <Form<SignUpForm>
        validationRules={validationRules}
        signUp={(data) => {
          const newData = { ...data };
          const companyId = searchParams.get('companyId');

          if (companyId) {
            Object.assign(newData, { _query: { companyId } });
          }

          signUp(newData);
          setSignInData(data);
        }}
        name="signUpEmail"
        requiredMark={customizeRequiredMark}
      >
        <>
          <Typography.Paragraph strong className={styles.title}>Register as a Company Admin</Typography.Paragraph>

          <AntdForm.Item
            name="fullName"
            label={`${t('fullName')}`}
            required
            rules={[validationRules]}
          >
            <Input
              size="large"
              placeholder={t('fullName') || 'Full Name'}
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </AntdForm.Item>

          <AntdForm.Item
            name="email"
            label={`${t('email')}`}
            required
            rules={[validationRules]}
          >
            <Input
              size="large"
              placeholder={t('email') || 'Email'}
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </AntdForm.Item>

          <AntdForm.Item
            name="password"
            label={`${t('password')}`}
            rules={[validationRules]}
            required
          >
            <Input.Password
              placeholder={t('password') || 'Password'}
              size="large"
            />
          </AntdForm.Item>

          {/* <AntdForm.Item
          name="confirmPassword"
          label={`${t('confirmPassword')}`}
          required
          dependencies={['password']}
          rules={[
            validationRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Passwords that you entered do not match!');
              },
            })]}
        >
          <Input.Password
            placeholder={t('confirmPassword') || 'Confirm password'}
            size="large"
          />
        </AntdForm.Item> */}

          <Typography.Paragraph type="secondary" className={styles.footerText}>
            By creating an account, you acknowledge that you have read and agree to the
            {' '}
            <a href="https://www.sephyre.com/data-privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>
          </Typography.Paragraph>
        </>
      </Form>

      <Footer />
    </>
  );
}

export default connect(null, {
  signUp: signUpAction,
})(EmailNameAndPassword);
