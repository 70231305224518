import {
  DependencyList, EffectCallback, useEffect, useRef,
} from 'react';

export default function useComponentDidUpdate(
  effect: EffectCallback,
  dependencies: DependencyList,
): void {
  const mounted = useRef(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mounted.current) {
      return effect();
    }

    mounted.current = true;
  }, dependencies);
}
