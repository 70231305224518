import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../../../components/Layout';
import CompanyEditContent, { HeaderActions } from '../../../../components/Pages/Companies/View/Edit';
import Menu from '../../../../components/Layout/Menu';
import CompanyProvider from '../../../../components/Pages/Companies/Form/context';

const { displayName } = config;

export default function CompanyEdit(): React.ReactNode | null {
  document.title = `${displayName}: Edit customer`;

  return (
    <CompanyProvider>
      <Layout
        title="Edit customer"
        leftMenu={<Menu />}
        headerContent={<HeaderActions />}
      >
        <CompanyEditContent />
      </Layout>
    </CompanyProvider>
  );
}
