import styles from './index.module.scss';

export default function Support() {
  return (
    <div>
      <span>
        Get in touch with us for any inquiries, support, or feedback.
        {' '}
        {'We\'re'}
        {' '}
        here to help!
      </span>
      <div className={styles.info}>
        <span>
          Sephyre GmbH
          {' '}
          <br />
          PO Box
          {' '}
          <br />
          CH-4450 Sissach
          {' '}
          <br />
          Switzerland
        </span>
      </div>
      <div className={styles.contact}>
        <a href="tel:+41 61 911 16 16">+41 61 911 16 16</a>
        <a href="mailto:welcome@sephyre.com">welcome@sephyre.com</a>
      </div>
    </div>
  );
}
