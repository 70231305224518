import React from 'react';
import { Provider } from 'react-redux';
import './index.scss';

import { config } from '@triare/auth-redux';

import store from './store';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';
import BlockerDisablerProvider from './context/blockerDisabler';

const { displayName } = config;

export default function App(): React.ReactNode {
  document.title = `${displayName}`;

  return (
    <Provider store={store}>
      <AntdConfigProvider>
        <BlockerDisablerProvider>
          <Routes />
        </BlockerDisablerProvider>
      </AntdConfigProvider>
    </Provider>
  );
}
