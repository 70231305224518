import React, {
  createContext, useMemo, useState,
} from 'react';
import { asyncDelay } from '../utils';
import { SimpleProviderProps } from '../types';

export interface BlockerContextProps {
  isBlockerActive: boolean | null;
  handleIsBlockerActive: (val: boolean | null) => Promise<void>;
}

const defaultValue: BlockerContextProps = {
  isBlockerActive: null,
  handleIsBlockerActive: async () => undefined,
};

export const BlockerContext = createContext<BlockerContextProps>(defaultValue);

function BlockerDisablerProvider({ children }: SimpleProviderProps) {
  const [isBlockerDisabled, setIsBlockerDisabled] = useState<boolean | null>(null);

  const handleIsBlockerActive = async (disabled: boolean | null) => {
    setIsBlockerDisabled(disabled);
    await asyncDelay(50);
  };

  const memoizedValue: BlockerContextProps = useMemo(() => ({
    isBlockerActive: isBlockerDisabled,
    handleIsBlockerActive,
  }), [isBlockerDisabled, setIsBlockerDisabled]);

  return (
    <BlockerContext.Provider
      value={memoizedValue}
    >
      {children}
    </BlockerContext.Provider>
  );
}

export default BlockerDisablerProvider;

export const useBlockerContext = ():
  BlockerContextProps => React.useContext(BlockerContext);
