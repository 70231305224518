import React, { useEffect, useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useAirportSelectGet } from '../../../../../../hooks/api/order';
import OrderSelect, { OrderSelectProps } from '../Select';

function SelectAirport({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  const airportSelectGet = useAirportSelectGet();
  const form = useFormInstance();

  const [search, setSearch] = useState<string | undefined>();

  const fetchFunc = (val: string) => {
    airportSelectGet.fetch({ search: val || undefined });
  };

  useEffect(() => {
    if (form) {
      fetchFunc(form.getFieldValue(props.name) || undefined);
    }
  }, []);

  useEffect(() => {
    if (search || search === '') {
      const timeoutId = setTimeout(() => fetchFunc(search), 500);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [search]);

  return (
    <OrderSelect
      fetch={airportSelectGet}
      selectProps={{
        ...selectProps,
        loading: airportSelectGet.loading,
        searchValue: search,
        onSearch: setSearch,
      }}
      rest={rest}
      {...props}
    />
  );
}

export default SelectAirport;
